import CloseIcon from "@mui/icons-material/Close";
import {
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useAsync } from "react-use";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useHangarState } from "../../../containers/HangarStateContainer";
import { useSnackbar } from "../../../containers/SnackbarContainer";
import { GarageDoor, Hangar } from "../../../types";
import { ButtonUploadImage } from "../../../widgets/ButtonUploadImage/ButtonUploadImage";
import {
  ConfirmationDialog,
  Props as ConfirmationDialogProps,
} from "../../../widgets/ConfirmationDialog";
import { HangarDimensionsForm } from "./HangarDimensionsForm";
import { ObstacleCanvas } from "./ObstacleCanvas";

type Props = {
  hangar: Hangar;
  setHangar: (hangar: Hangar) => void;
  onClose: () => void;
};

export const HangarEditorPresenter: React.FC<Props> = ({
  hangar,
  setHangar,
  onClose,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { postgrest } = useApi();
  const { hangar: dbHangar, setHangar: setHangarAgain } = useHangarState();
  const [localHangar, setLocalHangar] = React.useState<Hangar>();
  const [showReferenceImage, setShowReferenceImage] = React.useState<boolean>(
    false
  );
  const [referenceImage, setReferenceImage] = React.useState<string>();
  const [confirmSave, setConfirmSave] = React.useState<ConfirmationDialogProps>(
    null
  );
  const snackbar = useSnackbar();

  useAsync(async () => {
    const results = await postgrest
      .from("reference_image")
      .select()
      .eq("location_id", hangar.location_id);
    setReferenceImage(results?.data[0]?.image);
  }, [hangar]);

  const saveReferenceImage = async (image: string) => {
    await postgrest.from("reference_image").upsert({
      location_id: hangar.location_id,
      fbo_id: activeFBO.id,
      image,
    });
  };

  React.useEffect(() => {
    setLocalHangar(hangar);
  }, [hangar]);

  const handleClose = () => {
    onClose();
  };

  const onClickSaveHangar = async () => {
    setConfirmSave({
      title: "Save Changes?",
      text: "Are you sure you want to save these changes?",
      onCancel: () => setConfirmSave(null),
      okText: "Save",
      onOk: () => {
        const localHangarWithAdjustedGarageDoors = {
          ...localHangar,
          garageDoors: localHangar.garageDoors.map((gd) => ({
            ...gd,
            x:
              // if it's a left wall, should always be 0
              gd.wall === "left"
                ? 0
                : gd.wall === "right"
                ? // if it's a right wall, should always be the width of the hangar
                  localHangar.width
                : gd.x,
            y:
              gd.wall === "back"
                ? // if it's the back wall, should always be the depth of the hangar
                  localHangar.depth
                : gd.y,
          })),
        };
        setHangar(localHangarWithAdjustedGarageDoors);
        setHangarAgain({
          ...localHangarWithAdjustedGarageDoors,
          stack: {
            ...dbHangar.stack,
          },
        });
        snackbar.notify({ text: "Hangar changes saved.", severity: "success" });
        setConfirmSave(null);
      },
      onClose: () => {
        setConfirmSave(null);
      },
    });
  };

  const setIGarageDoor = React.useCallback(
    (i: number, g: GarageDoor) => {
      const newGarageDoors = localHangar.garageDoors.map((garageDoor, idx) => {
        if (i === idx) {
          return g;
        }
        return garageDoor;
      });
      setLocalHangar({ ...localHangar, garageDoors: newGarageDoors });
    },
    [localHangar]
  );

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={true}
        onClose={handleClose}
        sx={{ height: "95vh" }}
        PaperProps={{ sx: { width: "100%", height: "100%" } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Hangar Building Wizard</Typography>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            alignItems="space-between"
            justifyContent="space-around"
            spacing={2}
          >
            <Stack direction="column" spacing={2} sx={{ minWidth: 350 }}>
              <Stack direction="column" spacing={2} sx={{ p: 1 }}>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    size="small"
                    label="Hangar Name"
                    onChange={(evt) =>
                      setLocalHangar({ ...localHangar, name: evt.target.value })
                    }
                    value={localHangar?.name ?? ""}
                  />
                </Stack>
                <HangarDimensionsForm
                  localHangar={localHangar}
                  setLocalHangar={setLocalHangar}
                  setIGarageDoor={setIGarageDoor}
                />
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2">Reference Image</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <ButtonUploadImage
                      label="Upload Reference Image"
                      onLoad={async (image) => {
                        setReferenceImage(image);
                        setShowReferenceImage(true);
                        await saveReferenceImage(image);
                      }}
                    />
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="body2">Display?</Typography>
                      <Switch
                        checked={showReferenceImage}
                        onChange={(evt) =>
                          setShowReferenceImage(evt.target.checked)
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel Setup
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={onClickSaveHangar}
                >
                  Save Hangar
                </Button>
              </Stack>
            </Stack>
            <ObstacleCanvas
              hangar={localHangar}
              setHangar={setLocalHangar}
              referenceImage={showReferenceImage && referenceImage}
            />
          </Stack>
        </DialogContent>
      </Dialog>
      {Boolean(confirmSave) && <ConfirmationDialog {...confirmSave} />}
    </>
  );
};
