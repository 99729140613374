import { Divider, Stack } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { AutocompleteResultsContainer } from "../../containers/AutocompleteResultsContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { Stack as StackType } from "../../types";
import { CurrentAircraft } from "../../widgets/CurrentAircraft/CurrentAircraft";
import { Layout } from "../../widgets/Layout";
import { useDimensions } from "../../widgets/Layout/hooks/useDimensions";
import { LayoutToolbar } from "../../widgets/LayoutToolbar";
import { ZoomControl } from "../../widgets/ZoomControl";
import { AircraftFinder } from "./AircraftFinder";
import {
  CollapsibleRampActionCard,
  useLayout,
} from "./CollapsibleRampActionCard";
import { RampInfoCard } from "./RampInfoCard";
import { RampPrintLayout } from "./RampPrintLayout";

interface LayoutCanvasState {
  x0: number;
  setX0: (x0: number) => void;
  y0: number;
  setY0: (y0: number) => void;
  initialPositionOffset: { xFeet: number; yFeet: number };
  setInitialPositionOffset: (offset: { xFeet: number; yFeet: number }) => void;
}

export const useLayoutCanvasState = create<LayoutCanvasState>()((set) => ({
  x0: 0,
  setX0: (x0) => set({ x0 }),
  y0: 0,
  setY0: (y0) => set({ y0 }),
  initialPositionOffset: { xFeet: 0, yFeet: 0 },
  setInitialPositionOffset: (initialPositionOffset) =>
    set({ initialPositionOffset }),
}));

export const INFO_AND_CURRENT_AIRCRAFT_WIDTH = 400;

export const RampsPresenter: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { activeFBO } = useActiveFBOs();
  const { ramp, setRamp, history } = useRampState();
  const [zoomLevel, setZoomLevel] = React.useState<number>(1);
  const [width, setWidth] = React.useState<number>(700);

  const { isDrawerOpen, setIsDrawerOpen } = useLayout();

  const div = React.useCallback(
    (node) => {
      const heightOffsetForRamp = 150;
      if (node?.offsetWidth && node?.offsetHeight) {
        const widthOffsetForRamp =
          (heightOffsetForRamp * ramp.width) / ramp.depth;
        const widthBasedOnAvaiableWidth = node.offsetWidth;
        const widthBasedOnAvailableHeight =
          (node.offsetHeight * ramp.width) / ramp.depth - heightOffsetForRamp;

        setWidth(
          Math.min(widthBasedOnAvaiableWidth, widthBasedOnAvailableHeight)
        );
      }
    },
    [isDrawerOpen]
  );

  const setStack = React.useCallback(
    (stack: StackType) => {
      setRamp((ramp) => ({
        ...ramp,
        stack: {
          ...ramp.stack,
          ...stack,
        },
      }));
    },
    [ramp, setRamp]
  );

  const zoomedWith = width * zoomLevel;

  const { feetToPixels } = useDimensions({
    location: { type: "ramp", ...ramp },
    width: zoomedWith,
    height: (zoomedWith * ramp.depth) / ramp.width,
  });

  return (
    <AutocompleteResultsContainer>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
          <CollapsibleRampActionCard
            location={ramp}
            onClickBack={() => navigate("/ramps")}
          >
            <Stack
              direction="column"
              spacing={1}
              sx={{
                maxHeight: "100%",
                width: isDrawerOpen ? INFO_AND_CURRENT_AIRCRAFT_WIDTH : 125,
                borderLeft: "1px solid lightgrey",
                borderRight: "1px solid lightgrey",
              }}
            >
              <RampInfoCard isDrawerOpen={isDrawerOpen} ramp={ramp} />

              <AircraftFinder
                mini={!isDrawerOpen}
                tenants={ramp.stack.tenants ?? []}
                onSelect={(tenant) => {
                  setStack({
                    ...ramp.stack,
                    tenants: ramp.stack.tenants.map((t) => ({
                      ...t,
                      selected: t.id === tenant.id,
                    })),
                  });
                }}
              />

              <Divider />
              <CurrentAircraft
                feetToPixels={feetToPixels}
                readOnly={
                  activeFBO?.user_role === "manager"
                    ? activeFBO?.settings?.viewOnlyForManager
                    : activeFBO?.settings?.viewOnlyForOperator
                }
                location={{ type: "ramp", ...ramp }}
                stack={ramp.stack}
                setStack={(stack) => {
                  setRamp({
                    ...ramp,
                    stack: {
                      ...ramp.stack,
                      ...stack,
                    },
                  });
                }}
              />
            </Stack>
          </CollapsibleRampActionCard>
          <Stack
            direction="column"
            sx={{
              flex: 1,
              height: "100%",
              width: "100%",
            }}
            ref={div}
          >
            <LayoutToolbar
              location={{ type: "ramp", ...ramp }}
              setLocation={({ type, ...location }) =>
                setRamp({ ...ramp, ...location })
              }
              stack={ramp.stack}
              setStack={setStack}
              setStacks={() => {
                // don't need this b/c not autostacking
              }}
              onClickUndo={() => {
                history && history.undo();
              }}
              renderOnPrint={() => (
                <RampPrintLayout ramp={ramp} stack={ramp.stack} />
              )}
            />
            <div
              style={{
                flex: 1,
                // This stops the aircraft being dragged outside of this section
                // overflow: "scroll",
                position: "relative",
                height: "100%",
                margin: "20px",
              }}
            >
              <Layout
                width={zoomedWith}
                location={{ type: "ramp", ...ramp }}
                stack={ramp?.stack}
                setStack={setStack}
                options={{
                  hideUnplaced: false,
                  readOnly: false,
                  frozen:
                    activeFBO?.user_role === "manager"
                      ? activeFBO?.settings?.viewOnlyForManager
                      : activeFBO?.settings?.viewOnlyForOperator,

                  printable: false,
                  selectable: false,
                }}
                zoomLevel={zoomLevel}
              />
            </div>
          </Stack>
          <ZoomControl
            currentZoom={zoomedWith}
            increment={0.1}
            onClickPlus={(newZoomLevel) => setZoomLevel(newZoomLevel)}
            onClickMinus={(newZoomLevel) => setZoomLevel(newZoomLevel)}
          />
        </Stack>
      </Stack>
    </AutocompleteResultsContainer>
  );
};
