import { Stack } from "@mui/material";
import { range } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import {
  CornerStrategy,
  GarageDoor,
  Hangar,
  ParamSet,
  Preference,
  Tenant,
} from "../../types";
import { uuidv4 } from "../../utils";
import { CustomPlacementOptions } from "../Hangar/CustomStackDialog";
import { generateDevParamSets } from "../Hangar/GenerateParamSets";
import { runParamSets } from "../Hangar/RunParamSet";
import { Aircraft } from "../LabelingTool";
import { HangarResult } from "../StackMaster/StackMasterPresenter";
import { OptimizationHangarResults } from "./OptimizationHangarResults";
import { OptimizationInputsForm } from "./OptimizationInputsForm";

export const HangarBuilderOptimizerPresenter: React.FC = () => {
  const { airplxIdentity, airplxToken } = useIdentity();
  const [localHangar, setLocalHangar] = React.useState<Hangar>({
    id: uuidv4(),
    location_id: uuidv4(),
    fbo_id: "",
    name: "",
    width: 150,
    depth: 150,
    height: 50,
    left_door: 0,
    right_door: 0,
    wall_spacing_left: 2,
    wall_spacing_back: 2,
    wall_spacing_right: 2,
    garageDoors: [],
    obstacles: [],
    stack: {
      id: uuidv4(),
      name: "Test Stack",
      location_id: uuidv4(),
      isReference: false,
      tenants: [],
      movableObstacles: [],
      options: {
        wall_spacing_left: 2,
        wall_spacing_back: 2,
        wall_spacing_right: 2,
        horizontal_spacing: 2,
        vertical_spacing: 2,
        overlap: false,
        tug_spacing: 2,
        density_threshold: 0.8,
        corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
        xy_weights: [1, 1],
        towing_equipment_id: null,
      },
    },
    stacks: [],
    last_stacked: new Date().toISOString(),
    created_at: new Date().toISOString(),
  });
  const [widthIncrement, setWidthIncrement] = React.useState<number>(20);
  const [depthIncrement, setDepthIncrement] = React.useState<number>(20);
  const [
    selectedAircraft,
    setSelectedAircraft,
  ] = React.useState<Aircraft | null>(null);
  const [nAircraft, setNAircraft] = React.useState<number>(0);
  const [aircrafts, setAircrafts] = React.useState<Aircraft[]>([]);
  const [results, setResults] = React.useState<HangarResult[]>([]);

  const { activeFBO } = useActiveFBOs();
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";

  const hangars: Hangar[] = React.useMemo(() => {
    const hangarsToTrial: Hangar[] = [];
    const widths = range(
      localHangar.width - widthIncrement,
      localHangar.width + widthIncrement,
      widthIncrement / 2
    );
    const depths = range(
      localHangar.depth - depthIncrement,
      localHangar.depth + depthIncrement,
      depthIncrement / 2
    );
    for (const w of widths) {
      for (const d of depths) {
        hangarsToTrial.push({
          id: uuidv4(),
          location_id: uuidv4(),
          fbo_id: activeFBO.id,
          name: `${w}x${d}`,
          width: w,
          depth: d,
          height: 50, // option?
          left_door: 0, // option?
          right_door: 0, // option?
          wall_spacing_left: 2, // option?
          wall_spacing_back: 2, // option?
          wall_spacing_right: 2, // option?
          garageDoors: [], // option?
          obstacles: [], // option?
          stack: {
            id: uuidv4(),
            name: "Test Stack",
            location_id: uuidv4(),
            isReference: false,
            tenants: aircrafts.map((aircraft) => {
              return {
                id: uuidv4(),
                fbo_id: activeFBO.id,
                entity_id: uuidv4(),
                placement_id: uuidv4(),
                aircraft_id: aircraft.id,
                type: "transient",
                tail_number: "",
                owner_manager: "",
                created_at: new Date().toISOString(),
                selected: false,
                last_hangared: new Date().toISOString(),
                note: "",
                note_last_updated_by: "",
                note_last_updated: new Date().toISOString(),
                aircraft,
                position: {
                  x: 0,
                  y: 0,
                  angle: 0,
                  preferences: [],
                },
              } as Tenant;
            }),
            movableObstacles: [], // option?
            options: {
              wall_spacing_left: 2,
              wall_spacing_back: 2,
              wall_spacing_right: 2,
              horizontal_spacing: 2,
              vertical_spacing: 2,
              overlap: false,
              tug_spacing: 2,
              density_threshold: 0.8,
              corner_strategy: [
                CornerStrategy.ANGLE_45,
                CornerStrategy.ANGLE_45,
              ],
              xy_weights: [1, 1],
              towing_equipment_id: null,
            },
          },
          stacks: [],
          last_stacked: new Date().toISOString(),
          created_at: new Date().toISOString(),
        });
      }
    }
    return hangarsToTrial;
  }, [localHangar, widthIncrement, depthIncrement, aircrafts]);

  const onClickRun = React.useCallback(
    async (customPlacementOptions: CustomPlacementOptions) => {
      const currentResults: HangarResult[] = hangars.map((hangar) => ({
        id: uuidv4(),
        hangar,
        layoutOptions: [],
        progress: [0],
        time: 0,
      }));
      for (const hangar of hangars) {
        const startTime = +new Date();
        console.log(`running hangar: ${hangar.name}`);
        const paramSet: ParamSet = {
          run_id: uuidv4(),
          label: "base case",
          // slim down the hangar for the API call
          hangar: {
            id: hangar.id,
            width: hangar.width,
            depth: hangar.depth,
            left_door: hangar.left_door,
            right_door: hangar.right_door,
            garage_doors: hangar.garageDoors ?? [],
          },
          // slim down the tenant so it's just what we need to do the placement
          aircrafts_to_place: hangar.stack.tenants
            .filter(
              (t) => t.position.preferences?.indexOf(Preference.LOCKED) === -1
            )
            .map((tenant) => ({
              id: tenant.aircraft.id,
              placement_id: tenant.placement_id,
              angles_to_try: [],
              sectors_to_try: null,
              preferences: tenant.position.preferences,
            })),
          locked_aircrafts: [],
          obstacles: hangar.obstacles ?? [],
          options: {
            ...customPlacementOptions,
            corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
            tug_spacing: 0, // obselete
            error_type: null, // obselete
            density_threshold: 0.8, // obselete
          },
        };
        const paramSets = generateDevParamSets(
          paramSet,
          hangar.stack.tenants.map((t) => t.aircraft),
          customPlacementOptions
        );
        window.globalThis.airplxKillSwitch = 0;
        await runParamSets(
          hangar,
          paramSets,
          airplxIdentity,
          airplxToken,
          (layoutOptions, progress) => {
            const idx = currentResults.findIndex(
              (r) => r.hangar.id === hangar.id
            );
            currentResults[idx] = {
              ...currentResults[idx],
              layoutOptions,
              progress,
              time: +new Date() - startTime,
            };
            setResults([...currentResults]);
          }
        );
      }
    },
    [hangars]
  );

  const setIGarageDoor = React.useCallback(
    (i: number, g: GarageDoor) => {
      const newGarageDoors = localHangar.garageDoors.map((garageDoor, idx) => {
        if (i === idx) {
          return g;
        }
        return garageDoor;
      });
      setLocalHangar({ ...localHangar, garageDoors: newGarageDoors });
    },
    [localHangar]
  );

  return (
    <Stack direction="row" spacing={12} justifyContent="space-between" p={2}>
      <OptimizationInputsForm
        localHangar={localHangar}
        setLocalHangar={setLocalHangar}
        depthIncrement={depthIncrement}
        setDepthIncrement={setDepthIncrement}
        widthIncrement={widthIncrement}
        setWidthIncrement={setWidthIncrement}
        selectedAircraft={selectedAircraft}
        setSelectedAircraft={setSelectedAircraft}
        nAircraft={nAircraft}
        setNAircraft={setNAircraft}
        aircrafts={aircrafts}
        setAircrafts={setAircrafts}
        setIGarageDoor={setIGarageDoor}
      />
      <OptimizationHangarResults
        aircrafts={aircrafts}
        results={results}
        onClickRun={onClickRun}
      />
    </Stack>
  );
};
