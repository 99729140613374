import * as React from "react";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { PredefinedObstaclesContainer } from "../../containers/PredefinedObstaclesContainer";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { TowingEquipmentContainer } from "../../containers/TowingEquipmentContainer";
import { HangarBuilderOptimizerPresenter } from "./HangarBuilderOptimizerPresenter";

export const HangarBuilderOptimizer: React.FC = () => {
  return (
    <RampsStateContainer isReference={false}>
      <HangarsStateContainer isReference={false}>
        <PredefinedObstaclesContainer>
          <TowingEquipmentContainer>
            <HangarBuilderOptimizerPresenter />
          </TowingEquipmentContainer>
        </PredefinedObstaclesContainer>
      </HangarsStateContainer>
    </RampsStateContainer>
  );
};
