import * as React from "react";
import { useAsync, usePrevious } from "react-use";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { Rate } from "../screens/Settings/RevenueEstimator/RatesForm";

export const useRates = () => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [rates, setRates] = React.useState<Rate[]>([]);
  const previousRates = usePrevious(rates);

  const fetchRates = async () => {
    const { data: rates } = await postgrest
      .from("rates")
      .select()
      .eq("fbo_id", activeFBO.id);
    setRates(rates ?? []);
  };

  // grab the hangar to start
  React.useEffect(() => {
    fetchRates();
  }, []);

  useAsync(async () => {
    await postgrest.from("rates").upsert(rates);
    const rateIds = rates.map((r) => r.id);
    const previousRateIds = previousRates.map((r) => r.id);
    const idsToDelete = previousRateIds.filter((pr) => !rateIds.includes(pr));
    if (idsToDelete.length) {
      await postgrest
        .from("rates")
        .delete()
        .in("id", idsToDelete);
    }
  }, [rates]);

  return {
    ready: Boolean(rates),
    rates: rates ?? [],
    setRates,
  };
};
