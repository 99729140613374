import { Box, Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { HangarPrintLayout } from "../../../screens/Hangar/HangarPrintLayout";
import { RampPrintLayout } from "../../../screens/Ramp/RampPrintLayout";
import { Hangar, Location, Ramp, Stack as StackType } from "../../../types";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { Layout } from "../../Layout";

type Props = {
  location: Location<Ramp | Hangar>;
  favorite: StackType;
  onUse: (favorite: StackType) => void;
  onDelete: (favorite: StackType) => void;
};

export const FavoritePresenter: React.FC<Props> = ({
  location,
  favorite,
  onUse,
  onDelete,
}) => {
  const [confirm, setConfirm] = React.useState<{
    open: boolean;
    text: string;
    okText: string;
    onOk: () => void;
  }>({
    open: false,
    text: "",
    okText: "",
    onOk: () => {},
  });
  const [print, setPrint] = React.useState<boolean>(false);
  const printComponentRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    contentRef: printComponentRef,
  });

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      sx={{
        p: 1,
        height: 300,
        width: 300,
      }}
    >
      <Stack direction="column" spacing={0}>
        <Typography textAlign="center" variant="subtitle2">
          {favorite.name}
        </Typography>
        <Layout
          stack={favorite}
          setStack={() => {
            // not needed
          }}
          location={location}
          height={location.width / location.depth > 1.75 ? 175 : 250}
          zoomLevel={0.25}
          options={{
            hideUnplaced: true,
            readOnly: true,
            selectable: false,
            printable: false,
          }}
        />
        {print && (
          <Box
            sx={{
              position: "absolute",
              top: "100vh",
            }}
          >
            <div
              ref={printComponentRef}
              style={{
                display: "block",
                padding: 15,
                marginLeft: 50,
              }}
            >
              {location.type === "ramp" ? (
                <RampPrintLayout
                  ramp={location as Ramp}
                  stack={favorite}
                  subtitle={`Favorite: ${favorite.name}`}
                />
              ) : (
                <HangarPrintLayout
                  hangar={location as Hangar}
                  stack={favorite}
                  subtitle={`Favorite: ${favorite.name}`}
                />
              )}
            </div>
          </Box>
        )}
        <Stack direction="row" spacing={1} justifyContent="center" mt={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setConfirm({
                open: true,
                text: `Remove all aircaft from your current ${location.type} and replace with aircraft from '${favorite.name}'.`,
                okText: `Yes, reset to this saved stack`,
                onOk: () => {
                  onUse(favorite);
                },
              });
            }}
          >
            Use
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setConfirm({
                open: true,
                text: `You are about to delete ${favorite.name}.`,
                okText: "Yes, delete this favorite",
                onOk: () => {
                  onDelete(favorite);
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              setPrint(true);
              setTimeout(() => {
                handlePrint();
                setPrint(false);
              }, 1000);
            }}
          >
            Print
          </Button>
        </Stack>
      </Stack>
      {confirm?.open && (
        <ConfirmationDialog
          title={"Please Confirm"}
          onCancel={() => setConfirm(null)}
          onClose={() => setConfirm(null)}
          {...confirm}
        />
      )}
    </Box>
  );
};
