import GridViewIcon from "@mui/icons-material/GridView";
import LockIcon from "@mui/icons-material/Lock";
import WidgetsIcon from "@mui/icons-material/Widgets";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useTowingEquipmentState } from "../../../containers/TowingEquipmentContainer";
import { TowingEquipment } from "../../../hooks/useTowingEquipment";
import { useUserPreferences } from "../../../hooks/useUserPreferences";
import { Hangar, ParamSet } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { HOLDING_AREA_ID } from "../../MultiHangar/HoldingArea";
import { useLayout } from "../../Ramp/CollapsibleRampActionCard";
import {
  CustomPlacementOptions,
  CustomStackDialog,
} from "../CustomStackDialog";
import { EvaluatingStackDialog } from "../EvaluatingStackDialog";
import {
  StackStrategy,
  generateDevParamSets,
  makeParamSetForType,
} from "../GenerateParamSets";
import { HangarStack } from "../Hangar";
import { SelectStackStrategy } from "./SelectStackStrategy";
import { SelectTowingEquipment } from "./SelectTowingEquipment";
import { runAllParamSets } from "./run";

const ButtonAutoStack: React.FC<{
  disabled: boolean;
  onClick: () => void;
  icon: boolean;
}> = ({ disabled, onClick, icon }) => {
  if (icon) {
    return (
      <Tooltip title="AutoStack" arrow>
        <IconButton disabled={disabled} onClick={onClick} color="success">
          <WidgetsIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button
      disabled={disabled}
      fullWidth
      variant="contained"
      color="success"
      endIcon={<WidgetsIcon />}
      onClick={onClick}
    >
      AutoStack
    </Button>
  );
};

const ButtonResults: React.FC<{
  disabled: boolean;
  onClick: () => void;
  icon: boolean;
}> = ({ disabled, onClick, icon }) => {
  if (icon) {
    return (
      <Tooltip title="Results" arrow>
        <IconButton disabled={disabled} onClick={onClick} color="primary">
          <GridViewIcon />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Button
      fullWidth
      disabled={disabled}
      variant="contained"
      color="primary"
      endIcon={<GridViewIcon />}
      onClick={onClick}
    >
      Results
    </Button>
  );
};

type Props = {
  hangar: Hangar;
  stacks: HangarStack[];
  setStacks: (stacks: HangarStack[]) => void;
  paramSet: ParamSet;
  isAlgorithmTesting?: boolean;
  customPlacementOptions?: CustomPlacementOptions;
  stackStrategy: StackStrategy;
  setStackStrategy: (s: StackStrategy) => void;
  disabled?: boolean;
};

export enum Status {
  IN_PROGRESS = "in progress",
  COMPLETE = "complete",
}

export const RunStack: React.FC<Props> = ({
  hangar,
  stacks,
  setStacks,
  paramSet,
  customPlacementOptions,
  isAlgorithmTesting = false,
  stackStrategy,
  setStackStrategy,
  disabled = false,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { preferences, setPreferences } = useUserPreferences();
  const { towingEquipments: allTowingEquipments } = useTowingEquipmentState();
  const { isDrawerOpen } = useLayout();
  const { airplxIdentity, airplxToken } = useIdentity();
  const [open, setOpen] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<Status>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ignoreTowingEquipment, setIgnoreTowingEquipment] = React.useState<
    boolean
  >(false);
  const towingEquipments = allTowingEquipments.filter((t) => t.in_use);
  const [stacksProgress, setStacksProgress] = React.useState<number[]>([]);

  const [selectedTowingEquipment, setSelectedTowingEquipment] = React.useState<
    TowingEquipment
  >();
  const [showCustomDialog, setShowCustomDialog] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (!selectedTowingEquipment) {
      setSelectedTowingEquipment(towingEquipments.find((t) => t.is_default));
    }
  }, [towingEquipments]);

  const onClickRunStack = React.useCallback(
    async (customPlacementOptions?: CustomPlacementOptions) => {
      mixpanel.track("Clicked Autostack", {
        location_name: hangar.name,
        location_type: "hangar",
        stack_strategy: stackStrategy.toString(),
      });
      window.globalThis.airplxKillSwitch = 0;
      window.globalThis.airplxAborter = new AbortController();
      setStacksProgress([]);
      setLoading(true);
      setStatus(Status.IN_PROGRESS);

      const baseParamSet: ParamSet = {
        ...paramSet,
        options: {
          ...paramSet.options,
          towing_equipment_id: ignoreTowingEquipment
            ? null
            : selectedTowingEquipment?.towing_equipment_id,
        },
      };
      const paramSetForType = makeParamSetForType(
        isAlgorithmTesting ? StackStrategy.dev : stackStrategy,
        baseParamSet,
        customPlacementOptions,
        activeFBO
      );

      const paramsSets = generateDevParamSets(
        paramSetForType,
        hangar.stack.tenants.map(({ aircraft }) => aircraft),
        customPlacementOptions
      );

      await runAllParamSets(
        hangar,
        paramsSets,
        (stacks) => setStacks(stacks),
        (progress) => setStacksProgress(progress),
        airplxIdentity,
        airplxToken
      );

      setLoading(false);
      if (
        !window.globalThis.airplxKillSwitch ||
        window.globalThis.airplxKillSwitch < 2
      ) {
        setStatus(Status.COMPLETE);
      }
    },
    [
      open,
      setStacks,
      paramSet,
      selectedTowingEquipment,
      ignoreTowingEquipment,
      customPlacementOptions,
    ]
  );

  return (
    <>
      <EvaluatingStackDialog
        open={Boolean(status)}
        hangarId={hangar.id}
        isAlgorithmTesting={isAlgorithmTesting}
        status={status}
        stacks={stacks}
        progress={stacksProgress}
        onClose={() => setStatus(null)}
      />
      <Stack
        direction="row"
        spacing={1}
        justifyContent={isDrawerOpen ? "space-between" : "space-around"}
        sx={{ m: 0, pl: 1, pr: 1 }}
      >
        {isAlgorithmTesting ? (
          <>
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<WidgetsIcon />}
              onClick={() => {
                setOpen(false);
                onClickRunStack(customPlacementOptions);
              }}
            >
              AutoStack++
            </Button>
          </>
        ) : (
          <ButtonAutoStack
            disabled={
              activeFBO.subscription === "standard" ||
              hangar.id === HOLDING_AREA_ID ||
              disabled
            }
            onClick={() => setOpen(true)}
            icon={!isDrawerOpen}
          />
        )}
        <ButtonResults
          disabled={!stacks.length}
          onClick={() => setStatus(Status.COMPLETE)}
          icon={!isDrawerOpen}
        />
      </Stack>
      {showCustomDialog && (
        <CustomStackDialog
          onClickRunStack={(customPlacementOptions: CustomPlacementOptions) => {
            setOpen(false);
            onClickRunStack(customPlacementOptions);
            setShowCustomDialog(false);
          }}
          onClose={() => {
            setOpen(true);
            setShowCustomDialog(false);
            setStatus(null);
          }}
        />
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setStatus(null);
        }}
        maxWidth="sm"
      >
        <DialogTitle>Select a type of Stack</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {preferences.hideLockReminderAlert ? (
              <div />
            ) : (
              <Alert
                severity="warning"
                icon={<LockIcon fontSize="inherit" />}
                onClose={() => {
                  setPreferences({
                    ...preferences,
                    hideLockReminderAlert: true,
                  });
                }}
              >
                Did you lock aircraft that you don’t want to move? (eg dedicated
                location, already hangared)
              </Alert>
            )}
            <SelectStackStrategy
              paramSet={paramSet}
              stackStrategy={stackStrategy}
              setStackStrategy={setStackStrategy}
            />
            <SelectTowingEquipment
              ignoreTowingEquipment={ignoreTowingEquipment}
              setIgnoreTowingEquipment={setIgnoreTowingEquipment}
              selectedTowingEquipment={selectedTowingEquipment}
              setSelectedTowingEquipment={setSelectedTowingEquipment}
              towingEquipments={towingEquipments}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          {stackStrategy === StackStrategy.custom ? (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setOpen(false);
                setShowCustomDialog(true);
              }}
            >
              Customize Stack
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setOpen(false);
                onClickRunStack();
              }}
            >
              Run Stack
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
