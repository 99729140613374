import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { ConvertSVG } from "../ConvertSVG";
import { UberAdmin } from "../Settings/UberAdmin";
import { AuthorizedAccounts } from "./AuthorizedAccounts";
import { EnterTheDojo } from "./EnterTheDojo";
import { FBOAdmin } from "./FBOAdmin";

type Props = {};

export const SuperAdmin: React.FC<Props> = () => {
  const [activeTab, setActiveTab] = React.useState<number>(0);

  return (
    <Box sx={{ p: 2 }}>
      <Tabs value={activeTab} onChange={(evt, value) => setActiveTab(value)}>
        <Tab value={0} label="Authorized Accounts" />
        <Tab value={1} label="FBOs" />
        <Tab value={2} label="Labeling" />
        <Tab value={3} label="Convert SVGs" />
        <Tab value={4} label="System Status" />
      </Tabs>
      {activeTab === 0 && <AuthorizedAccounts />}
      {activeTab === 1 && <FBOAdmin />}
      {activeTab === 2 && <EnterTheDojo />}
      {activeTab === 3 && <ConvertSVG />}
      {activeTab === 4 && <UberAdmin />}
    </Box>
  );
};
