import * as React from "react";
import { useAutocompleteResults } from "../../containers/AutocompleteResultsContainer";
import { useLoadingProgress } from "../../containers/LoadingProgressContainer";
import { useUserPreferences } from "../../containers/UserPreferencesContainer";
import { useAircraft } from "./hooks/useAircraft";
import { useAutocompleteTenant } from "./hooks/useAutocompleteTenant";
import { useDimensions } from "./hooks/useDimensions";
import { useGetClickedEntity } from "./hooks/useGetClickedEntity";
import { useGetIndexOfGhostAircraftHoveringOver } from "./hooks/useGetIndexOfGhostAircraftHoveringOver";
import { useIsInLayout } from "./hooks/useIsInLayout";
import { useMovableObstacles } from "./hooks/useMovableObstacles";
import { useOnClick } from "./hooks/useOnClick";
import { useOnContextMenu } from "./hooks/useOnContextMenu";
import { usePolygons } from "./hooks/usePolygons";
import { useUnselect } from "./hooks/useUnselect";
import { LayoutBackground } from "./LayoutBackground";
import { CollisionOverlay } from "./widgets/CollisionOverlay";
import { Door } from "./widgets/Door";
import { GhostAircraft } from "./widgets/GhostAircraft";
import { Obstacle } from "./widgets/Obstacle";

import {
  AutocompletePosition,
  Entity,
  Hangar,
  Location,
  MovableObstacle,
  Position,
  Preference,
  Ramp,
  Stack,
  Tenant,
} from "../../types";
import { DraggingScrollContainer } from "../DraggingScrollContainer/DraggingScrollContainer";

export type LayoutDisplayOptions = {
  hideUnplaced?: boolean;
  selectable?: boolean;
  readOnly?: boolean;
  printable?: boolean;
  dashColor?: string;
  backgroundColor?: string;
  disableDragProtection?: boolean;
  borderColor?: string;
  hideTailNumbers?: boolean;
  frozen?: boolean;
  disableGrabScroll?: boolean;
};

export type MoveEntityEvent = {
  direction: string;
  entity: Entity<Tenant | MovableObstacle>;
  previousPosition: Position;
  locationId: string;
};

type Props = {
  stack: Stack;
  setStack: (stack: Stack) => void;
  width?: number;
  height?: number;
  location: Location<Hangar | Ramp>;
  autocomplete?: (tenant: Tenant) => Promise<AutocompletePosition[]>;
  options: LayoutDisplayOptions;
  zoomLevel?: number;
};

export type RightClick = {
  entity_id: string;
  mouseX: number;
  mouseY: number;
};

export const HANGAR_BACKGROUND_COLORS = {
  reference: "#F4D2CD",
  testing: "#23967F",
};

export const Layout: React.FC<Props> = ({
  stack,
  setStack,
  location,
  autocomplete: originalAutocomplete,
  options,
  zoomLevel,
  ...props
}) => {
  const autocomplete =
    originalAutocomplete ?? (async () => [] as AutocompletePosition[]);
  const { setShow: setShowLoadingProgress } = useLoadingProgress();
  const hideUnplaced = options?.hideUnplaced ?? false;
  const readOnly = options?.readOnly ?? false;
  const frozen = options?.frozen ?? false;
  const printable = options?.printable ?? false;
  const spacingBufferRef = React.useRef<HTMLDivElement>();
  const spacingBufferOverlayElement = spacingBufferRef.current;
  const [blockGhostHighlight, setBlockGhostHightlight] = React.useState<
    boolean
  >(false);

  const bboxRef = React.useRef<React.ReactNode>();
  const [ghostMouseOverIndex, setGhostMouseOverIndex] = React.useState<
    number
  >();
  const { loading: loadingUserPreferences, preferences } = useUserPreferences();
  const [rightClick, setRightClick] = React.useState<RightClick>(null);
  const {
    autocompleteResults,
    setAutocompleteResults,
  } = useAutocompleteResults();

  const { width, height, feetToPixels } = useDimensions({
    location,
    height: props.height,
    width: props.width,
  });

  const { polygons } = usePolygons(
    {
      stack,
      width,
      feetToPixels,
      isHoldingArea: location.id === "holding-area",
    },
    [width, stack.tenants, stack.movableObstacles, feetToPixels]
  );

  // TODO: make ghost and moving aircraft use hook
  const { polygons: ghostPolygons } = usePolygons(
    {
      stack: {
        ...stack,
        tenants: autocompleteResults.suggestedPositions
          .filter((sp) => sp.hangar_id === location.id)
          .map((position) => ({
            ...autocompleteResults.tenant,
            position: {
              ...autocompleteResults.tenant.position,
              ...position,
            },
          })),
        movableObstacles: [],
      },
      width,
      feetToPixels,
    },
    [width, autocompleteResults]
  );

  /**
   * Not sure what's going on here but I must have some sort of object non-sense where
   * react thinks the hangar object is something else. In any event, if this doesn't happen then
   * when the user clicks the hangar for the first time, nothing happens
   */
  React.useEffect(() => {
    setStack({
      ...stack,
    });
  }, []);

  // This hook is responsible for unselecting an aircraft if the user taps
  // anywhere on the page except for a plane or the aircraft list
  useUnselect(
    {
      stack,
      setStack,
    },
    [stack, setStack]
  );

  const isInLayout = useIsInLayout({ location, stack, polygons }, [
    stack.tenants,
    stack.movableObstacles,
    polygons,
  ]);

  const getClickedEntity = useGetClickedEntity(
    {
      bboxRef,
      stack,
      polygons,
      feetToPixels,
    },
    [stack]
  );

  const getIndexOfGhostAircraftHoveringOver = useGetIndexOfGhostAircraftHoveringOver(
    bboxRef,
    stack,
    ghostPolygons,
    feetToPixels,
    width
  );

  React.useEffect(() => {
    const selectedTenant = stack.tenants.find((t) => t.selected);
    if (selectedTenant) {
      autocompleteTenant(selectedTenant);
    }
  }, [stack.tenants]);

  /**
   * Autocomplete the aircraft position
   */
  const autocompleteTenant = useAutocompleteTenant(
    {
      hangarId: location.id,
      enabled: Boolean(originalAutocomplete),
      stack,
      preferences,
      autocomplete: async (tenant: Tenant) => await autocomplete(tenant),
      isInLayout,
      autocompleteResults,
      setAutocompleteResults,
    },
    [stack, autocompleteResults, preferences]
  );

  const onClick = useOnClick(
    {
      location,
      stack,
      setStack,
      autocompleteResults,
      setAutocompleteResults,
      rightClick,
      getClickedEntity,
      getIndexOfGhostAircraftHoveringOver,
    },
    [stack, setStack, ghostPolygons, autocompleteResults, rightClick]
  );

  const onMouseMove = React.useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      if (blockGhostHighlight) {
        return;
      }
      const index = getIndexOfGhostAircraftHoveringOver(evt);
      setGhostMouseOverIndex(index);
    },
    [stack, ghostPolygons]
  );

  const onContextMenu = useOnContextMenu(
    {
      stack,
      getClickedEntity,
      setRightClick,
    },
    [stack]
  );

  const Aircraft = useAircraft(
    {
      location,
      stack,
      setStack,
      hideUnplaced,
      isInLayout,
      preferences,
      printable,
      rightClick,
      setRightClick,
      readOnly,
      frozen,
      width,
      height,
      feetToPixels,
      spacingBufferOverlayElement,
      options,
    },
    [
      location,
      stack,
      setStack,
      readOnly,
      height,
      feetToPixels,
      preferences,
      rightClick,
      setRightClick,
      spacingBufferOverlayElement,
      options,
    ]
  );

  const MovableObstacles = useMovableObstacles(
    {
      location,
      stack,
      setStack,
      preferences,
      rightClick,
      setRightClick,
      readOnly,
      height,
      feetToPixels,
      spacingBufferOverlayElement,
    },
    [
      stack,
      setStack,
      readOnly,
      height,
      feetToPixels,
      preferences,
      rightClick,
      setRightClick,
      spacingBufferOverlayElement,
    ]
  );

  React.useEffect(() => {
    setShowLoadingProgress(autocompleteResults?.loading);
  }, [autocompleteResults?.loading]);

  // if a locked aircraft is moved outside the hangar, then unlock it
  React.useEffect(() => {
    for (const tenant of stack.tenants) {
      if (tenant.position.preferences.includes(Preference.LOCKED)) {
        if (!isInLayout(tenant)) {
          setStack({
            ...stack,
            tenants: stack.tenants.map((t) => {
              if (t.id === tenant.id) {
                return {
                  ...t,
                  position: {
                    ...t.position,
                    preferences: t.position.preferences.filter(
                      (p) => p !== Preference.LOCKED
                    ),
                  },
                };
              }
              return t;
            }),
          });
        }
      }
    }
  }, [stack.tenants]);

  const onMouseDown = () => setBlockGhostHightlight(true);
  const onMouseUp = () => setBlockGhostHightlight(false);

  // wait for user prefs...
  if (loadingUserPreferences) {
    return <div />;
  }

  const backgroundColor =
    options?.backgroundColor ??
    (stack.isReference ? HANGAR_BACKGROUND_COLORS.reference : "#aab1c7");
  const dashColor =
    options?.dashColor ?? stack.isReference ? "#f8f8f8" : "#5474a4";
  const borderColor = options?.borderColor;

  const autocompleteResultsForThisLayout = {
    ...autocompleteResults,
    suggestedPositions: autocompleteResults.suggestedPositions.filter(
      (sp) => sp.hangar_id === location.id
    ),
  };

  return (
    <DraggingScrollContainer
      disabled={readOnly || options?.disableGrabScroll}
      feetToPixels={feetToPixels}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <div
        data-location-id={location.id}
        data-layout={stack.id}
        style={{ display: "flex" }}
      >
        <LayoutBackground
          ref={bboxRef}
          location={location}
          preferences={preferences}
          selectable={false}
          width={width}
          height={height}
          feetToPixels={feetToPixels}
          zoomLevel={zoomLevel}
          backgroundColor={backgroundColor}
          dashColor={dashColor}
          borderColor={borderColor}
          printable={printable}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseEnter={() => null}
          onContextMenu={onContextMenu}
        >
          {!readOnly && preferences.showOverlaps && (
            <CollisionOverlay location={location} polygons={polygons ?? []} />
          )}
          {location.type === "hangar" &&
            Boolean((location as Hangar).left_door) && (
              <Door
                feetToPixels={feetToPixels}
                position="left"
                width={(location as Hangar).left_door}
              />
            )}
          {location.type === "hangar" &&
            Boolean((location as Hangar).right_door) && (
              <Door
                feetToPixels={feetToPixels}
                position="right"
                width={(location as Hangar).right_door}
              />
            )}

          {location.type === "hangar" &&
            location.obstacles.map((obstacle) => (
              <React.Fragment key={`obstacle-${obstacle.id}`}>
                <Obstacle {...obstacle} feetToPixels={feetToPixels} />
              </React.Fragment>
            ))}
          {location.type === "hangar" &&
            location.garageDoors.map((garageDoor) => (
              <React.Fragment key={`garage-door-${garageDoor.id}`}>
                <Door
                  type="garage"
                  feetToPixels={feetToPixels}
                  position={garageDoor.wall}
                  offset={
                    garageDoor.wall === "back" ? garageDoor.x : garageDoor.y
                  }
                  width={garageDoor.width}
                />
              </React.Fragment>
            ))}
          <div ref={spacingBufferRef} />
          {/**
           * aircraft is a memo so that it doesn't update during a drag event. this is because we only store state
           * at the end of a drag event
           */}
          {Aircraft}
          {MovableObstacles}
          {Boolean(preferences?.enableCoPilot) && (
            <GhostAircraft
              stack={stack}
              polygons={polygons}
              autocompleteResults={autocompleteResultsForThisLayout}
              mouseOverIndex={ghostMouseOverIndex}
              feetToPixels={feetToPixels}
              height={height}
            />
          )}
        </LayoutBackground>
      </div>
    </DraggingScrollContainer>
  );
};
