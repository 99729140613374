import { Divider, Stack, Switch, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { resetAircraftCache } from "../../../hooks/api";
import { FBO } from "../../../types";
import { MetricAwareTextField } from "../../../widgets/MetricAwareTextField/MetricAwareTextField";
import { NoseInThreshholds } from "./NoseInThreshholds";

type SpacingSettingsProps = {
  title: string;
  horizontalSpacing: number;
  verticalSpacing: number;
  overlap: boolean;
  onChange: (field: string, value: any) => void;
};

const SpacingSettings: React.FC<SpacingSettingsProps> = ({
  title,
  horizontalSpacing,
  verticalSpacing,
  overlap,
  onChange,
}) => {
  const { activeFBO } = useActiveFBOs();
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";
  return (
    <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
      <Typography>{title}</Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <MetricAwareTextField
          fullWidth
          size="small"
          inputProps={{
            min: 0,
            max: 15,
            step: activeFBO?.settings?.useMetricSystem ? 0.1 : 0.5,
          }}
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          label={`Horizontal Spacing (${unit})`}
          value={horizontalSpacing}
          onChange={(value) => onChange("sop_horizontal_spacing", value)}
        />
        <MetricAwareTextField
          fullWidth
          size="small"
          inputProps={{
            min: 0,
            max: 15,
            step: activeFBO?.settings?.useMetricSystem ? 0.1 : 0.5,
          }}
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          label={`Vertical Spacing (${unit})`}
          value={verticalSpacing}
          onChange={(value) => onChange("sop_vertical_spacing", value)}
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>Allow Overlap?</Typography>
          <Switch
            checked={overlap}
            onChange={(evt) => onChange("sop_overlap", evt.target.checked)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

type Props = {
  fbo: FBO;
  setFBO: (fbo: FBO) => void;
  updateSOP: (field: string, value: any) => Promise<void>;
};

export const StackingSOPsPresenter: React.FC<Props> = ({
  fbo,
  setFBO,
  updateSOP,
}) => {
  const { activeFBO } = useActiveFBOs();
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";
  return (
    <Stack direction="column" spacing={2} sx={{ pt: 2, width: 600 }}>
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Hangar Stacking</Typography>
        <Divider />
        <SpacingSettings
          title="Normal Spacing"
          horizontalSpacing={fbo.sop_horizontal_spacing}
          verticalSpacing={fbo.sop_vertical_spacing}
          overlap={fbo.sop_overlap}
          onChange={(field, value) => {
            updateSOP(`${field}`, value);
            resetAircraftCache();
          }}
        />
        <SpacingSettings
          title="Loose Spacing"
          horizontalSpacing={fbo.sop_horizontal_spacing_loose}
          verticalSpacing={fbo.sop_vertical_spacing_loose}
          overlap={fbo.sop_overlap_loose}
          onChange={(field, value) => {
            updateSOP(`${field}_loose`, value);
            resetAircraftCache();
          }}
        />
        <SpacingSettings
          title="Tight Spacing"
          horizontalSpacing={fbo.sop_horizontal_spacing_tight}
          verticalSpacing={fbo.sop_vertical_spacing_tight}
          overlap={fbo.sop_overlap_tight}
          onChange={(field, value) => {
            updateSOP(`${field}_tight`, value);
            resetAircraftCache();
          }}
        />
        <br />
        <NoseInThreshholds fbo={fbo} setFBO={setFBO} />
      </Stack>
      <Divider />
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Ramp Spacing</Typography>
        <MetricAwareTextField
          fullWidth
          size="small"
          inputProps={{
            min: 0,
            max: 25,
            step: activeFBO?.settings?.useMetricSystem ? 0.1 : 0.5,
          }}
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          label={`Horizontal Spacing (${unit})`}
          value={fbo.sop_ramp_horizontal_spacing}
          onChange={(value) => updateSOP("sop_ramp_horizontal_spacing", value)}
          sx={{
            width: 192,
          }}
        />
      </Stack>
    </Stack>
  );
};
