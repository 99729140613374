import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useApi } from "../../containers/ApiContainer";
import { scrollSx } from "../../utils";

type Props = {};

export const AuthorizedAccounts: React.FC<Props> = () => {
  const [testEmail, setTestEmail] = React.useState<string>("");
  const [testResult, setTestResult] = React.useState<boolean>();
  const [emailOrDomain, setEmailOrDomain] = React.useState<string>("");
  const [authorizedAccounts, setAuthorizedAccounts] = React.useState<
    { email_or_domain: string }[]
  >([]);
  const { postgrest } = useApi();

  const fetchAuthorizedAccounts = async () => {
    const result = await postgrest.from("authorized_account").select("*");
    return result.data;
  };

  React.useEffect(() => {
    fetchAuthorizedAccounts().then(setAuthorizedAccounts);
  }, []);

  return (
    <Stack direction="column" spacing={1} sx={{ width: 400 }}>
      <List
        subheader={
          <>
            <ListSubheader component="div" id="nested-list-subheader">
              Valid Emails/Domains
            </ListSubheader>
            <Divider />
          </>
        }
      >
        <Box
          sx={{
            maxHeight: 500,
            overflow: "auto",
            ...scrollSx,
          }}
        >
          {authorizedAccounts.map((authorizedAccount) => (
            <ListItem
              key={authorizedAccount.email_or_domain}
              secondaryAction={
                <IconButton
                  onClick={async () => {
                    await postgrest
                      .from("authorized_account")
                      .delete()
                      .eq("email_or_domain", authorizedAccount.email_or_domain);
                    fetchAuthorizedAccounts().then(setAuthorizedAccounts);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={authorizedAccount.email_or_domain} />
            </ListItem>
          ))}
        </Box>
      </List>
      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          placeholder="New Email/Domain"
          size="small"
          onChange={(e) => setEmailOrDomain(e.target.value)}
          value={emailOrDomain}
        />
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            // don't let someone input a very liberal regex
            if (emailOrDomain.length < 3) {
              return;
            }
            await postgrest
              .from("authorized_account")
              .insert({ email_or_domain: emailOrDomain });
            fetchAuthorizedAccounts().then(setAuthorizedAccounts);
            setEmailOrDomain("");
          }}
        >
          Add
        </Button>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            size="small"
            placeholder="Email to test"
            onChange={(e) => {
              setTestResult(null);
              setTestEmail(e.target.value);
            }}
            value={testEmail}
          />
          <Button
            variant="contained"
            size="small"
            onClick={async () => {
              setTestResult(null);
              const result = await postgrest.rpc("is_authorized", {
                email: testEmail,
              });
              setTestResult(result.data as boolean);
            }}
          >
            Test
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          {testResult === true && <CheckIcon sx={{ color: "green" }} />}
          {testResult === false && <CloseIcon sx={{ color: "red" }} />}
          <Typography>
            {testResult === true &&
              "Passed. This email will be allowed access."}
            {testResult === false &&
              "Failed. This email will not be allowed access."}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
