import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { PercentageInput } from "../../../widgets/PercentInput/PercentInput";
import React = require("react");

type Props = {
  aircraftFee: number;
  camFees?: number;
  totalFee?: number;
};

export const AnnualTenantTable: React.FC<Props> = ({
  aircraftFee,
  camFees,
  totalFee,
}) => {
  const [percentageIncrease, setPercentageIncrease] = useState<number>(5);

  // Calculate fees for 5 years
  const fees = Array.from({ length: 5 }, (_, i) => {
    if (!aircraftFee) {
      return {
        aircraftFee: "---",
        camFees: "---",
        totalFee: "---",
      };
    }
    return {
      aircraftFee: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(aircraftFee * Math.pow(1 + percentageIncrease / 100, i)),

      camFees: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(camFees * Math.pow(1 + percentageIncrease / 100, i)),
      totalFee: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(totalFee * Math.pow(1 + percentageIncrease / 100, i)),
    };
  });

  const showBufferColumn = camFees !== undefined;
  const showBufferAndCamColumn = totalFee !== undefined;

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Annual % Increase</Typography>
        <PercentageInput
          initialValue={percentageIncrease}
          onChange={(value) => setPercentageIncrease(value)}
        />
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Aircraft Fee</TableCell>
            {showBufferColumn && <TableCell>CAM Fees</TableCell>}
            {showBufferAndCamColumn && <TableCell>Total Fee</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {fees.map((fee, index) => (
            <TableRow key={index}>
              <TableCell align="center">{`Year ${index + 1}`}</TableCell>
              <TableCell align="center">{fee.aircraftFee}</TableCell>
              {showBufferColumn && (
                <TableCell align="center">{fee.camFees}</TableCell>
              )}
              {showBufferAndCamColumn && (
                <TableCell align="center">{fee.totalFee}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};
