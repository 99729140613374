import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import * as geometric from "geometric";
import { sum } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { getStackPolygons, removeNonNumericAndParse } from "../../hooks/utils";
import { EntityPolygon, FBO, Hangar, Tenant } from "../../types";
import { SexyText } from "../../widgets/SexyText/SexyText";
import {
  calcEstimateTowTime,
  estimateValue,
} from "../Hangars/HangarsPresenter";
import { CustomPlacementOptions, CustomStackForm } from "./CustomStackDialog";

import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatCurrency, formatNumber } from "../../utils";
import { feetToMeters } from "../../utils/math";
import { useLayout } from "../Ramp/CollapsibleRampActionCard";
import { MicroMetric } from "../Ramp/RampInfoCard";

type Props = {
  hangar: Hangar;
  onClickBack?: () => void;
  customPlacementOptions: CustomPlacementOptions;
  setCustomPlacementOptions: (cpo: CustomPlacementOptions) => void;
  isReference?: boolean;
  isAlgorithmTesting?: boolean;
  activeHangarId?: string;
  setActiveHangarId?: (id: string) => void;
  multiHangar?: boolean;
  hangars?: Hangar[];
};

export const findTenantsFullyInHangar = (
  hangar: Hangar,
  entityPolygons: EntityPolygon[]
): Tenant[] => {
  const buffer = 2.5;
  const hangarPolygon: geometric.Polygon = [
    [0 - buffer, 0 - buffer],
    [0 - buffer, hangar.depth + buffer],
    [hangar.width + buffer, hangar.depth + buffer],
    [hangar.width + buffer, 0 - buffer],
  ];
  const tenantIdsFullyInHangar = entityPolygons
    .filter(({ polygon }) => {
      return geometric.polygonInPolygon(
        polygon as geometric.Polygon,
        hangarPolygon
      );
    })
    .map(({ entity_id }) => entity_id);
  const tenantsInHangar = hangar.stack.tenants.filter((tenant) => {
    return tenantIdsFullyInHangar.indexOf(tenant.entity_id) > -1;
  });
  return tenantsInHangar;
};

export const calculateTotalArea = (
  hangar: Hangar,
  ignoreUnusableArea: boolean = false
): number => {
  if (ignoreUnusableArea) {
    const unusableArea = sum([
      ...hangar.obstacles.map((o) => o.width * o.depth),
      hangar.wall_spacing_left * hangar.depth,
      hangar.wall_spacing_back * hangar.width,
      hangar.wall_spacing_right * hangar.depth,
      // these areas get double counted b/c they overlap
      -hangar.wall_spacing_left * hangar.wall_spacing_back,
      -hangar.wall_spacing_right * hangar.wall_spacing_back,
    ]);
    return hangar.width * hangar.depth - unusableArea;
  }
  return hangar.width * hangar.depth;
};

export const calculateUtilization = (
  hangar: Hangar,
  polygons: EntityPolygon[],
  limitToInHangar: boolean = false,
  ignoreUnusableArea: boolean = false,
  feet: boolean = false
): number => {
  const totalUtilization = sum(
    (limitToInHangar
      ? findTenantsFullyInHangar(hangar, polygons)
      : hangar.stack.tenants
    ).map(({ aircraft }) => aircraft.wingspan * aircraft.length)
  );

  if (feet) {
    return totalUtilization;
  }

  const totalArea = calculateTotalArea(hangar, ignoreUnusableArea);

  return Math.round((100 * totalUtilization) / totalArea);
};

export const estimatePotentialValue = (fbo: FBO, hangar: Hangar) => {
  const totalSqft = sum(
    hangar.stack.tenants
      .filter((t) => t.type === "transient")
      .map((t) => t.aircraft.wingspan * t.aircraft.length)
  );
  const sqftValue = fbo.nightly_transient_rate ?? 0.2;
  return formatCurrency(sqftValue * totalSqft, 0);
};

export const HangarInfoCard: React.FC<Props> = ({
  hangar,
  onClickBack,
  customPlacementOptions,
  setCustomPlacementOptions,
  isReference,
  isAlgorithmTesting = false,
  activeHangarId,
  setActiveHangarId,
  multiHangar = false,
  hangars = [],
}) => {
  const { isDrawerOpen } = useLayout();
  const { activeFBO } = useActiveFBOs();
  const padding = 1;
  /**
   * we are cheating a bit on these useMemos and useDebouncedValues. when this component is rendered,
   * the hangar itself isn't changing (dimensions, doors, etc.). so we can focus only on the geometry
   * of the hangar and the entities inside it for changes.
   */
  const entityPolygons = useDebouncedValue(
    () => getStackPolygons(hangar.stack, hangar.width, 1),
    50,
    [hangar.stack]
  );
  const displayEstimatedValue =
    activeFBO.user_role === "manager"
      ? activeFBO?.settings?.displayEstimatedValueForManager ?? true
      : activeFBO?.settings?.displayEstimatedValueForOperator ?? true;

  const tenantsFullyInHangar = useDebouncedValue<Tenant[]>(
    () => {
      return findTenantsFullyInHangar(hangar, entityPolygons);
    },
    100,
    [
      // hangar.width, hangar.depth, hangar.stack.tenants,
      entityPolygons,
    ]
  );

  const actualUtilization = useDebouncedValue<number>(
    () => {
      return calculateUtilization(
        hangar,
        entityPolygons,
        true,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
      );
    },
    100,
    [
      // hangar,
      entityPolygons,
      activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
    ]
  );

  const totalPossibleUtilization = useDebouncedValue<number>(
    () => {
      return calculateUtilization(
        hangar,
        entityPolygons,
        null,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
      );
    },
    100,
    [
      // hangar,
      entityPolygons,
      activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
    ]
  );

  const dailyEstimatedValue = useDebouncedValue<string>(
    () => {
      return estimateValue(
        activeFBO,
        hangar,
        entityPolygons,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
      );
    },
    100,
    [
      activeFBO?.nightly_base_rate,
      // hangar,
      entityPolygons,
    ]
  );

  const monthlyEstimatedValue = useDebouncedValue(
    () => {
      return estimateValue(
        activeFBO,
        hangar,
        entityPolygons,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
        true
      );
    },
    100,
    [
      activeFBO?.nightly_base_rate,
      // hangar,
      entityPolygons,
    ]
  );

  const estimatedTowTime = useDebouncedValue<string>(
    () => {
      return calcEstimateTowTime(hangar, entityPolygons);
    },
    100,
    [
      // hangar,
      entityPolygons,
    ]
  );

  const doorSize = activeFBO?.settings?.useMetricSystem ? (
    <>
      {formatNumber(
        feetToMeters(hangar.width - (hangar.right_door + hangar.left_door)),
        2
      )}
      L x {formatNumber(feetToMeters(hangar.height), 2)}H
    </>
  ) : (
    <>
      {formatNumber(hangar.width - (hangar.right_door + hangar.left_door), 0)}L
      x {formatNumber(hangar.height, 0)}H
    </>
  );
  const dimensions = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(hangar.depth), 1)}L x ${formatNumber(
        feetToMeters(hangar.width),
        1
      )}W`
    : `${hangar.depth}L x ${hangar.width}W`;

  if (!isDrawerOpen) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        width={130}
        height={105}
      >
        {multiHangar && (
          <Typography variant="caption">{hangar.name}</Typography>
        )}
        <Stack
          direction="column"
          justifyContent="center"
          spacing={1}
          sx={{
            p: 1,
            pt: 0,
            maxHeight: "100%",
          }}
        >
          <MicroMetric
            icon={<ConnectingAirportsIcon />}
            value={
              <>
                <SexyText
                  value={tenantsFullyInHangar.length}
                  text={tenantsFullyInHangar.length}
                />{" "}
                / {`${hangar.stack.tenants.length}`}
              </>
            }
            tooltip="# aircraft inside hangar / # aircraft wanted inside"
          />

          <MicroMetric
            icon={<PictureInPictureIcon />}
            value={
              <>
                <span style={{ color: "inherit" }}>
                  <SexyText
                    value={actualUtilization}
                    text={`${actualUtilization}%`}
                  />{" "}
                </span>
                / {totalPossibleUtilization}%
              </>
            }
            tooltip="Utilization of aircraft inside hangar / Utilization of aircraft wanted inside"
          />
          <MicroMetric
            icon={<AccessTimeIcon />}
            value={estimatedTowTime}
            tooltip="Estimated time it would take to tow every aircraft out of the hangar."
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: padding,
        maxHeight: "100%",
      }}
    >
      <Stack direction="column" spacing={1}>
        {multiHangar && <Typography>{hangar.name}</Typography>}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Aircraft
              </Typography>
              <Tooltip title="# aircraft inside hangar / # aircraft wanted inside">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <span style={{ color: "inherit" }}>
                <SexyText
                  value={tenantsFullyInHangar.length}
                  text={tenantsFullyInHangar.length}
                />{" "}
              </span>
              / {`${hangar.stack.tenants.length}`}
            </Typography>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Utilization
              </Typography>
              <Tooltip title="Utilization of aircraft inside hangar / Utilization of aircraft wanted inside">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <span style={{ color: "inherit" }}>
                <SexyText
                  value={actualUtilization}
                  text={`${actualUtilization}%`}
                />{" "}
              </span>
              / {totalPossibleUtilization}%
            </Typography>
          </Stack>
          {!multiHangar && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="column" sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body2" textAlign="center">
                    Door Size
                  </Typography>
                </Stack>
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="info.dark"
                >
                  {doorSize}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          {displayEstimatedValue && (
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="body2" textAlign="center">
                  Est. Value
                </Typography>
                <Tooltip title="Estimated value of your hangar. This includes all base tenants and any transients you're able to fit in the hangar.">
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                <SexyText
                  value={removeNonNumericAndParse(dailyEstimatedValue)}
                  text={`${dailyEstimatedValue} / day`}
                />
                {hangar.stack.name === "reference" && (
                  <>
                    <br />
                    <SexyText
                      value={removeNonNumericAndParse(monthlyEstimatedValue)}
                      text={`${monthlyEstimatedValue} / mo`}
                    />
                  </>
                )}
              </Typography>
            </Stack>
          )}
          {displayEstimatedValue && (
            <Divider orientation="vertical" variant="middle" flexItem />
          )}
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Est. Tow Time
              </Typography>
              <Tooltip title="Estimated time it would take to tow every aircraft out of the hangar.">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              {estimatedTowTime}
            </Typography>
          </Stack>
          {!multiHangar && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="body2" textAlign="center">
                  Dimensions
                </Typography>
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="info.dark"
                >
                  {dimensions}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {isAlgorithmTesting && (
        <Accordion disableGutters elevation={0} defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Parameters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomStackForm
              minimal
              isDev
              customPlacementOptions={customPlacementOptions}
              setCustomPlacementOptions={setCustomPlacementOptions}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  );
};
