import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { CornerStrategy } from "../../types";
import { CustomPlacementOptions } from "../Hangar/CustomStackDialog";
import { Aircraft } from "../LabelingTool";
import { HangarResult } from "../StackMaster/StackMasterPresenter";
import { OptimizationHangarResultRow } from "./OptimizationHangarResultRow";

type Props = {
  aircrafts: Aircraft[];
  results: HangarResult[];
  onClickRun: (customPlacementOptions: CustomPlacementOptions) => Promise<void>;
};

export const OptimizationHangarResults: React.FC<Props> = ({
  aircrafts,
  results,
  onClickRun,
}) => {
  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={8}
        width="100%"
      >
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={() => {
            window.globalThis.airplxKillSwitch = 1;
            window.globalThis?.airplxAborter?.abort();
          }}
        >
          Stop
        </Button>
        <Button
          fullWidth
          variant="contained"
          disabled={aircrafts.length === 0}
          onClick={() =>
            onClickRun({
              wall_spacing_left: 2,
              wall_spacing_back: 2,
              wall_spacing_right: 2,
              horizontal_spacing: 2,
              vertical_spacing: 2,
              overlap: false,
              tug_spacing: 2,
              density_threshold: 0.8,
              corner_strategy: [
                CornerStrategy.ANGLE_45,
                CornerStrategy.ANGLE_45,
              ],
              xy_weights: [1, 1],
              towing_equipment_id: null,
            })
          }
        >
          Run
        </Button>
      </Stack>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Hangar</TableCell>
            <TableCell>Doors</TableCell>
            <TableCell>Progress</TableCell>
            <TableCell>
              <Stack direction="column" alignItems="center">
                <Typography variant="inherit"># in Hangar</Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="inherit">Worst</Typography>
                  <Typography variant="inherit">/</Typography>
                  <Typography variant="inherit">Best</Typography>
                  <Typography variant="inherit">/</Typography>
                  <Typography variant="inherit">Average</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="column" alignItems="center">
                <Typography variant="inherit">Utilization</Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="inherit">Worst</Typography>
                  <Typography variant="inherit">/</Typography>
                  <Typography variant="inherit">Best</Typography>
                  <Typography variant="inherit">/</Typography>
                  <Typography variant="inherit">Average</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((r) => (
            <OptimizationHangarResultRow key={r.id} result={r} />
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};
