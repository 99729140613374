import { Button, Grow, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import logo from "../../../airplx-logo-transparent-square-no-text.png";
import { useTokenMaker } from "../../hooks";

export const LoginWithLink: React.FC<{}> = () => {
  const { url: tokenMakerURL } = useTokenMaker();
  const [email, setEmail] = React.useState<string>("");
  const [sent, setSent] = React.useState<boolean>(false);
  const onClickSend = async () => {
    await fetch(`${tokenMakerURL}/login/link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    setSent(true);
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="60vh"
        width="100vw"
      >
        <Stack direction="column" width={450}>
          <Grow in={!sent}>
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography variant="h6">Login with a Magic Link</Typography>
              <Typography variant="body2" color="textSecondary">
                Enter your email address below to receive a magic link to login.
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <TextField
                  size="small"
                  fullWidth
                  label="Email"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button
                  disabled={
                    // disabled if email does not match @airplx.com or @jetaviation.com
                    !email.match(/@(gmail|hotmail|airplx|jetaviation)\.com$/) ||
                    email.length === 0
                  }
                  variant="contained"
                  color="primary"
                  onClick={onClickSend}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Grow>
          <Grow in={sent}>
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography variant="h6">Check your email</Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                An email with subject <u>Login to AirPlx</u> has been sent to{" "}
                <u>{email}</u> with a magic link has been sent to your email
                address. Click the link to login.
              </Typography>
            </Stack>
          </Grow>
        </Stack>
      </Stack>
      <img
        src={logo}
        style={{ position: "fixed", bottom: 15, right: 15, width: 50 }}
      />
    </>
  );
};
