import { Box } from "@mui/material";
import * as React from "react";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { Preferences } from "../../hooks/useUserPreferences";
import { RampCanvasReadOnlyNew } from "../../screens/Settings/RampsSettings/RampEditor/RampCanvas/RampCanvasReadOnly";
import { RampCanvasReadOnly } from "../../screens/Settings/RampsSettings/RampEditor/RampCanvasReadOnly";
import { Hangar, Location, Ramp } from "../../types";
import { HangarLayoutBackground } from "./HangarLayoutBackground";
import { MeasuringTool } from "./MeasuringTool";

type Props = {
  selectable: boolean;
  location: Location<Hangar | Ramp>;
  preferences: Preferences;
  width: number;
  height: number;
  feetToPixels: number;
  zoomLevel?: number;
  backgroundColor: string;
  dashColor: string;
  borderColor?: string;
  printable?: boolean;
  readOnly?: boolean;
  onClick: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseDown: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseUp: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseMove: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void;
  onContextMenu: (evt: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
};

export const LayoutBackground = React.forwardRef((props: Props, ref) => {
  const {
    selectable,
    location,
    preferences,
    width,
    height,
    feetToPixels,
    zoomLevel,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onContextMenu,
    backgroundColor,
    dashColor,
    borderColor,
    printable,
    ...otherProps
  } = props;

  // TODO: not sure wtf is going on here...
  const { getHangar, isMultiHangar } = useMultiHangarState();
  const { hangar: hangarX } = useThisHangar();
  const hangar =
    (isMultiHangar ? getHangar(location.id) : hangarX) || (location as Hangar);
  if (location.type === "hangar") {
    return (
      <MeasuringTool pixelsToFeetRatio={feetToPixels}>
        <HangarLayoutBackground
          ref={ref}
          hangar={hangar}
          selectable={false}
          width={width}
          height={height}
          feetToPixels={feetToPixels}
          backgroundColor={backgroundColor}
          dashColor={dashColor}
          borderColor={borderColor}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseEnter={onMouseEnter}
          onContextMenu={onContextMenu}
        >
          {props.children}
        </HangarLayoutBackground>
      </MeasuringTool>
    );
  }

  const { ramp } = useRampState();
  const showReferenceImage = preferences?.showRampReferenceImage ?? false;
  const rampBackgroundColor = showReferenceImage
    ? `${props.backgroundColor}80`
    : props.backgroundColor;

  if (printable) {
    return (
      <Box>
        {props.children}
        {ramp?.version === "v1" ? (
          <RampCanvasReadOnly
            ramp={ramp}
            width={width}
            backgroundColor={rampBackgroundColor}
            showReferenceImage={showReferenceImage}
          />
        ) : (
          <RampCanvasReadOnlyNew
            ramp={ramp}
            width={width}
            showReferenceImage={showReferenceImage}
            zoomLevel={zoomLevel || 1}
          />
        )}
      </Box>
    );
  }

  return (
    <MeasuringTool pixelsToFeetRatio={feetToPixels}>
      <Box
        ref={ref}
        onClick={onClick}
        onContextMenu={onContextMenu}
        sx={{
          position: "relative",
          width,
          // height,
          height: "100%",
          marginBottom: "-7px",
          // TODO: i'm not sure why this was here, but it was causing the planes at the bottom of the ramp to be cut off
          // overflow: "hidden",
          "&:hover": selectable
            ? {
                border: "2px solid black",
                borderColor: "success.main",
                cursor: "pointer",
              }
            : {},
        }}
      >
        {props.children}
        {ramp?.version === "v1" ? (
          <RampCanvasReadOnly
            ramp={ramp}
            width={width}
            backgroundColor={rampBackgroundColor}
            showReferenceImage={showReferenceImage}
          />
        ) : (
          <RampCanvasReadOnlyNew
            ramp={ramp}
            width={width}
            showReferenceImage={showReferenceImage}
            zoomLevel={zoomLevel || 1}
          />
        )}
      </Box>
    </MeasuringTool>
  );
});
