import * as md5 from "md5";
import * as React from "react";
import { usePrevious } from "react-use";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { Shape } from "../screens/Settings/RampsSettings/RampEditor/RampCanvas/RampCanvas";
import {
  Marking,
  MovableObstacle,
  Position,
  Ramp,
  Stack,
  Tenant,
} from "../types";
import { fetchRamp } from "./api";
import { isLocationChangeMeaningful } from "./useHangar";
import { useStateHistory } from "./useMeaningfulStateHistory";
import useSyncToTable from "./useSyncToTable";

export const useRamp = (
  ramp_id: string,
  isReference: boolean,
  includeReferenceImage: boolean
) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [ready, setReady] = React.useState<boolean>(false);
  const [ramp, setRamp, undo] = useStateHistory<Ramp>(
    null,
    isLocationChangeMeaningful
  );

  // 'stack' is just for convenience. the data that gets synced is the stack[] since it's now a table.
  React.useEffect(() => {
    if (!ramp?.stack) {
      return;
    }
    setRamp({
      ...ramp,
      stacks: ramp?.stacks?.map((stack) =>
        stack.id === ramp.stack.id ? ramp.stack : stack
      ),
    });
  }, [md5(JSON.stringify(ramp?.stack) ?? "")]);

  useSyncToTable<Ramp>({
    thingToWatch: ramp,
    tableName: "ramp",
  });

  const stacks = ramp?.stacks ?? [];
  useSyncToTable<Stack[]>({
    tableName: "stack",
    thingToWatch: Boolean(ramp) ? stacks : null,
  });

  useSyncToTable<Tenant[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(ramp) ? ramp?.stack?.tenants : null,
    tableName: "tenant",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete tenants. they just leave the hangar. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the hangar.
    },
  });

  useSyncToTable<MovableObstacle[]>({
    thingToWatch: Boolean(ramp) ? ramp?.stack?.movableObstacles : null,
    tableName: "movable_obstacle",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete movable obstacles. they just leave the ramp. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the ramp.
    },
  });

  const currentPositions: Position[] = [];
  for (const stack of ramp?.stacks ?? []) {
    for (const tenant of stack.tenants ?? []) {
      currentPositions.push(tenant.position);
    }
    for (const movableObstacle of stack.movableObstacles ?? []) {
      currentPositions.push(movableObstacle.position);
    }
  }

  useSyncToTable<Position[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(ramp) ? currentPositions : null,
    tableName: "position",
    debounce: 500,
  });

  useSyncToTable<Marking[]>({
    thingToWatch: Boolean(ramp) ? ramp?.markings : null,
    tableName: "marking",
  });

  useSyncToTable<Shape[]>({
    thingToWatch: Boolean(ramp) ? ramp?.shapes : null,
    tableName: "shape",
  });

  const previousRamp = usePrevious<Ramp>(ramp);

  // ok let's grab the ramp! we only do this once. changes are tracked in memory that
  // match with the db
  React.useEffect(() => {
    (async () => {
      const zRamp = await fetchRamp(
        postgrest,
        activeFBO,
        ramp_id,
        isReference,
        includeReferenceImage
      );
      setRamp({ ...zRamp });
      setReady(true);
    })();
  }, [ramp_id]);

  return {
    ready: ready && Boolean(ramp),
    ramp: ramp,
    setRamp,
    isReference,
    history: {
      undo,
    },
  };
};
