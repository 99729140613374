import { Button, Dialog } from "@mui/material";
import imageCompression from "browser-image-compression";
import * as React from "react";
import { formatPercentage } from "../../utils";
import { Loading } from "../Loading";

type Props = {
  label: string;
  onLoad: (image: string) => void;
};

export const ButtonUploadImage: React.FC<Props> = ({ label, onLoad }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <>
      <Button size="small" variant="contained" component="label">
        {label}
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={async (evt) => {
            setLoading(true);
            const file = evt.target.files[0];
            if (file) {
              const compressedFile = await imageCompression(file, {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              });
              const originalSize = file.size / 1024 / 1024;
              const compressedSize = compressedFile.size / 1024 / 1024;
              console.log(
                `original image size: ${originalSize} MB\ncompressed image size: ${compressedSize} MB\n${formatPercentage(
                  (100 * compressedSize) / originalSize,
                  1
                )} smaller`
              );

              const reader = new FileReader();
              reader.onload = async (e) => {
                setLoading(false);
                onLoad(e.target.result as string);
              };
              reader.readAsDataURL(compressedFile);
            }
          }}
        />
        <Dialog open={loading} onClose={() => setLoading(false)} fullScreen>
          <Loading message="Compressing image. This will take a few seconds." />
        </Dialog>
      </Button>
    </>
  );
};
