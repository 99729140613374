import { Stack } from "@mui/material";
import * as React from "react";
import { AircraftLastUpdated } from "./AircraftLastUpdated";
import { WorkerStatus } from "./WorkerStatus";

export const UberAdmin: React.FC<{}> = () => {
  return (
    <Stack direction="column" spacing={4} maxWidth={1000}>
      <WorkerStatus />
      <AircraftLastUpdated />
    </Stack>
  );
};
