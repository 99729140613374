import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { useLayout } from "../../screens/Ramp/CollapsibleRampActionCard";
import { AddTenantButton } from "../../screens/Settings/HangarSettings/AddTenantButton";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
  Tenant,
} from "../../types";
import { scrollSx } from "../../utils";
import { AircraftListItem } from "./AircraftListItem";
import { AircraftPreferencesListItemMenuButton } from "./AircraftPreferencesListItemMenu";

type Props = {
  isAlgorithmTesting?: boolean;
  location: Location<Hangar | Ramp>;
  stack: StackType;
  setStack: (stack: StackType) => void;
  width?: number;
  readOnly?: boolean;
  feetToPixels?: number;
};

const sortTenants = (tenants: Tenant[]): Tenant[] => {
  return tenants.sort((a: Tenant, b: Tenant) => {
    // if (a.selected) {
    //   return -1;
    // }
    // if (b.selected) {
    //   return 1;
    // }
    if (!a.tail_number) {
      return 1;
    }
    if (!b.tail_number) {
      return -1;
    }
    if (!a.tail_number?.toLowerCase().startsWith("n")) {
      return 1;
    }
    if (!b.tail_number?.toLowerCase().startsWith("n")) {
      return -1;
    }
    if (a.tail_number > b.tail_number) {
      return 1;
    }
    if (a.tail_number < b.tail_number) {
      return -1;
    }
    return 1;
  });
};

export const CurrentAircraft: React.FC<Props> = ({
  location,
  stack,
  setStack,
  width,
  feetToPixels,
  readOnly = false,
}) => {
  const theme = useTheme();
  const { isDrawerOpen } = useLayout();
  const { sendToLocation } = useLocationsState();
  const sortedTenants = React.useMemo(
    () =>
      [...stack.tenants]
        .sort((a, b) => {
          if (a.selected) {
            return -1;
          }
          if (b.selected) {
            return 1;
          }
          return a.tail_number.localeCompare(b.tail_number);
        })
        .filter((t) => t.position.stack_id === stack.id)
        .filter((tenant) => tenant.aircraft?.make),
    [stack.tenants]
  );
  // const sortedTenants = React.useMemo(() => stack.tenants, [stack.tenants]);

  const onAddAircraft = React.useCallback(
    (tenant: Tenant | Tenant[]) => {
      if (Array.isArray(tenant)) {
        setStack({
          ...stack,
          tenants: [...tenant, ...stack.tenants],
        });
        return;
      }
      setStack({
        ...stack,
        tenants: [tenant, ...stack.tenants],
      });
    },
    [stack, setStack]
  );

  const setTenant = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => (t.id === tenant.id ? tenant : t)),
      });
    },
    [stack, setStack]
  );

  const onDeleteAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.filter(
          (t) => t.placement_id !== tenant.placement_id
        ),
      });
    },
    [stack, setStack]
  );

  const onSelectAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => ({
          ...t,
          selected:
            t.placement_id === tenant.placement_id ? !t.selected : false,
        })),
      });
    },
    [stack, setStack]
  );

  if (!isDrawerOpen) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <AddTenantButton
          iconButton
          disabled={readOnly}
          onAdd={onAddAircraft}
          location={location}
          sendToLocation={sendToLocation}
          stack={stack}
          setStack={setStack}
          feetToPixels={feetToPixels}
        />
        <Divider flexItem />
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            ...scrollSx,
          }}
        >
          {sortedTenants.map((tenant, idx) => (
            <Tooltip
              key={`current-aircraft-${tenant.id}`}
              placement="right"
              title={`${tenant.tail_number || "N/A"} • ${
                tenant.aircraft.model
              }`}
            >
              <Stack
                key={`current-aircraft-${tenant.id}`}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                p={1}
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  bgcolor: tenant.selected
                    ? `${theme.palette.success.main}15`
                    : "transparent",
                  "&:hover": {
                    backgroundColor: `${theme.palette.success.main}15`,
                  },
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  maxWidth={60}
                  onClick={() => onSelectAircraft(tenant)}
                >
                  {tenant.tail_number || tenant.aircraft.model}
                </Typography>
                <AircraftPreferencesListItemMenuButton
                  stack={stack}
                  setStack={setStack}
                  tenant={tenant}
                  setTenant={setTenant}
                  preferences={tenant.position.preferences}
                  setPreferences={(preferences) => {
                    setTenant({
                      ...tenant,
                      position: {
                        ...tenant.position,
                        preferences,
                      },
                    });
                  }}
                  onDeleteAircraft={() => onDeleteAircraft(tenant)}
                />
              </Stack>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        minWidth: width ?? 400,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pr: 1, pl: 1 }}
      >
        <Typography>Current Aircraft</Typography>
        <AddTenantButton
          disabled={readOnly}
          onAdd={onAddAircraft}
          location={location}
          sendToLocation={sendToLocation}
          stack={stack}
          setStack={setStack}
          feetToPixels={feetToPixels}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          ...scrollSx,
        }}
      >
        {sortedTenants.map((tenant, idx) => (
          <React.Fragment key={`current-aircraft-${tenant.id}`}>
            <AircraftListItem
              showBottomBorder={idx + 1 === stack.tenants.length}
              stack={stack}
              setStack={setStack}
              tenant={tenant}
              setTenant={setTenant}
              onSelectAircraft={onSelectAircraft}
              onDeleteAircraft={onDeleteAircraft}
            />
          </React.Fragment>
        ))}
      </Box>
    </Stack>
  );
};
