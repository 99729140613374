import TuneIcon from "@mui/icons-material/Tune";
import {
  Button,
  FormControlLabel,
  Popover,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useUserPreferences } from "../../containers/UserPreferencesContainer";

type Props = {
  locationType: string;
};

export const ViewSettings: React.FC<Props> = ({ locationType }) => {
  const { activeFBO } = useActiveFBOs();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { preferences, setPreferences } = useUserPreferences();

  const showCoPilot =
    locationType === "hangar" &&
    activeFBO?.subscription.includes("autostacking");

  return (
    <>
      <Button
        variant="contained"
        color="info"
        onClick={handleClick}
        startIcon={<TuneIcon />}
        sx={{
          "@media (max-width: 1300px)": { display: "none" },
        }}
      >
        View Settings
      </Button>
      <Button
        color="info"
        variant="contained"
        onClick={handleClick}
        sx={{
          "@media (min-width: 1300px)": { display: "none" },
          mr: 1,
        }}
      >
        <TuneIcon />
      </Button>
      <Popover
        data-testid="view_settings"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="column" alignItems="flex-end" sx={{ p: 2 }}>
          <Tooltip title="Display tail number on aircraft" placement="right">
            <FormControlLabel
              control={
                <Switch
                  data-testid="toggle_tail_number"
                  color="primary"
                  checked={preferences.forceDisplayTailNumber ?? false}
                  onChange={() =>
                    setPreferences({
                      ...preferences,
                      forceDisplayTailNumber: !preferences.forceDisplayTailNumber,
                    })
                  }
                />
              }
              label="Tail #"
              labelPlacement="start"
            />
          </Tooltip>
          <Tooltip
            title="To see single note vs all notes, go to Settings > Configuration"
            placement="right"
          >
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={preferences.notes ?? false}
                  onChange={() =>
                    setPreferences({
                      ...preferences,
                      notes: !preferences.notes,
                    })
                  }
                />
              }
              label="Notes"
              labelPlacement="start"
            />
          </Tooltip>
          <Tooltip title="Display aircraft model on aircraft" placement="right">
            <FormControlLabel
              control={
                <Switch
                  data-testid="toggle_model"
                  color="primary"
                  checked={preferences.forceDisplayModel ?? false}
                  onChange={() =>
                    setPreferences({
                      ...preferences,
                      forceDisplayModel: !preferences.forceDisplayModel,
                    })
                  }
                />
              }
              label="Model"
              labelPlacement="start"
            />
          </Tooltip>
          <Tooltip title="Horizontal spacing outline" placement="right">
            <FormControlLabel
              control={
                <Switch
                  data-testid="toggle_sop_spacing"
                  color="primary"
                  checked={preferences.showSOPSpacing ?? false}
                  onChange={() =>
                    setPreferences({
                      ...preferences,
                      showSOPSpacing: !preferences.showSOPSpacing,
                    })
                  }
                />
              }
              label="SOP Spacing"
              labelPlacement="start"
            />
          </Tooltip>
          <Tooltip
            title="Highlights all overlaps, doesn’t evaluate vertical spacing safety"
            placement="right"
          >
            <FormControlLabel
              control={
                <Switch
                  data-testid="toggle_overlap"
                  color="primary"
                  checked={preferences.showOverlaps ?? false}
                  onChange={() =>
                    setPreferences({
                      ...preferences,
                      showOverlaps: !preferences.showOverlaps,
                    })
                  }
                />
              }
              label="Overlaps"
              labelPlacement="start"
            />
          </Tooltip>
          {showCoPilot && (
            <Tooltip
              title="Select unstacked aircraft and get SOP-compliant options"
              placement="right"
            >
              <FormControlLabel
                disabled={!activeFBO.subscription.includes("autostacking")}
                control={
                  <Switch
                    data-testid="toggle_copilot"
                    color="primary"
                    checked={preferences.enableCoPilot ?? false}
                    onChange={() =>
                      setPreferences({
                        ...preferences,
                        enableCoPilot: !preferences.enableCoPilot,
                      })
                    }
                  />
                }
                label="Co-Pilot"
                labelPlacement="start"
              />
            </Tooltip>
          )}
          {locationType === "ramp" && (
            <Tooltip title="Overlay ramp satellite image" placement="right">
              <FormControlLabel
                control={
                  <Switch
                    data-testid="toggle_ramp_reference_image"
                    color="primary"
                    checked={preferences.showRampReferenceImage ?? false}
                    onChange={() =>
                      setPreferences({
                        ...preferences,
                        showRampReferenceImage: !preferences.showRampReferenceImage,
                      })
                    }
                  />
                }
                label="Ramp Image"
                labelPlacement="start"
              />
            </Tooltip>
          )}
        </Stack>
      </Popover>
    </>
  );
};
