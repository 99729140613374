import { Stack, Typography } from "@mui/material";
import * as React from "react";
import loadingSvg from "../../../static/loading.svg";

export const Loading: React.FC<{
  message?: string;
}> = ({ message }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ height: "100%", width: "100%" }}
  >
    <img src={loadingSvg} style={{ height: 150 }} />
    {// If a message is provided, display it
    message && <Typography>{message}</Typography>}
  </Stack>
);
