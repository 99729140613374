import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../../../containers/ActiveFBOContainer";
import { formatNumber } from "../../../../../utils";
import { feetToMeters } from "../../../../../utils/math";
import { getDimensions } from "../RampCanvas/math";
import { Shape } from "../RampCanvas/RampCanvas";
import { ShapeEditDialog } from "../RampCanvas/ShapeEditDialog";
import { useLocalRampState } from "../RampEditorPresenter";
import { Tool } from "./Toolbar";

type Props = {
  shapes: Shape[];
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  tools: Tool[];
};

export const ShapeElements: React.FC<Props> = ({
  shapes,
  setShapes,
  tools,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { setHighlightedShapes } = useLocalRampState();
  const [editShapeId, setEditShapeId] = React.useState<string | null>(null);

  return (
    <Box
      width="100%"
      sx={{
        maxHeight: 400,
        overflowY: "auto",
      }}
    >
      {shapes.length === 0 && (
        <Typography variant="subtitle2" align="center">
          No shapes added
        </Typography>
      )}
      {shapes.map((shape, index) => {
        const dimensions = getDimensions(shape.points);
        const formattedDimensions = activeFBO?.settings?.useMetricSystem
          ? `${formatNumber(
              feetToMeters(dimensions.width),
              0
            )}m x ${formatNumber(feetToMeters(dimensions.height), 0)}m`
          : `${formatNumber(dimensions.width, 0)}ft x ${formatNumber(
              dimensions.height,
              0
            )}ft`;

        return (
          <Stack
            key={`shape-stack-${index}`}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            onMouseEnter={async () => {
              setHighlightedShapes([shape.id]);
            }}
            onMouseLeave={async () => {
              setHighlightedShapes([]);
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {tools.find((t) => t.name === shape.type)?.icon}
              <Typography variant="subtitle2">{formattedDimensions}</Typography>
            </Stack>
            <Stack direction="row">
              <IconButton
                onClick={() => {
                  setShapes(
                    shapes.map((s) => ({
                      ...s,
                      locked: s.id === shape.id ? !shape.locked : s.locked,
                    }))
                  );
                }}
              >
                {shape.locked ? (
                  <LockIcon sx={{ fontSize: 18 }} />
                ) : (
                  <LockOpenIcon sx={{ fontSize: 18 }} />
                )}
              </IconButton>
              <IconButton onClick={() => setEditShapeId(shape.id)}>
                <EditIcon sx={{ fontSize: 18 }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setShapes(shapes.filter((s) => s.id !== shape.id));
                }}
              >
                <DeleteIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Stack>
          </Stack>
        );
      })}
      {editShapeId && (
        <ShapeEditDialog
          contextMenu={{
            x: 0,
            y: 0,
            shapeId: editShapeId,
          }}
          setContextMenu={() => {}}
          onClose={() => setEditShapeId(null)}
          shapes={shapes}
          setShapes={setShapes}
        />
      )}
    </Box>
  );
};
