export const metersToFeet = (meters: number): number => {
  return meters * 3.28084;
};

export const feetToMeters = (feet: number): number => {
  return feet * 0.3048;
};

export const squareFeetToSquareMeters = (squareFeet: number): number => {
  return squareFeet * 0.092903;
};
