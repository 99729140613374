import { useCallback } from "react";
import { Ramp } from "../../../../../../types";
import {
  getSnapPoint,
  insertNewPoint,
  isOnText,
  isPointInShapeOrOnEdge,
} from "../math";
import { makeDefaultShape, Shape } from "../RampCanvas";
import { SHAPE_COLORS } from "../ShapeEditDialog";

type Props = {
  selectedTool: string;
  shapes: Shape[];
  currentShape: Shape;
  setCurrentShape: React.Dispatch<React.SetStateAction<Shape>>;
  setShape: (id: string, shape: Shape) => void;
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  setActiveShapes: React.Dispatch<React.SetStateAction<string[]>>;
  activeShapes: string[];
  canvasRef: React.RefObject<HTMLCanvasElement>;
  ramp: Ramp;
  isShiftPressed: boolean;
  isOptPressed: boolean;
  isCommandPressed: boolean;
  setMousePosition: React.Dispatch<
    React.SetStateAction<{ x: number; y: number } | null>
  >;
  setSelectedTool: React.Dispatch<React.SetStateAction<string>>;
};

export const useOnCanvasClick = (props: Props) => {
  const {
    selectedTool,
    shapes,
    currentShape,
    setCurrentShape,
    setShape,
    setShapes,
    setActiveShapes,
    activeShapes,
    canvasRef,
    ramp,
    isShiftPressed,
    isCommandPressed,
    isOptPressed,
    setMousePosition,
    setSelectedTool,
  } = props;
  return useCallback(
    (event: React.MouseEvent) => {
      if (selectedTool === "Measuring") {
        return;
      }

      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }

      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      if (selectedTool === "Select") {
        // look for any shapes that are clicked on
        const clickedShape = shapes.find((shape) => {
          if (shape.locked) {
            return false;
          }
          if (shape.type === "Text") {
            // since Text is just a single point, just evaluate that point w/ a nice buffer
            // adjust for the length of the text. the user will perceive the shape as being
            // the full length of the text on the screen
            const canvas = canvasRef.current;
            return isOnText(canvas, shape, { x, y });
          }
          return isPointInShapeOrOnEdge({ x, y }, shape.points);
        });

        if (clickedShape) {
          if (["Line", "Area"].includes(clickedShape.type)) {
            // handle inserting a point
            if (isCommandPressed) {
              setShape(clickedShape.id, {
                ...clickedShape,
                points: insertNewPoint(clickedShape.points, { x, y }),
              });
              return;
            }
            // handle deleting a point
            if (isOptPressed) {
              // if area only has 3 points, delete the whole shape. if line has 2 points, delete the whole shape
              const newPoints = clickedShape.points.filter(
                (point) => Math.hypot(point.x - x, point.y - y) > 10
              );
              if (
                (clickedShape.type === "Area" && newPoints.length < 3) ||
                (clickedShape.type === "Line" && newPoints.length < 2)
              ) {
                setShapes(
                  shapes.filter((shape) => shape.id !== clickedShape.id)
                );
              } else {
                setShape(clickedShape.id, {
                  ...clickedShape,
                  points: newPoints,
                });
              }
              return;
            }
          }
          // check to see if we're in multi-select mode
          if (isShiftPressed) {
            // handle shape selection
            if (activeShapes.includes(clickedShape.id)) {
              setActiveShapes(
                activeShapes.filter((id) => id !== clickedShape.id)
              );
            } else {
              setActiveShapes([...activeShapes, clickedShape.id]);
            }
          } else {
            if (activeShapes.includes(clickedShape.id)) {
              setActiveShapes(activeShapes);
            } else {
              setActiveShapes([clickedShape.id]);
            }
          }
        } else {
          setActiveShapes([]);
        }
        return;
      }

      // if we're in Line mode, check to see if we're done
      if (currentShape.points.length > 0) {
        const lastPoint = currentShape.points[currentShape.points.length - 1];
        // Check if the click is on the starting point to complete the shape
        if (Math.hypot(lastPoint.x - x, lastPoint.y - y) < 10) {
          setShapes([currentShape, ...shapes]);
          setCurrentShape(makeDefaultShape(ramp.fbo_id, ramp.id));
          setMousePosition(null);
          setSelectedTool("Select");
          return;
        }
      }

      // if we're in Area mode, check to see if we're done
      if (currentShape.points.length > 0) {
        const firstPoint = currentShape.points[0];
        // Check if the click is on the starting point to complete the shape
        if (Math.hypot(firstPoint.x - x, firstPoint.y - y) < 10) {
          setShapes([currentShape, ...shapes]);
          setCurrentShape({
            ...makeDefaultShape(ramp.fbo_id, ramp.id),
            fill_style: SHAPE_COLORS[0],
          });
          setMousePosition(null);
          setSelectedTool("Select");
          return;
        }
      }

      /**
       * If we're in 'snap' mode, then modify what will be the next point.
       */
      const isSnapMode =
        isShiftPressed && ["Line", "Area"].includes(selectedTool);
      const points = currentShape.points;
      const nextPoint = isSnapMode
        ? getSnapPoint({ x, y }, points[points.length - 1])
        : { x, y };

      // Add a new point to the current shape
      setCurrentShape({
        ...currentShape,
        type: selectedTool === "Area" ? "Area" : "Line",
        line_style: "solid",
        line_width: 3,
        stroke_style: SHAPE_COLORS[0],
        points: [...currentShape.points, nextPoint],
      });
    },
    [props]
  );
};
