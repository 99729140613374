import CopyAllIcon from "@mui/icons-material/CopyAll";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import * as React from "react";
import { useAsync, useKeyPress } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { useApi } from "../../../../../containers/ApiContainer";
import { Ramp } from "../../../../../types";
import { MeasuringTool } from "../../../../../widgets/Layout/MeasuringTool";
import Canvas from "../../../../../widgets/useCanvas";
import { ZoomControl } from "../../../../../widgets/ZoomControl";
import { useLocalRampState, useRampEditorState } from "../RampEditorPresenter";

import { useActiveFBOs } from "../../../../../containers/ActiveFBOContainer";
import { DraggingScrollContainer } from "../../../../../widgets/DraggingScrollContainer/DraggingScrollContainer";
import { Loading } from "../../../../../widgets/Loading";
import { CanvasTextInput } from "./CanvasTextInput";
import { drawMain } from "./drawing";
import { useCanvasHotkeys } from "./EventHandlers/useCanvasHotkeys";
import { useOnCanvasClick } from "./EventHandlers/useOnCanvasClick";
import { useOnContextMenu } from "./EventHandlers/useOnContextMenu";
import { clickedShape, useOnMouseDown } from "./EventHandlers/useOnMouseDown";
import { useOnMouseMove } from "./EventHandlers/useOnMouseMove";
import { usePatterns } from "./patterns/usePatterns";
import { SHAPE_COLORS, ShapeEditDialog } from "./ShapeEditDialog";

type Props = {
  ramp: Ramp;
  setRamp: (ramp: Ramp) => void;
  readOnly?: boolean;
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
};

export type ContextMenu = {
  x: number;
  y: number;
  shapeId: string;
};

export type DraggingPoint = {
  shapeId: string;
  pointIndex: number;
};
export type DraggingShape = {
  shapeId: string;
  offset: Point;
};

export type Point = { x: number; y: number };

export type Shape = {
  id: string;
  fbo_id: string;
  ramp_id: string;
  hangar_id?: string;
  tags: string[];
  points: Point[];
  type:
    | "Line"
    | "Text"
    | "Area"
    | "Tie Down T"
    | "Double T"
    | "Circle"
    | "Helipad";
  locked: boolean;
  stroke_style?: string;
  line_style?: "solid" | "dashed";
  line_width?: number;
  fill_style?: string;
  fill_pattern?: string;
  text?: string;
  font_size?: number;
};

export const makeDefaultShape = (fbo_id: string, ramp_id: string): Shape => ({
  id: uuidv4(),
  ramp_id,
  fbo_id,
  tags: [],
  points: [],
  type: "Line",
  locked: false,
  line_style: "solid",
  line_width: 1,
  stroke_style: SHAPE_COLORS[0],
  fill_style: null,
  font_size: null,
  text: null,
});

export const RampCanvas: React.FC<Props> = ({
  selectedTool,
  setSelectedTool,
  readOnly = false,
}) => {
  const { activeFBO } = useActiveFBOs();
  const {
    ramp,
    setRamp,
    highlightedShapes,
    activeShapes,
    setActiveShapes,
  } = useLocalRampState();

  const [history, setHistory] = React.useState<Shape[][]>([]); // History stack
  const { postgrest } = useApi();
  const { showReferenceImage, showMeasurements } = useRampEditorState();
  const [zoomLevel, setZoomLevel] = React.useState<number>(1);
  const [canvasWidth, setCanvasWidth] = React.useState<number>(600);
  const div = React.useCallback((node) => {
    if (node?.offsetWidth) {
      setCanvasWidth(node.offsetWidth * 0.9);
    }
  }, []);

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage && ramp?.reference_image) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [ramp.reference_image]);

  const canvasRef = React.createRef<HTMLCanvasElement>();
  const pixelsToFeetRatio = canvasWidth / (ramp?.width ?? 1);
  const canvasHeight = (canvasWidth * ramp?.depth) / ramp?.width;
  const pixelsToFeet = (px: number) => px / pixelsToFeetRatio;

  const { loading: loadingPatterns, patterns } = usePatterns();

  // convert shapes to pixels
  const shapes = React.useMemo(
    () =>
      ramp.shapes.map((shape) => ({
        ...shape,
        points: shape.points.map((point) => ({
          x: point.x * pixelsToFeetRatio,
          y: point.y * pixelsToFeetRatio,
        })),
      })),
    [ramp.shapes, pixelsToFeetRatio]
  );

  // returns active shapes in pixels
  const getActiveShapes = React.useCallback(() => {
    return shapes
      .filter(
        (s) => activeShapes.includes(s.id) || draggingShape?.shapeId === s.id
      )
      .filter((s) => !s.locked);
  }, [shapes, activeShapes]);

  // set a given shape, converting from pixels to feet
  const setShape = React.useCallback(
    (id: string, zShape: Shape) => {
      setRamp({
        ...ramp,
        shapes: shapes.map((shape) => ({
          ...(shape.id === id ? zShape : shape),
          points: (shape.id === id ? zShape : shape).points.map((point) => ({
            x: point.x / pixelsToFeetRatio,
            y: point.y / pixelsToFeetRatio,
          })),
        })),
      });
    },
    [ramp, setRamp, shapes, pixelsToFeetRatio]
  );

  // set shapes, converting from pixels to feet
  const setShapes = React.useCallback(
    (updatedShapes: Shape[]) => {
      setRamp({
        ...ramp,
        shapes: updatedShapes.map((shape) => ({
          ...shape,
          points: shape.points.map((point) => ({
            x: point.x / pixelsToFeetRatio,
            y: point.y / pixelsToFeetRatio,
          })),
        })),
      });
    },
    [ramp, setRamp, pixelsToFeetRatio]
  );

  const duplicateShapes = () => {
    if (activeShapes.length > 0) {
      const updatedShapes = [...shapes];
      getActiveShapes().forEach((shape) => {
        updatedShapes.unshift({
          ...shape,
          id: uuidv4(),
          points: shape.points.map((point) => ({
            x: point.x + pixelsToFeetRatio * 25,
            y: point.y + pixelsToFeetRatio * 25,
          })),
        });
      });
      setShapes(updatedShapes);
      setActiveShapes([...activeShapes, updatedShapes[0].id]);
    }
  };

  // Undo the last action
  const undo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1]; // Get the last saved state
      if (!previousState) {
        return;
      }
      setShapes(previousState); // Revert to the last state
      setHistory((prevHistory) => prevHistory.slice(0, -1)); // Remove the last state from history
    }
  };

  const [currentShape, setCurrentShape] = React.useState<Shape>(
    makeDefaultShape(ramp.fbo_id, ramp.id)
  );
  const [
    draggingPoint,
    setDraggingPoint,
  ] = React.useState<DraggingPoint | null>(null);
  const [
    draggingShape,
    setDraggingShape,
  ] = React.useState<DraggingShape | null>(null);
  const [mousePosition, setMousePosition] = React.useState<Point | null>(null);
  const [contextMenu, setContextMenu] = React.useState<ContextMenu | null>(
    null
  );
  const [textPrompt, setTextPrompt] = React.useState<{
    open: boolean;
    x?: number;
    y?: number;
  }>({
    open: false,
  });
  const [cursor, setCursor] = React.useState<string | null>(null);

  const [isShiftPressed] = useKeyPress("Shift");
  const [isCommandPressed] = useKeyPress("Meta");
  const [isOptPressed] = useKeyPress("Alt");

  useCanvasHotkeys({
    activeShapes,
    setActiveShapes,
    getActiveShapes,
    pixelsToFeetRatio,
    shapes,
    setShapes,
    undo,
    duplicateShapes,
  });

  const draw = React.useCallback(
    (context: CanvasRenderingContext2D) => {
      drawMain(context, {
        shapes,
        currentShape,
        highlightedShapes,
        mousePosition,
        canvasRef,
        pixelsToFeet,
        showMeasurements,
        pixelsToFeetRatio,
        activeShapes,
        patterns,
        isShiftPressed,
        zoomLevel,
        isMetric: activeFBO?.settings?.useMetricSystem || false,
      });
    },
    [
      shapes,
      currentShape,
      activeShapes,
      mousePosition,
      isShiftPressed,
      highlightedShapes,
      canvasRef,
      pixelsToFeet,
      showMeasurements,
      pixelsToFeetRatio,
      patterns,
    ]
  );

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      draw(context);
    }
  }, [canvasRef, draw]);

  // Add a point to the current shape or complete the shape if clicking on the first point
  const handleCanvasClick = useOnCanvasClick({
    selectedTool,
    shapes,
    setShapes,
    currentShape,
    setCurrentShape,
    setMousePosition,
    setSelectedTool,
    ramp,
    activeShapes,
    setActiveShapes,
    canvasRef,
    isCommandPressed,
    isOptPressed,
    isShiftPressed,
    setShape,
  });

  // Start dragging a point (either from existing shapes or the current shape)
  const handleMouseDown = useOnMouseDown({
    selectedTool,
    setSelectedTool,
    shapes,
    setShapes,
    currentShape,
    setDraggingPoint,
    setDraggingShape,
    draggingPoint,
    draggingShape,
    canvasRef,
    ramp,
    setHistory,
    setTextPrompt,
  });

  // Drag the point
  const handleMouseMove = useOnMouseMove({
    selectedTool,
    shapes,
    setShapes,
    currentShape,
    setDraggingPoint,
    setDraggingShape,
    draggingPoint,
    draggingShape,
    canvasRef,
    ramp,
    isShiftPressed,
    setMousePosition,
    getActiveShapes,
    setSelectedTool,
  });

  const handleContextMenu = useOnContextMenu({
    selectedTool,
    shapes,
    setContextMenu,
    canvasRef,
  });

  // Finish dragging
  const handleMouseUp = (event: React.MouseEvent) => {
    setDraggingShape(null);
    setDraggingPoint(null);
  };

  if (loadingPatterns) {
    return <Loading />;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Tooltip
          title={navigator.platform.includes("Mac") ? `⌘ + d` : `alt + d`}
        >
          <Button
            variant="contained"
            color="info"
            startIcon={<CopyAllIcon />}
            onClick={duplicateShapes}
          >
            Copy Selected
          </Button>
        </Tooltip>
        <Tooltip
          title={navigator.platform.includes("Mac") ? `⌘ + z` : `alt + z`}
        >
          <Button
            variant="contained"
            color="info"
            startIcon={<ReplayIcon />}
            onClick={undo}
          >
            Undo
          </Button>
        </Tooltip>
      </Stack>
      <Box
        ref={div}
        sx={{
          cursor,
          position: "relative",
          overflowX: "scroll",
        }}
      >
        <DraggingScrollContainer
          zoomLevel={zoomLevel}
          feetToPixels={1 / pixelsToFeetRatio}
          disabled={false}
          onClick={handleCanvasClick}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onContextMenu={handleContextMenu}
          preventDefault={(event: React.MouseEvent) => {
            if (selectedTool === "Select") {
              return clickedShape(canvasRef, event, shapes);
            }
            return true;
          }}
        >
          <MeasuringTool
            pixelsToFeetRatio={pixelsToFeetRatio}
            overlayExpansionRatio={1}
          >
            <Canvas
              ref={canvasRef as React.RefObject<HTMLCanvasElement>}
              draw={draw}
              width={canvasWidth}
              height={canvasHeight}
              onClick={handleCanvasClick}
              onMouseDown={handleMouseDown}
              onMouseMove={(evt) => {
                if (clickedShape(canvasRef, evt, shapes)) {
                  setCursor("default");
                } else {
                  setCursor(null);
                }
                handleMouseMove(evt);
              }}
              onMouseUp={handleMouseUp}
              onContextMenu={handleContextMenu} // Attach the right-click handler
              style={{
                border: "1px solid black",
                cursor: selectedTool === "Select" ? cursor : "crosshair",
                backgroundImage:
                  showReferenceImage && `url(${rampImage?.value})`,
                backgroundSize: showReferenceImage && "cover",
                margin: "20px",
              }}
            />
          </MeasuringTool>
        </DraggingScrollContainer>
      </Box>

      <ShapeEditDialog
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        onClose={() => {
          setDraggingShape(null);
          setDraggingPoint(null);
          setContextMenu(null);
        }}
        shapes={shapes}
        setShapes={setShapes}
      />
      <ZoomControl
        currentZoom={canvasWidth}
        increment={0.1}
        onClickPlus={() => {
          // Limit zoom to 250%
          if (zoomLevel < 2.5) {
            setZoomLevel(zoomLevel * 1.1);
            setCanvasWidth(canvasWidth * 1.1);
            setCurrentShape({
              ...currentShape,
              points: currentShape.points.map((point) => ({
                x: point.x * 1.1,
                y: point.y * 1.1,
              })),
            });
          }
        }}
        onClickMinus={() => {
          setZoomLevel(zoomLevel * 0.9);
          setCanvasWidth(canvasWidth * 0.9);
          setCurrentShape({
            ...currentShape,
            points: currentShape.points.map((point) => ({
              x: point.x * 0.9,
              y: point.y * 0.9,
            })),
          });
        }}
      />
      <CanvasTextInput
        open={textPrompt.open}
        setOpen={(open) => setTextPrompt({ open })}
        onDone={(text) => {
          const updatedShapes = [...shapes];
          updatedShapes.unshift({
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            points: [{ x: textPrompt.x, y: textPrompt.y }],
            locked: false,
            type: "Text",
            stroke_style: "white",
            line_style: "solid",
            line_width: 3,
            text,
            font_size: 18,
          });
          setShapes(updatedShapes);
          setSelectedTool("Select");
        }}
      />
    </Stack>
  );
};
