import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import * as React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

const theme = createTheme({
  zIndex: {
    mobileStepper: 4000 + 1000,
    fab: 4000 + 1050,
    appBar: 4000 + 1100,
    drawer: 4000 + 1249,
    modal: 4000 + 1250,
    snackbar: 4000 + 1400,
    tooltip: 4000 + 9999,
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "--DataGrid-containerBackground": "#fbfbfb", // Custom background color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 24,
          boxShadow: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    tabs: {
      textTransform: "none",
    },
    fontFamily: [
      "Lexend Deca",
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  // https://huemint.com/brand-intersection/#palette=b64a2f-6ac4ac-d7c49e-1c1a2f
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#23967F",
    },
    info: {
      main: "#d7d7d7",
    },
    success: {
      main: "#3c6ce9",
    },
    warning: {
      main: "#f5c25c",
    },
    background: {
      paper: "#ffffff",
      default: "#00000004",
    },
  },
});

export const ThemeContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </MuiThemeProvider>
  );
};
