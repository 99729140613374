import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import { PostgrestSingleResponse } from "@supabase/postgrest-js";
import { Polygon } from "geojson";
import * as React from "react";
import { useAsync } from "react-use";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useTokenMaker } from "../../../hooks";
import { Aircraft } from "../../LabelingTool";

import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export const SubmitNewAircraftForm: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
  const { url: tokenMakerURL } = useTokenMaker();
  const { activeFBO } = useActiveFBOs();
  const [success, setSuccess] = React.useState<boolean>(false);
  const [make, setMake] = React.useState<string>("");
  const [model, setModel] = React.useState<string>("");
  const [uniqueMods, setUniqueMods] = React.useState<string>("");
  const [timeframe, setTimeframe] = React.useState<string>("");
  const { airplxIdentity } = useIdentity();
  const onSubmit = React.useCallback(async () => {
    const payload = {
      fbo_name: activeFBO.name,
      user_email: airplxIdentity.email,
      make,
      model,
      uniqueMods,
      timeframe,
    };
    const payloadQuerystring = new URLSearchParams(payload).toString();
    fetch(`${tokenMakerURL}/request_aircraft?${payloadQuerystring}`);
  }, [
    activeFBO?.name,
    airplxIdentity.email,
    make,
    model,
    uniqueMods,
    timeframe,
  ]);

  React.useEffect(() => {
    setMake("");
    setModel("");
    setUniqueMods("");
    setTimeframe("");
    setSuccess(false);
  }, [open]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          {success ? "Requested Recieved" : "New Aircraft Request"}
        </DialogTitle>

        <DialogContent sx={{ p: 1 }}>
          {success ? (
            <Alert
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setOpen(false)}
                >
                  OK
                </Button>
              }
            >
              Thanks! We'll start working on this as soon as we can. We might
              email you if we have questions about the specifics of the aircraft
              you'd like to add so please watch your inbox.
            </Alert>
          ) : (
            <>
              <Stack direction="column" spacing={1} p={1}>
                <TextField
                  size="small"
                  autoFocus
                  required
                  label="Manufacturer"
                  type="text"
                  fullWidth
                  onChange={(evt) => setMake(evt.target.value)}
                  value={make}
                  sx={{ mt: 1 }}
                />
                <TextField
                  size="small"
                  required
                  label="Model"
                  type="text"
                  fullWidth
                  onChange={(evt) => setModel(evt.target.value)}
                  value={model}
                />
                <TextField
                  size="small"
                  required
                  label="Unique Modifiers (e.g. winglets, variations)"
                  type="text"
                  fullWidth
                  onChange={(evt) => setUniqueMods(evt.target.value)}
                  value={uniqueMods}
                />
                <TextField
                  size="small"
                  label="Timeframe"
                  type="text"
                  fullWidth
                  onChange={(evt) => setTimeframe(evt.target.value)}
                  value={timeframe}
                />
                <Typography variant="caption">
                  Allow 2-4 days for upload of new aircraft models. We will
                  notify once it is available.
                </Typography>
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {success ? (
            <div />
          ) : (
            <>
              <Button color="error" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                disabled={!Boolean(make) || !Boolean(model)}
                color="success"
                variant="contained"
                onClick={async () => {
                  await onSubmit();
                  setSuccess(true);
                }}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

type Props = {
  label: string;
  onSelect: (a: Aircraft) => void;
  defaultSelected?: Aircraft;
};

const filterOptions = (options, { inputValue }) => {
  return options.filter((option) => {
    const name = `${option.make} ${option.model}`;
    return (
      name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.make.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.model.toLowerCase().includes(inputValue.toLowerCase())
    );
  });
};

export const AutocompleteAircraft: React.FC<Props> = ({
  label,
  onSelect,
  defaultSelected,
  ...props
}) => {
  const { activeFBO } = useActiveFBOs();
  const [submitNewAircraftOpen, setSubmitNewAircraftOpen] = React.useState<
    boolean
  >(false);
  const [selected, setSelected] = React.useState<Aircraft>(defaultSelected);
  const { postgrest } = useApi();
  const state = useAsync(async () => {
    // TODO: do this in the db
    return postgrest
      .from("aircraft")
      .select("id,make,model,geom,linked_to")
      .order("make")
      .order("model")
      .then((r) => r.body)
      .then((rows) => rows.filter((row) => row.geom || row.linked_to));
  }, []);

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  return (
    <>
      <Autocomplete
        loading={state.loading}
        options={state.value ?? []}
        filterOptions={filterOptions}
        noOptionsText={
          <Button
            variant="contained"
            color="success"
            endIcon={<HelpOutlineIcon />}
            onClick={() => setSubmitNewAircraftOpen(true)}
          >
            No aircraft found • Click here to request a new aircraft
          </Button>
        }
        getOptionLabel={(option: Aircraft) => `${option.make} ${option.model}`}
        onChange={async (event: any, newValue: Aircraft | null) => {
          if (!newValue) {
            onSelect(null);
            setSelected(null);
            return;
          }
          const fullValue: PostgrestSingleResponse<Aircraft> = await postgrest
            .from("aircraft")
            .select()
            .eq("id", newValue.id)
            .single();
          const { data: geom_scaled_buffer } = await postgrest.rpc("buffer", {
            geom: fullValue.data.scaled_geom_full,
            radius: activeFBO.sop_horizontal_spacing ?? 2,
          });

          const { data: ramp_geom_scaled_buffer } = await postgrest.rpc(
            "buffer",
            {
              geom: fullValue.data.scaled_geom_full,
              radius: activeFBO.sop_ramp_horizontal_spacing ?? 10,
            }
          );

          const selectedAircraft = {
            ...fullValue.data,
            geom_scaled_buffer: geom_scaled_buffer as Polygon,
            ramp_geom_scaled_buffer: ramp_geom_scaled_buffer as Polygon,
          };
          onSelect(selectedAircraft);
          setSelected(selectedAircraft);
        }}
        value={selected || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "Aircraft"}
            size="small"
            InputProps={{
              ...params.InputProps,
              testid: "autocomplete_aircraft",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <SubmitNewAircraftForm
        open={submitNewAircraftOpen}
        setOpen={setSubmitNewAircraftOpen}
      />
    </>
  );
};
