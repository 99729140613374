// import { TextField, TextFieldProps } from "@mui/material";
// import * as React from "react";
// import { useEffect, useState } from "react";
// import { formatNumber } from "../../utils";

// type Unit = "meters" | "feet";

// interface Props extends Omit<TextFieldProps, "onChange" | "value" | "onBlur"> {
//   value: number; // Value stored in feet
//   deicmals?: number;
//   unit: Unit; // Display unit: meters or feet
//   onChange: (valueInFeet: number) => void; // Callback for value change in feet
//   onBlur?: (valueInFeet: number) => void; // Callback for value change in feet
// }

// export const MetricAwareTextField: React.FC<Props> = ({
//   value,
//   deicmals: decimalsProvided,
//   unit,
//   onChange,
//   onBlur,
//   ...textFieldProps
// }) => {
//   const defaultDecimals = unit === "meters" ? 2 : 1;
//   const decimals = decimalsProvided || defaultDecimals;
//   const [displayValue, setDisplayValue] = useState<string>("");

//   // Conversion factors
//   const METERS_TO_FEET = 3.28084;
//   const FEET_TO_METERS = 1 / METERS_TO_FEET;

//   // Update the display value when `value` or `unit` changes
//   useEffect(() => {
//     if (isNaN(value)) {
//       setDisplayValue("");
//       return;
//     }
//     const convertedValue =
//       unit === "meters"
//         ? formatNumber(value * FEET_TO_METERS, decimals)
//         : formatNumber(value, decimals);
//     setDisplayValue(convertedValue);
//   }, [value, unit]);

//   // Handle input changes
//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const rawValue = event.target.value;
//     setDisplayValue(rawValue); // Update the display even for incomplete input
//   };

//   const handleBlur = () => {
//     if (!isNaN(Number(displayValue))) {
//       const valueInFeet =
//         unit === "meters"
//           ? Number(displayValue) * METERS_TO_FEET
//           : Number(displayValue);
//       onChange(valueInFeet);
//       onBlur && onBlur(valueInFeet);
//     } else {
//       setDisplayValue(""); // Reset the display value if it's not a number
//     }
//   };

//   return (
//     <TextField
//       {...textFieldProps}
//       type="text"
//       value={displayValue ?? ""}
//       onChange={handleInputChange}
//       onBlur={handleBlur}
//       onKeyDown={(evt) => {
//         if (evt.code === "Enter") {
//           if (!isNaN(Number(displayValue))) {
//             const valueInFeet =
//               unit === "meters"
//                 ? Number(displayValue) * METERS_TO_FEET
//                 : Number(displayValue);
//             onChange(valueInFeet);
//           }
//         }
//       }}
//       InputProps={{
//         endAdornment: unit === "meters" ? "m" : "ft",
//         ...textFieldProps.InputProps,
//       }}
//     />
//   );
// };

import { TextField, TextFieldProps } from "@mui/material";
import { round } from "lodash";
import * as React from "react";

type Unit = "meters" | "feet";

interface Props extends Omit<TextFieldProps, "onChange" | "value" | "onBlur"> {
  value: number; // Value stored in feet
  decimals?: number;
  unit: Unit; // Display unit: meters or feet
  onChange: (valueInFeet: number) => void; // Callback for value change in feet
  onBlur?: (valueInFeet: number) => void; // Callback for value change in feet
}

export const MetricAwareTextField: React.FC<Props> = ({
  value,
  decimals: decimalsProvided,
  unit,
  onChange,
  onBlur,
  ...textFieldProps
}) => {
  const defaultDecimals = unit === "meters" ? 2 : 1;
  const decimals = decimalsProvided || defaultDecimals;

  // Conversion factors
  const METERS_TO_FEET = 3.28084;
  const FEET_TO_METERS = 1 / METERS_TO_FEET;

  // Handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if we're using the metric system, convert meters to feet
    const conversion = unit === "meters" ? METERS_TO_FEET : 1;
    onChange(parseFloat(event.target.value) * conversion);
  };

  // Step size for meters and feet
  const step = unit === "meters" ? 0.01 : 1 / 10 ** decimals;

  const displayValue = round(
    unit === "meters" ? value / METERS_TO_FEET : value,
    2
  );

  return (
    <TextField
      {...textFieldProps}
      type="number" // Set the input type to "number"
      onChange={handleInputChange}
      value={displayValue}
      InputProps={{
        endAdornment: unit === "meters" ? "m" : "ft",
        inputProps: {
          step,
          min: 0, // Optional: minimum value
        },
        ...textFieldProps.InputProps,
      }}
    />
  );
};
