import { Box } from "@mui/material";
import * as React from "react";
import { useMeasuringToolState } from "../../containers/MeasuringToolContainer";
import { useLayoutCanvasState } from "../../screens/Ramp/RampPresenter";
import { scrollSx } from "../../utils";

type Props = {
  children: React.ReactNode;
  feetToPixels: number;
  disabled: boolean;
  onClick: (evt: React.MouseEvent) => void;
  onMouseDown: (evt: React.MouseEvent) => void;
  onMouseMove: (evt: React.MouseEvent) => void;
  onMouseUp: (evt: React.MouseEvent) => void;
  onMouseLeave?: (evt: React.MouseEvent) => void;
  onContextMenu: (evt: React.MouseEvent) => void;
  preventDefault?: (evnt: React.MouseEvent) => boolean;
  zoomLevel?: number;
};

export const DraggingScrollContainer: React.FC<Props> = ({
  children,
  feetToPixels,
  disabled = false,
  onClick,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onMouseLeave,
  onContextMenu,
  preventDefault,
  zoomLevel,
}) => {
  const { active: isMeasuringToolActive } = useMeasuringToolState();
  const { setX0, setY0 } = useLayoutCanvasState();
  // Begin grab scroll state mgmt
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [dragStart, setDragStart] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [scrollStart, setScrollStart] = React.useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const containerBounds = React.useMemo(() => {
    if (!containerRef.current) {
      return {
        x: 20,
        y: 20,
      };
    }
    const rect = containerRef.current.getBoundingClientRect();
    return rect;
  }, [containerRef?.current, zoomLevel]);

  React.useEffect(() => {
    if (containerRef.current) {
      setX0(containerRef.current.scrollLeft / feetToPixels);
      setY0(containerRef.current.scrollTop / feetToPixels);
    }
  }, [containerRef.current, feetToPixels, zoomLevel]);

  const dragThreshold = 5; // Minimum movement to consider a drag
  // end grab scroll state mgmt
  // Begin grab scroll handlers
  const handleParentMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    // if there's no target, bail
    if (!event.target) {
      return;
    }

    // we need to distinguish between Select mode and other modes. If we're in Select mode, we want to
    // scroll if the click doesn't select anything.
    if (preventDefault?.(event)) {
      onMouseDown?.(event); // Call child handler
      return;
    }

    const isGrabbingContainer = (event.target as HTMLElement).getAttribute(
      "data-grabbing-container"
    );
    // check to see if they're dragging the layout background
    const isRampBackground = (event.target as HTMLElement).tagName === "CANVAS";
    const isHangarBackground =
      (event.target as HTMLElement).getAttribute("data-id") ===
      "hangar-layout-background";
    if (!isRampBackground && !isHangarBackground && !isGrabbingContainer) {
      return; // Ignore the event if it's not on the canvas
    }

    setDragStart({ x: event.clientX, y: event.clientY });
    setScrollStart({
      x: containerRef.current?.scrollLeft || 0,
      y: containerRef.current?.scrollTop || 0,
    });
    setIsDragging(false);
    onMouseDown?.(event); // Call child handler
  };

  const handleParentMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    if (preventDefault?.(event)) {
      onMouseMove(event); // Call child handler
      return;
    }

    if (!dragStart.x && !dragStart.y) {
      return;
    }

    const dx = event.clientX - dragStart.x;
    const dy = event.clientY - dragStart.y;

    if (
      !isDragging &&
      (Math.abs(dx) > dragThreshold || Math.abs(dy) > dragThreshold)
    ) {
      setIsDragging(true);
    }

    if (isDragging && containerRef.current) {
      containerRef.current.scrollLeft = scrollStart.x - dx;
      containerRef.current.scrollTop = scrollStart.y - dy;
    } else {
      onMouseMove(event); // Defer to child if not dragging
    }
  };

  const handleParentMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    if (preventDefault?.(event)) {
      onMouseUp(event); // Call child handler
      return;
    }

    if (!isDragging) {
      onClick(event); // Trigger click if not dragging
    }
    setIsDragging(false);
    setDragStart({ x: 0, y: 0 });
    setScrollStart({ x: 0, y: 0 });
    onMouseUp?.(event); // Call child handler
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setIsDragging(false);
    setDragStart({ x: 0, y: 0 });
    setScrollStart({ x: 0, y: 0 });
    onMouseLeave?.(event);
  };

  // if we're in read-only mode or for whatever reason we don't want to use the drag-scroll,
  // then we're just displaying so we don't need the drag scroll
  if (disabled) {
    return <>{children}</>;
  }

  // if the measuring tool is active, then we don't need the drag scroll
  if (isMeasuringToolActive) {
    return (
      <Box
        data-grabbing-container
        ref={containerRef}
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: `calc(100vw - ${containerBounds.x}px)`,
          maxHeight: `calc(100vh - ${containerBounds.y}px)`,
          overflow: disabled ? "inherit" : "scroll",
          cursor: isDragging ? "grabbing" : "grab",
          position: "relative",
          ...scrollSx,
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      data-grabbing-container
      ref={containerRef}
      onScroll={() => {
        setX0(containerRef.current.scrollLeft / feetToPixels);
        setY0(containerRef.current.scrollTop / feetToPixels);
      }}
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: `calc(100vw - ${containerBounds.x}px)`,
        maxHeight: `calc(100vh - ${containerBounds.y}px)`,
        overflow: disabled ? "inherit" : "scroll",
        cursor: isDragging ? "grabbing" : "grab",
        position: "relative",
        ...scrollSx,
      }}
      onMouseDown={handleParentMouseDown}
      onMouseMove={handleParentMouseMove}
      onMouseUp={handleParentMouseUp}
      onMouseLeave={handleMouseLeave}
      onContextMenu={onContextMenu}
    >
      {children}
    </Box>
  );
};
