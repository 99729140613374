import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Slider, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { FBO } from "../../../types";
import { feetToMeters, metersToFeet } from "../../../utils/math";

type Props = {
  fbo: FBO;
  setFBO: (fbo: FBO) => void;
};

export const NoseInThreshholds: React.FC<Props> = ({ fbo, setFBO }) => {
  const { activeFBO } = useActiveFBOs();
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeFBO?.settings?.useMetricSystem) {
      newValue = newValue.map((v) => metersToFeet(v));
    }
    setFBO({
      ...fbo,
      sop_orientation_tail_in_threshold: newValue[0],
      sop_orientation_nose_in_threshold: newValue[1],
    });
  };

  const value = [
    fbo.sop_orientation_tail_in_threshold,
    fbo.sop_orientation_nose_in_threshold,
  ].map((v) => {
    if (activeFBO?.settings?.useMetricSystem) {
      return feetToMeters(v);
    }
    return v;
  });

  const maxValue = activeFBO?.settings?.useMetricSystem ? feetToMeters(50) : 50;

  const widths = [
    (100 * value[0]) / maxValue,
    (100 * (value[1] - value[0])) / maxValue,
    (100 * (maxValue - value[1])) / maxValue,
  ].map((w) => `${w}%`);

  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";

  return (
    <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography>Nose-In/Tail-In Settings</Typography>
        <Tooltip
          title={
            <Typography variant="inherit">
              {`AirPlx uses the lower height measurement of the horizontal stabilizer to determine the default orientation when a new aircraft is added to a hangar. Use the slider below to adjust the horizontal stabilizer height at which AirPlx will default to Tail-in, No-Preset, or Nose-In. For example: `}
              <br />
              <br />
              • If you prefer to tail-in all aircraft, move both sliders all the
              way to the right. <br />
              • If you want all aircraft below 20{unit} to tail-in and all other
              to nose-in, move both sliders to 20{unit}. <br />
              <br />
              {`Note: You can adjust individual aircraft orientation inside each hangar as desired.`}
            </Typography>
          }
        >
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Stack>
      <br />
      <br />
      <Stack direction="row" width="100%" alignItems="flex-start" spacing={2}>
        <Stack direction="column" width="100%">
          <Slider
            value={value}
            onChange={handleChange}
            disableSwap
            min={0}
            max={maxValue}
            marks
            valueLabelFormat={(value) => `${value.toFixed(1)} ${unit}`}
            valueLabelDisplay="on"
          />
          <Stack direction="row">
            <Box sx={{ width: widths[0] }}>
              <Typography variant="body2" textAlign="center">
                Tail In
              </Typography>
            </Box>
            <Box sx={{ width: widths[1] }}>
              <Typography variant="body2" textAlign="center">
                No Preset
              </Typography>
            </Box>
            <Box sx={{ width: widths[2] }}>
              <Typography variant="body2" textAlign="center">
                Nose In
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          onClick={() => {
            setFBO({
              ...fbo,
              sop_orientation_tail_in_threshold: 15,
              sop_orientation_nose_in_threshold: 20,
            });
          }}
          sx={{ width: 200 }}
        >
          Reset to Default
        </Button>
      </Stack>
    </Stack>
  );
};
