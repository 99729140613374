import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useIdentity } from "../../containers/IdentityContainer";
import { Loading } from "../../widgets/Loading";
import { MagicLinkLoginButton } from "../Login";

export const MagicLinkLogin: React.FC<{}> = () => {
  const { airplxIdentity, loginWithMagicLink } = useIdentity();
  const navigate = useNavigate();
  const { code } = useParams();

  const { loading } = useAsync(async () => {
    // convert code to token and login
    if (!code) {
      return;
    }
    await loginWithMagicLink(code);
  }, [code]);

  React.useEffect(() => {
    if (airplxIdentity?.email) {
      navigate("/");
    }
  }, [airplxIdentity]);

  if (loading) {
    return <Loading />;
  }

  if (airplxIdentity?.email) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="80vh"
        width="100%"
      >
        <Typography variant="h4">
          Welcome to AirPlx <u>{airplxIdentity?.email}</u>!
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="80vh"
      width="100%"
    >
      <Stack direction="column" spacing={1} width={500} alignItems="center">
        <Typography variant="h4">We weren't able to log you in.</Typography>
        <Typography variant="body1" color="textSecondary">
          Click the button below to try again. If this issue persists, please
          contact support@airplx.com.
        </Typography>
        <MagicLinkLoginButton />
      </Stack>
    </Stack>
  );
};
