import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ButtonGroup,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import * as React from "react";

type Props = {
  currentZoom: number;
  increment: number;
  onClickPlus: (newZoomLevel: number) => void;
  onClickMinus: (newZoomLevel: number) => void;
};

export const ZoomControl: React.FC<Props> = ({
  increment = 0.1,
  onClickPlus,
  onClickMinus,
}) => {
  const theme = useTheme();
  const [zoomChange, setZoomChange] = React.useState<number>(0);
  const [showZoomLevel, setShowZoomLevel] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Show the zoom level for 2 seconds
    setShowZoomLevel(true);
    const timer = setTimeout(() => {
      setShowZoomLevel(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [zoomChange]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      sx={{
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.mobileStepper - 1,
      }}
    >
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        sx={{ backgroundColor: "white" }}
      >
        <Tooltip title="Zoom In" placement="left">
          <IconButton
            onClick={() => {
              const newZoomLevel = 1 + (zoomChange + 1) * increment;
              onClickPlus(newZoomLevel);
              setZoomChange(zoomChange + 1);
            }}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Zoom Out" placement="left">
          <IconButton
            onClick={() => {
              const newZoomLevel = 1 + (zoomChange - 1) * increment;
              if (newZoomLevel <= 0) {
                return;
              }
              onClickMinus(newZoomLevel);
              setZoomChange(zoomChange - 1);
            }}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Stack>
  );
};
