import { Box, Stack } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useIdentity } from "../../containers/IdentityContainer";
import { scrollSx } from "../../utils";
import { SideDrawer } from "./SideDrawer";

interface NavProps {
  children: React.ReactNode;
}

export const Nav: React.FC<NavProps> = ({ children, ...props }) => {
  const { isLoggedIn } = useIdentity();

  const location = useLocation();
  const isMultiHangar = location.pathname.includes("/multi-hangar/");

  return (
    <Stack
      direction="row"
      display="flex"
      height="100%"
      sx={{ overflow: "hidden" }}
    >
      <Box sx={{ height: "100%", maxWidth: "100%" }}>
        {isLoggedIn ? <SideDrawer /> : null}
      </Box>
      <Box
        component="main"
        sx={{
          height: "100%",
          width: "100%",
          overflow: isMultiHangar ? "scroll" : "inherit",
          ...(isMultiHangar ? scrollSx : {}),
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};
