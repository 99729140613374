import { PostgrestClient } from "@supabase/postgrest-js";
import * as React from "react";
import { getPostgrestUrl, getTokenMakerUrl } from "../utils";

export const usePostgrest = () => {
  const [token, setToken] = React.useState<string>("");
  const postgrest = new PostgrestClient(getPostgrestUrl());
  if (token) {
    postgrest.auth(token);
  }
  return postgrest;
};

export const useTokenMaker = () => {
  const url = getTokenMakerUrl();

  const getIdentityToken = async (code: string) => {
    const result = await fetch(`${url}/identity/${code}`).then((res) =>
      res.json()
    );
    return result.token;
  };

  const verifyJWT = async (
    jwt: string,
    noValidate: number = 0
  ): Promise<string> => {
    const verifyURL = noValidate
      ? `${url}/?token=${jwt}&noValidate=${noValidate}`
      : `${url}/?token=${jwt}`;
    const result = await fetch(verifyURL).then((res) => res.json());
    return result.token;
  };

  const exchangeToken = async (jwt: string): Promise<string> => {
    const result = await fetch(`${url}?token=${jwt}&noValidate=1`).then((res) =>
      res.json()
    );
    return result.token;
  };

  return {
    url,
    verifyJWT,
    getIdentityToken,
    exchangeToken,
  };
};
