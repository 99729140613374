import * as React from "react";
import { Preferences } from "../../../hooks/useUserPreferences";
import { Hangar, Location, Position, Ramp, Stack } from "../../../types";
import { FullyLoadedDraggableObstacle } from "../FullyLoadedDraggableObstacle";
import { RightClick } from "../Layout";
import { useOnPositionChange } from "./useOnPositionChange";

export const isPositionValid = (position: Position): boolean => {
  return position.x !== null && position.y !== null && position.angle !== null;
};

type Props = {
  location: Location<Hangar | Ramp>;
  stack: Stack;
  setStack: (stack: Stack) => void;
  height: number;
  readOnly: boolean;
  feetToPixels: number;
  rightClick: RightClick;
  setRightClick: (rightClick: RightClick) => void;
  preferences: Preferences;
  spacingBufferOverlayElement: React.ReactNode;
};

export const useMovableObstacles = (props: Props, deps: any[] = []) => {
  const {
    location,
    stack,
    setStack,
    height,
    readOnly,
    feetToPixels,
    rightClick,
    setRightClick,
    preferences,
    spacingBufferOverlayElement,
  } = props;

  const onPositionChange = useOnPositionChange({ location, stack, setStack }, [
    stack.tenants,
    stack.movableObstacles,
    setStack,
    location,
  ]);

  return React.useMemo(
    () => (
      <>
        {stack?.movableObstacles.map((movableObstacle) => {
          return (
            <React.Fragment
              key={`draggable-movableobstacle-${stack.id}-${movableObstacle.entity_id}`}
            >
              <FullyLoadedDraggableObstacle
                readOnly={readOnly}
                layoutHeight={height}
                feetToPixels={feetToPixels}
                movableObstacle={{
                  ...movableObstacle,
                  selected:
                    movableObstacle.selected ||
                    rightClick?.entity_id === movableObstacle.entity_id,
                }}
                stack={stack}
                setStack={setStack}
                preferences={preferences}
                onPositionChange={onPositionChange}
                rightClick={rightClick}
                setRightClick={setRightClick}
                spacingBufferOverlayElement={spacingBufferOverlayElement}
              />
            </React.Fragment>
          );
        })}
      </>
    ),
    deps
  );
};
