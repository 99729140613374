import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Box,
  Divider,
  Grow,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import create from "zustand";
import { persist } from "zustand/middleware";
import { Hangar, Ramp } from "../../types";

interface LayoutState {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
}

export const useLayout = create(
  persist<LayoutState>(
    (set) => ({
      isDrawerOpen: true,
      setIsDrawerOpen: (isOpen) => set({ isDrawerOpen: isOpen }),
    }),
    {
      name: "layout-storage", // name of the item in storage
    }
  )
);

type Props = {
  location: Ramp | Hangar;
  children: React.ReactNode;
  onClickBack?: () => void;
  hideTitle?: boolean;
};

export const CollapsibleRampActionCard: React.FC<Props> = ({
  location,
  children,
  onClickBack,
  hideTitle = false,
}) => {
  const { isDrawerOpen, setIsDrawerOpen } = useLayout();
  const navigate = useNavigate();
  const drawerWidth = 400;
  const miniDrawerWidth = 125;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        width={isDrawerOpen ? drawerWidth : miniDrawerWidth}
        sx={{
          overflow: "hidden",
          transition: "width 0.3s",
          borderRightColor: "lightgrey",
          borderRightWidth: 1,
          borderRightStyle: "solid",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                onClick={() => {
                  if (onClickBack) {
                    onClickBack();
                    return;
                  }
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              {isDrawerOpen && !hideTitle && (
                <Grow in={isDrawerOpen}>
                  <Typography>
                    {location.stack.isReference && `[All Tie Down Tenants] `}
                    {location.name}
                  </Typography>
                </Grow>
              )}
            </Stack>
          </Stack>
          <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            {isDrawerOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </IconButton>
        </Stack>
        <Divider />
        <Box display="flex" flex={1} overflow="hidden">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
