import AddRoadIcon from "@mui/icons-material/AddRoad";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useApi } from "../../containers/ApiContainer";
import { FBOSettings } from "../../types";
import { SideIconButton } from "../Nav/SideDrawer";

export const AddFBO: React.FC<{}> = () => {
  const { postgrest } = useApi();
  const [open, setOpen] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [inviteURL, setInviteURL] = React.useState<string>("");

  React.useEffect(() => {
    setName("");
    setEmail("");
    setInviteURL("");
  }, [open]);

  const onClickAdd = async () => {
    if (!name || !email) {
      return;
    }
    const { data: newFbo } = await postgrest
      .from("fbo")
      .insert({
        name,
        // Default FBO Settings
        settings: {
          viewOnlyForManager: false,
          viewOnlyForOperator: false,
          useFavoritesForManager: true,
          modifyTenantsForManager: true,
          useFavoritesForOperator: true,
          addTeamMembersForManager: true,
          modifyTenantsForOperator: false,
          addTeamMembersForOperator: false,
          ignoreDeadSpaceForUtilization: false,
          displayEstimatedValueForManager: true,
          displayEstimatedValueForOperator: false,
          forceDisplayNotes: false,
          defaultBuffer: 0,
          defaultCAM: 0,
          defaultCAMPerSqft: 0,
          hangarDisplayOrder: [],
          rampDisplayOrder: [],
          useMetricSystem: false,
        } as FBOSettings,
      })
      .single();

    const { data: inviteId } = await postgrest.rpc("make_invite", {
      fbo_id: newFbo.id,
      email,
      default_role: "manager",
    });
    setInviteURL(`${window.location.origin}/#/claim-invite/${inviteId}`);
  };

  return (
    <>
      <SideIconButton
        onClickRoute={() => setOpen(!open)}
        label={"Add FBO"}
        icon={<AddRoadIcon />}
      />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Add a New FBO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Boolean(inviteURL) ? (
              <>
                An email has been sent to{" "}
                <b style={{ color: "#3c6ce9" }}>{email}</b> with an invite to{" "}
                <b style={{ color: "#3c6ce9" }}>{name}</b>. You can also use the
                link below.
              </>
            ) : (
              "Create a new FBO and invite the first user."
            )}
          </DialogContentText>
          {Boolean(inviteURL) ? (
            <Typography variant="subtitle1">{inviteURL}</Typography>
          ) : (
            <Stack direction="column" spacing={1} sx={{ p: 1 }}>
              <TextField
                size="small"
                autoFocus
                required
                label="Name"
                type="text"
                fullWidth
                onChange={(evt) => setName(evt.target.value)}
                value={name}
              />
              <TextField
                size="small"
                required
                label="Email Address"
                type="email"
                fullWidth
                onChange={(evt) => setEmail(evt.target.value)}
                value={email}
              />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {Boolean(inviteURL) ? (
            <Button onClick={() => setOpen(false)}>OK</Button>
          ) : (
            <>
              <Button color="error" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button color="success" variant="contained" onClick={onClickAdd}>
                Add
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
