import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { removeNonNumericAndParse } from "../../hooks/utils";
import { FBO } from "../../types";
import { formatCurrency } from "../../utils";
import { AircraftSpecsTooltip } from "../../widgets/CurrentAircraft/AircraftSpecsTooltip";
import { MetricAwareTextField } from "../../widgets/MetricAwareTextField/MetricAwareTextField";
import { SexyText } from "../../widgets/SexyText/SexyText";
import { Aircraft } from "../LabelingTool";
import { AutocompleteAircraft } from "../Settings/HangarSettings/AutocompleteAircraft";
import { AnnualTenantTable } from "../Settings/RevenueEstimator/AnnualTenantTable";
import { Rate } from "../Settings/RevenueEstimator/RatesForm";

export const FormLabel: React.FC<{
  title: string;
  subtitle: string;
}> = ({ title, subtitle }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Tooltip title={subtitle}>
      <InfoIcon fontSize="small" sx={{ color: "primary.main" }} />
    </Tooltip>
    <Typography variant="subtitle2">{title}</Typography>
  </Stack>
);

type Props = {
  aircraft: Aircraft;
  buffer: number;
  setBuffer: (buffer: number) => void;
  setAircaft: (aircraft: Aircraft) => void;
  aircraftSqft: string;
  aircraftSqftWithBuffer: string;
  baseMonthlyTenantDisplayValue: string;
  monthlyTenantValue: number;
  baseMonthlyTenantValue: number;
  monthlyTenantValueWithCAM: number;
  monthlyTenantDisplayValue: string;
  monthlyTenantDisplayValueWithCAM: string;
  baseDailyTenantDisplayValue: string;
  dailyTenantDisplayValue: string;
  dailyCamFees: number;
  monthlyCamFees: number;
  activeFBO: FBO;
  setFBO: (fbo: FBO) => void;
  nightlyRate: number;
  cam: number;
  setCam: (cam: number) => void;
  camPerSqft: number;
  setCamPerSqft: (cam: number) => void;
  dailyRate: number;
  setDailyRate: (rate: number) => void;
  monthlyRate: number;
  setMonthlyRate: (rate: number) => void;
  availableRates: Rate[];
};

export const FeeCalculator2Presenter: React.FC<Props> = ({
  aircraft,
  buffer,
  setBuffer,
  setAircaft,
  aircraftSqft,
  aircraftSqftWithBuffer,
  baseMonthlyTenantDisplayValue,
  monthlyTenantValue,
  baseMonthlyTenantValue,
  monthlyTenantValueWithCAM,
  monthlyTenantDisplayValue,
  monthlyTenantDisplayValueWithCAM,
  baseDailyTenantDisplayValue,
  dailyTenantDisplayValue,
  dailyCamFees,
  monthlyCamFees,
  activeFBO,
  setFBO,
  monthlyRate,
  nightlyRate,
  cam,
  camPerSqft,
  setCam,
  setCamPerSqft,
  dailyRate,
  setDailyRate,
  setMonthlyRate,
  availableRates,
}) => {
  const [activeTab, setActiveTab] = React.useState<string>(
    "Tenant & Transient Fees"
  );
  const [selectedRate, setSelectedRate] = React.useState<string>("Default");
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";

  // if the user has picked a selectedRate and then modified one of those rates,
  // we want to reset the selectedRate to Default
  React.useEffect(() => {
    const activeRate = availableRates.find(
      (rate) => rate.label === selectedRate
    );
    const wereRatesModified =
      activeRate &&
      (activeRate.monthly_rate !== monthlyRate ||
        activeRate.nightly_rate !== nightlyRate);
    if (activeRate?.label !== "Default" && wereRatesModified) {
      setSelectedRate("Default");
    }
  }, [monthlyRate, nightlyRate]);

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      maxWidth="100%"
    >
      <Stack direction="column" spacing={1} p={2}>
        <Typography>Inputs</Typography>
        <Divider />
        <Box width={600}>
          <Stack direction="column" spacing={1}>
            <Stack direction="column" spacing={1}>
              <FormLabel
                title="Aircraft"
                subtitle="Aircraft you would like to calculate fees for. We'll use the wingspan and length to calculate the area to use in calculations."
              />
              <Stack
                direction="row"
                spacing={1}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box width={375}>
                  <AutocompleteAircraft
                    label={"Make/Model"}
                    onSelect={setAircaft}
                  />
                </Box>
                <Stack direction="column" alignItems="center">
                  <Typography variant="subtitle2">Dimensions</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body1">{aircraftSqft}</Typography>
                    {aircraft ? (
                      <AircraftSpecsTooltip aircraft={aircraft} />
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
              <FormLabel
                title="Buffer"
                subtitle={`A buffer is added to each end of the dimensions. Eg: a 2${unit} buffer adds a total of 4${unit} to the length and 4${unit} to the wingspan.`}
              />
              <MetricAwareTextField
                type="number"
                unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
                inputProps={{
                  min: 0.0,
                  max: 5,
                  step: activeFBO?.settings?.useMetricSystem ? 0.1 : 0.5,
                  inputMode: "decimal",
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                }}
                onChange={(value) => setBuffer(Number(value))}
                value={buffer}
                sx={{ width: 125 }}
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Stack direction="column" spacing={1}>
                  <FormLabel
                    title="Base Tenant Rate"
                    subtitle="Rate to use for calculating monthly tenant pricing."
                  />
                  <MetricAwareTextField
                    variant="outlined"
                    unit={
                      activeFBO?.settings?.useMetricSystem ? "meters" : "feet"
                    }
                    type="number"
                    size="small"
                    decimals={2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          / {unit}² / month
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      step: 0.01,
                      min: 0.01,
                      max: 100,
                      inputMode: "decimal",
                    }}
                    sx={{ width: 200 }}
                    onChange={(value) => setMonthlyRate(round(value, 2))}
                    value={monthlyRate}
                  />
                </Stack>
                <Stack direction="column" spacing={1}>
                  <FormLabel
                    title="Transient Rate"
                    subtitle="Rate to use for calculating nightly transient pricing."
                  />
                  <MetricAwareTextField
                    variant="outlined"
                    unit={
                      activeFBO?.settings?.useMetricSystem ? "meters" : "feet"
                    }
                    decimals={2}
                    type="number"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          / {unit}² / day
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      step: 0.01,
                      min: 0.01,
                      max: 100,
                      inputMode: "decimal",
                    }}
                    sx={{ width: 200 }}
                    onChange={(value) => setDailyRate(round(value, 2))}
                    value={dailyRate}
                  />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <FormLabel
                    title="Presets"
                    subtitle="You can add/edit preset rates in Settings > Estimation."
                  />
                  <TextField
                    select
                    label="Rates"
                    size="small"
                    sx={{ width: 225 }}
                    onChange={(evt) => {
                      const label = evt.target.value;
                      const rate = availableRates.find(
                        (rate) => rate.label === label
                      );
                      setMonthlyRate(rate.monthly_rate);
                      setDailyRate(rate.nightly_rate);
                      setSelectedRate(label);
                    }}
                    value={selectedRate}
                  >
                    {availableRates.map((rate) => (
                      <MenuItem key={rate.id} value={rate.label}>
                        {rate.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>
              <FormLabel
                title="Common Area Maitenance Fees"
                subtitle={`Common Area Maintenance Fees added to monthly tenant rate. You can specify a percentage fee or a ${unit}² fee.`}
              />
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  type="number"
                  inputProps={{
                    min: 0.0,
                    max: 100,
                    step: 1,
                    inputMode: "numeric",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  size="small"
                  onChange={(evt) => {
                    // Parse the value without the percentage sign
                    const value = parseFloat(evt.target.value);
                    setCam(value || 0);
                    setCamPerSqft(0);
                  }}
                  value={cam}
                  sx={{ width: 120 }}
                />
                <Typography>OR</Typography>
                <MetricAwareTextField
                  unit={
                    activeFBO?.settings?.useMetricSystem ? "meters" : "feet"
                  }
                  decimals={2}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">/ {unit}²</InputAdornment>
                    ),
                  }}
                  size="small"
                  onChange={(value) => {
                    setCamPerSqft(value || 0);
                    setCam(0);
                  }}
                  value={camPerSqft}
                  sx={{ width: 140 }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Divider orientation="vertical" flexItem />
      <Box width={600}>
        <Stack direction="column" spacing={1} p={2}>
          <Typography>Calculations</Typography>
          <Divider />
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
          >
            <Tab
              value="Tenant & Transient Fees"
              label="Tenant & Transient Fees"
            />
            <Tab value="Annual Fees" label="Annual Fees" />
          </Tabs>
          {activeTab === "Tenant & Transient Fees" && (
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormLabel
                        title={`Aircraft Square ${
                          unit === "m" ? "Meters" : "Feet"
                        }`}
                        subtitle="Area taken up by the aircraft. Mesaured as wingspan x length."
                      />
                    </TableCell>
                    <TableCell>
                      <FormLabel
                        title={`Total Square ${
                          unit === "m" ? "Meters" : "Feet"
                        }`}
                        subtitle="Area taken up by the aircraft aircraft + buffer (if provided)."
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{aircraftSqft}</TableCell>
                    <TableCell>
                      <SexyText
                        bidirectional
                        text={aircraftSqftWithBuffer}
                        value={removeNonNumericAndParse(aircraftSqftWithBuffer)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Aircraft Fee</TableCell>
                    <TableCell>CAM Fees</TableCell>
                    <TableCell>
                      <Typography
                        variant="inherit"
                        align="center"
                        sx={{
                          borderBottomColor: "success.main",
                          borderBottomWidth: 2,
                          borderBottomStyle: "solid",
                        }}
                      >
                        Total Fee
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Tenant (monthly)</TableCell>
                    <TableCell>{monthlyTenantDisplayValue}</TableCell>
                    <TableCell>
                      {monthlyCamFees
                        ? formatCurrency(monthlyCamFees, 2)
                        : "---"}
                    </TableCell>
                    <TableCell>
                      <SexyText
                        bidirectional
                        value={removeNonNumericAndParse(
                          monthlyTenantDisplayValueWithCAM
                        )}
                        text={monthlyTenantDisplayValueWithCAM}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transient (daily)</TableCell>
                    <TableCell>{dailyTenantDisplayValue}</TableCell>
                    <TableCell>{"---"}</TableCell>
                    <TableCell>
                      <SexyText
                        bidirectional
                        value={removeNonNumericAndParse(
                          dailyTenantDisplayValue
                        )}
                        text={dailyTenantDisplayValue}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}
          {activeTab === "Annual Fees" && (
            <AnnualTenantTable
              aircraftFee={monthlyTenantValue}
              camFees={monthlyCamFees}
              totalFee={monthlyTenantValueWithCAM}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
