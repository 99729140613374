import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

export const EnterTheDojo: React.FC = () => {
  return (
    <Stack
      direction="column"
      spacing={2}
      p={4}
      justifyContent="center"
      alignItems="center"
      width={400}
    >
      <Typography variant="h5">Enter the dojo 🥷</Typography>
      <Button
        component={Link}
        to="/labeling-tool"
        size="large"
        color="success"
        variant="contained"
      >
        Let's Start Labeling 🚀
      </Button>
    </Stack>
  );
};
