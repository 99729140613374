import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useRates } from "../../../hooks/useRates";
import { uuidv4 } from "../../../utils";
import { MetricAwareTextField } from "../../../widgets/MetricAwareTextField/MetricAwareTextField";
import { FormLabel } from "../../Estimator/FeeCalculator2Presenter";

type RateInputProps = {
  hideTitles?: boolean;
  label: string;
  setLabel: (label: string) => void;
  monthlyRate: number;
  setMonthlyRate: (monthlyRate: number) => void;
  nightlyRate: number;
  setNightlyRate: (nightlyRate: number) => void;
};

export const RateInput: React.FC<RateInputProps> = ({
  hideTitles = false,
  label,
  setLabel,
  monthlyRate,
  setMonthlyRate,
  nightlyRate,
  setNightlyRate,
}) => {
  const isLabelDisabled = label === "Default";
  return (
    <Stack direction="row" spacing={2} alignItems="flex-end">
      <Stack direction="column" spacing={1}>
        <TextField
          label="Label"
          variant="outlined"
          size="small"
          sx={{ width: 200 }}
          disabled={isLabelDisabled}
          onChange={(evt) => setLabel(evt.target.value)}
          value={label}
        />
      </Stack>
      <Stack direction="column" spacing={1}>
        {!hideTitles && <Typography>Base Tenant Rate</Typography>}
        <TextField
          label="$ / sqft / month"
          variant="outlined"
          type="number"
          size="small"
          inputProps={{
            step: 0.01,
            min: 0.01,
            max: 100,
            inputMode: "decimal",
          }}
          sx={{ width: 200 }}
          onChange={(evt) => {
            const newRate = Number(evt.target.value);
            if (isNaN(newRate)) {
              return;
            }
            setMonthlyRate(newRate);
          }}
          defaultValue={monthlyRate}
        />
      </Stack>
      <Stack direction="column" spacing={1}>
        {!hideTitles && <Typography>Transient Rate</Typography>}
        <TextField
          label="$ / sqft / day"
          variant="outlined"
          type="number"
          size="small"
          inputProps={{
            step: 0.01,
            min: 0.01,
            max: 100,
            inputMode: "decimal",
          }}
          sx={{ width: 200 }}
          onChange={(evt) => {
            const newRate = round(Number(evt.target.value), 2);
            if (isNaN(newRate)) {
              return;
            }
            setNightlyRate(newRate);
          }}
          defaultValue={nightlyRate}
        />
      </Stack>
    </Stack>
  );
};

export type Rate = {
  id: string;
  fbo_id: string;
  label: string;
  monthly_rate: number;
  nightly_rate: number;
  created_at: string;
};

type Props = {};

export const RatesForm: React.FC<Props> = ({}) => {
  const { activeFBO, setFBO } = useActiveFBOs();
  const { rates, setRates } = useRates();
  const [buffer, setBuffer] = React.useState<number>(0);
  const [cam, setCam] = React.useState<number>(0);
  const [camPerSqft, setCamPerSqft] = React.useState<number>(0);
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";

  return (
    <Stack direction="column" spacing={2}>
      <RateInput
        label="Default"
        setLabel={() => {}}
        monthlyRate={round(activeFBO.nightly_base_rate * 30, 2)}
        setMonthlyRate={(nightly_base_rate) =>
          setFBO({ ...activeFBO, nightly_base_rate: nightly_base_rate / 30 })
        }
        nightlyRate={round(activeFBO.nightly_transient_rate, 2)}
        setNightlyRate={(nightly_transient_rate) =>
          setFBO({ ...activeFBO, nightly_transient_rate })
        }
      />
      {rates.map((rate, index) => (
        <Stack
          key={`rate-${rate.id}`}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <RateInput
            hideTitles
            key={index}
            label={rate.label}
            setLabel={(label) => {
              const newRates = [...rates];
              newRates[index].label = label;
              setRates(newRates);
            }}
            monthlyRate={rate.monthly_rate}
            setMonthlyRate={(monthly_rate) => {
              const newRates = [...rates];
              newRates[index].monthly_rate = monthly_rate;
              setRates(newRates);
            }}
            nightlyRate={rate.nightly_rate}
            setNightlyRate={(nightly_rate) => {
              const newRates = [...rates];
              newRates[index].nightly_rate = nightly_rate;
              setRates(newRates);
            }}
          />
          <IconButton
            onClick={() => {
              const newRates = [...rates];
              newRates.splice(index, 1);
              setRates(newRates);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setRates([
              ...rates,
              {
                id: uuidv4(),
                fbo_id: activeFBO.id,
                label: "New Rate",
                monthly_rate: 0,
                nightly_rate: 0,
                created_at: new Date().toISOString(),
              },
            ]);
          }}
        >
          Add Rate
        </Button>
      </Stack>
      <Stack direction="column" spacing={1}>
        <FormLabel
          title="Default Buffer"
          subtitle={`A buffer is added to each end of the dimensions. Eg: a 2${unit} buffer adds a total of 4${unit} to the length and 4${unit} to the wingspan.`}
        />
        <MetricAwareTextField
          label="Buffer"
          variant="outlined"
          size="small"
          sx={{ width: 200 }}
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          onChange={(value) => {
            setFBO({
              ...activeFBO,
              settings: {
                ...activeFBO.settings,
                defaultBuffer: value,
              },
            });
          }}
          value={activeFBO?.settings?.defaultBuffer}
        />
      </Stack>
      <Stack direction="column" spacing={1}>
        <FormLabel
          title="Default Common Area Maitenance Fees"
          subtitle={`Common Area Maintenance Fees added to monthly tenant rate. You can specify a percentage fee or a ${unit}² fee.`}
        />
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            type="number"
            inputProps={{
              min: 0.0,
              max: 100,
              step: 1,
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            size="small"
            onChange={(evt) => {
              // Parse the value without the percentage sign
              const value = parseFloat(evt.target.value);
              if (isNaN(value)) {
                return;
              }
              setFBO({
                ...activeFBO,
                settings: {
                  ...activeFBO.settings,
                  defaultCAM: value,
                },
              });
            }}
            value={activeFBO?.settings?.defaultCAM}
            sx={{ width: 120 }}
          />
          <Typography>OR</Typography>
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            decimals={2}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">/ {unit}²</InputAdornment>
              ),
            }}
            size="small"
            onChange={(value) => {
              if (isNaN(value)) {
                return;
              }
              setFBO({
                ...activeFBO,
                settings: {
                  ...activeFBO.settings,
                  defaultCAMPerSqft: value,
                },
              });
            }}
            value={activeFBO?.settings?.defaultCAMPerSqft}
            sx={{ width: 140 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
