import { round } from "lodash";
import { GarageDoor, Hangar, Obstacle, ObstacleType } from "../../../types";
import { feetToMeters } from "../../../utils/math";

export const isAlmostEqual = (num1: number, num2: number) => {
  return Math.abs(num1 - num2) < 0.25;
};

export const drawDoors = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  feetToPixels: (ft: number) => number
) => {
  ctx.beginPath();
  ctx.save();
  if (hangar.left_door) {
    ctx.setLineDash([]);
    ctx.lineWidth = 0;
    ctx.strokeStyle = "black";
    ctx.fillStyle = "black";
    ctx.moveTo(0, ctx.canvas.height);
    ctx.lineTo(feetToPixels(hangar.left_door), ctx.canvas.height);
    ctx.lineTo(feetToPixels(hangar.left_door), ctx.canvas.height - 20);
    ctx.lineTo(0, ctx.canvas.height - 20);
    ctx.fill();
  }
  if (hangar.right_door) {
    ctx.setLineDash([]);
    ctx.strokeStyle = "black";
    ctx.fillStyle = "black";
    const coords = [
      [ctx.canvas.width - feetToPixels(hangar.right_door), ctx.canvas.height],
      [ctx.canvas.width, ctx.canvas.height],
      [ctx.canvas.width, ctx.canvas.height - 20],
      [
        ctx.canvas.width - feetToPixels(hangar.right_door),
        ctx.canvas.height - 20,
      ],
    ];
    ctx.moveTo(
      ctx.canvas.width - feetToPixels(hangar.right_door),
      ctx.canvas.height
    );
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height);
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height - 20);
    ctx.lineTo(
      ctx.canvas.width - feetToPixels(hangar.right_door),
      ctx.canvas.height - 20
    );
    ctx.fill();
  }
  ctx.restore();
};

export const drawGarageDoors = (
  ctx: CanvasRenderingContext2D,
  garageDoors: GarageDoor[],
  feetToPixels: (ft: number) => number
) => {
  ctx.beginPath();
  ctx.save();
  for (const garageDoor of garageDoors) {
    ctx.setLineDash([]);
    ctx.lineWidth = 8;
    ctx.strokeStyle = "#FFAC1C";
    ctx.fillStyle = "#FFAC1C";
    if (garageDoor.wall === "back") {
      ctx.moveTo(
        feetToPixels(garageDoor.x),
        ctx.canvas.height - feetToPixels(garageDoor.y)
      );
      ctx.lineTo(
        feetToPixels(garageDoor.x + garageDoor.width),
        ctx.canvas.height - feetToPixels(garageDoor.y)
      );
    } else {
      ctx.moveTo(
        feetToPixels(garageDoor.x),
        ctx.canvas.height - feetToPixels(garageDoor.y)
      );
      ctx.lineTo(
        feetToPixels(garageDoor.x),
        ctx.canvas.height - feetToPixels(garageDoor.y + garageDoor.width)
      );
    }
    ctx.stroke();
    ctx.fill();
  }
  ctx.restore();
};

export const drawUnobstructiveObstacles = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  feetToPixels: (ft: number) => number,
  options?: {
    lineWidth?: number;
    lineDash?: number[];
  }
) => {
  ctx.beginPath();
  ctx.save();

  for (const obstacle of hangar.obstacles.filter(
    (o) => o.type === ObstacleType.UNOBSTRUCTIVE
  )) {
  }
  ctx.restore();
};

export const drawSpacingBuffers = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  feetToPixels: (ft: number) => number,
  options?: {
    lineWidth?: number;
    lineDash?: number[];
  }
) => {
  ctx.beginPath();
  ctx.save();

  const lineWidth = options?.lineWidth ?? 2;
  const lineDash = options?.lineDash ?? [4, 4];

  if (hangar.wall_spacing_left) {
    ctx.setLineDash([]);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "red";
    ctx.setLineDash(lineDash);
    ctx.moveTo(feetToPixels(hangar.wall_spacing_left), ctx.canvas.height);
    ctx.lineTo(
      feetToPixels(hangar.wall_spacing_left),
      feetToPixels(hangar.wall_spacing_back ?? 0)
    );
    ctx.stroke();
  }
  if (hangar.wall_spacing_back) {
    ctx.setLineDash([]);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "red";
    ctx.setLineDash(lineDash);
    ctx.moveTo(
      feetToPixels(hangar.wall_spacing_left ?? 0),
      feetToPixels(hangar.wall_spacing_back)
    );
    ctx.lineTo(
      ctx.canvas.width - feetToPixels(hangar.wall_spacing_right ?? 0),
      feetToPixels(hangar.wall_spacing_back)
    );
    ctx.stroke();
  }
  if (hangar.wall_spacing_right) {
    ctx.setLineDash([]);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "red";
    ctx.setLineDash(lineDash);
    ctx.moveTo(
      feetToPixels(hangar.width - hangar.wall_spacing_right),
      ctx.canvas.height
    );
    ctx.lineTo(
      feetToPixels(hangar.width - hangar.wall_spacing_right),
      feetToPixels(hangar.wall_spacing_back ?? 0)
    );
    ctx.stroke();
  }
  ctx.restore();
  ctx.save();
  ctx.beginPath();
  if (hangar.obstacles) {
    for (const obstacle of hangar.obstacles) {
      if (obstacle.type === ObstacleType.UNOBSTRUCTIVE) {
        continue;
      }
      if (obstacle.type === ObstacleType.CAUTION) {
        continue;
      }
      if (obstacle.type === ObstacleType.NO_TOW_AREA) {
        ctx.clearRect(
          feetToPixels(obstacle.x),
          feetToPixels(obstacle.y),
          feetToPixels(obstacle.width + lineWidth),
          feetToPixels(obstacle.depth + lineWidth)
        );
        ctx.restore();
        continue;
      }
      // top left corner
      if (obstacle.y === 0 && obstacle.x === 0) {
        ctx.clearRect(
          feetToPixels(0),
          feetToPixels(0),
          feetToPixels(obstacle.width + hangar.wall_spacing_left),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.setLineDash(lineDash);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        ctx.moveTo(
          feetToPixels(hangar.wall_spacing_left),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.lineTo(
          feetToPixels(obstacle.width + hangar.wall_spacing_left),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.lineTo(
          feetToPixels(obstacle.width + hangar.wall_spacing_left),
          feetToPixels(hangar.wall_spacing_back)
        );
        ctx.stroke();
        ctx.restore();
        continue;
      }
      // top right corner
      if (
        obstacle.y === 0 &&
        isAlmostEqual(obstacle.x + obstacle.width, hangar.width)
      ) {
        ctx.clearRect(
          feetToPixels(obstacle.x - hangar.wall_spacing_right),
          feetToPixels(0),
          feetToPixels(obstacle.width + hangar.wall_spacing_right * 2),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.setLineDash(lineDash);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        ctx.moveTo(
          feetToPixels(obstacle.x - hangar.wall_spacing_right),
          feetToPixels(hangar.wall_spacing_back)
        );
        ctx.lineTo(
          feetToPixels(obstacle.x - hangar.wall_spacing_right),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.lineTo(
          feetToPixels(obstacle.x + obstacle.width - hangar.wall_spacing_right),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.stroke();
        ctx.restore();
        continue;
      }
      // check to see if it is touching a wall
      if (obstacle.y === 0) {
        ctx.clearRect(
          feetToPixels(obstacle.x - hangar.wall_spacing_back),
          feetToPixels(0),
          feetToPixels(obstacle.width + hangar.wall_spacing_back * 2),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        ctx.setLineDash(lineDash);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        // if the left buffer on the obstacle is within the wall spacing
        // then we aren't going to paint the left buffer
        // top left
        if (obstacle.x - hangar.wall_spacing_back < hangar.wall_spacing_back) {
          ctx.moveTo(
            feetToPixels(hangar.wall_spacing_back),
            feetToPixels(obstacle.depth + hangar.wall_spacing_back)
          );
        } else {
          ctx.moveTo(
            feetToPixels(obstacle.x - hangar.wall_spacing_back),
            feetToPixels(hangar.wall_spacing_back)
          );
        }

        // bottom left
        ctx.lineTo(
          feetToPixels(
            Math.max(
              hangar.wall_spacing_back,
              obstacle.x - hangar.wall_spacing_back
            )
          ),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        // bottom right
        ctx.lineTo(
          feetToPixels(
            Math.min(
              hangar.width - hangar.wall_spacing_back,
              obstacle.x + obstacle.width + hangar.wall_spacing_back
            )
          ),
          feetToPixels(obstacle.depth + hangar.wall_spacing_back)
        );
        // top right
        if (
          hangar.width -
            (obstacle.x + obstacle.width + hangar.wall_spacing_back) <
          hangar.wall_spacing_back
        ) {
          // do nothing
        } else {
          ctx.lineTo(
            feetToPixels(
              obstacle.x + obstacle.width + hangar.wall_spacing_back
            ),
            feetToPixels(hangar.wall_spacing_back)
          );
        }
        ctx.stroke();
      }
      if (obstacle.x === 0) {
        ctx.clearRect(
          feetToPixels(0),
          feetToPixels(obstacle.y - hangar.wall_spacing_left),
          feetToPixels(obstacle.width + hangar.wall_spacing_left),
          feetToPixels(obstacle.depth + hangar.wall_spacing_left * 2)
        );
        ctx.setLineDash(lineDash);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        // if the top buffer on the obstacle is within the wall spacing
        // then we aren't going to paint the top buffer
        if (obstacle.y - hangar.wall_spacing_left < hangar.wall_spacing_left) {
          ctx.moveTo(
            feetToPixels(
              obstacle.x + obstacle.width + hangar.wall_spacing_left
            ),
            feetToPixels(hangar.wall_spacing_left)
          );
        } else {
          ctx.moveTo(
            feetToPixels(obstacle.x + hangar.wall_spacing_left),
            feetToPixels(obstacle.y - hangar.wall_spacing_left)
          );
        }

        ctx.lineTo(
          feetToPixels(obstacle.x + obstacle.width + hangar.wall_spacing_left),
          feetToPixels(
            Math.max(
              hangar.wall_spacing_left,
              obstacle.y - hangar.wall_spacing_left
            )
          )
        );
        ctx.lineTo(
          feetToPixels(obstacle.x + obstacle.width + hangar.wall_spacing_left),
          feetToPixels(obstacle.y + obstacle.depth + hangar.wall_spacing_left)
        );
        ctx.lineTo(
          feetToPixels(obstacle.x + hangar.wall_spacing_left),
          feetToPixels(obstacle.y + obstacle.depth + hangar.wall_spacing_left)
        );
        ctx.stroke();
      }
      if (isAlmostEqual(obstacle.x + obstacle.width, hangar.width)) {
        ctx.clearRect(
          feetToPixels(
            hangar.width - obstacle.width - hangar.wall_spacing_right
          ),
          feetToPixels(obstacle.y - hangar.wall_spacing_right),
          feetToPixels(obstacle.width + hangar.wall_spacing_right * 2) +
            lineWidth,
          feetToPixels(obstacle.depth + hangar.wall_spacing_right * 2) +
            lineWidth
        );
        ctx.setLineDash(lineDash);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        if (
          obstacle.y - hangar.wall_spacing_right <
          hangar.wall_spacing_right
        ) {
          ctx.moveTo(
            feetToPixels(
              hangar.width - hangar.wall_spacing_right - obstacle.width
            ),
            feetToPixels(hangar.wall_spacing_right)
          );
        } else {
          ctx.moveTo(
            feetToPixels(hangar.width - hangar.wall_spacing_right),
            feetToPixels(obstacle.y - hangar.wall_spacing_right)
          );
        }
        ctx.lineTo(
          feetToPixels(
            hangar.width - hangar.wall_spacing_right - obstacle.width
          ),
          feetToPixels(
            Math.max(
              hangar.wall_spacing_right,
              obstacle.y - hangar.wall_spacing_right
            )
          )
        );
        ctx.lineTo(
          feetToPixels(
            hangar.width - hangar.wall_spacing_right - obstacle.width
          ),
          feetToPixels(obstacle.y + obstacle.depth + hangar.wall_spacing_right)
        );
        ctx.lineTo(
          feetToPixels(hangar.width - hangar.wall_spacing_right),
          feetToPixels(obstacle.y + obstacle.depth + hangar.wall_spacing_right)
        );
        ctx.stroke();
      }
    }
  }
  ctx.restore();
};

export const drawDistanceMarkers = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  obstacle: Obstacle,
  feetToPixels: (ft: number) => number,
  unit: string = "ft"
) => {
  if (!obstacle) {
    return;
  }
  const obstacleInPixels: Obstacle = {
    ...obstacle,
    x: feetToPixels(obstacle.x),
    y: feetToPixels(obstacle.y),
    width: feetToPixels(obstacle.width),
    depth: feetToPixels(obstacle.depth),
    height: feetToPixels(obstacle.height),
  };
  ctx.beginPath();
  ctx.save();

  ctx.beginPath();
  ctx.lineWidth = 4;
  ctx.setLineDash([4]);
  ctx.strokeStyle = "skyblue";
  // horizontal
  ctx.moveTo(0, obstacleInPixels.y + obstacleInPixels.depth / 2);
  ctx.lineTo(ctx.canvas.width, obstacleInPixels.y + obstacleInPixels.depth / 2);
  ctx.stroke();
  // vertical
  ctx.moveTo(obstacleInPixels.x + obstacleInPixels.width / 2, 0);
  ctx.lineTo(
    obstacleInPixels.x + obstacleInPixels.width / 2,
    ctx.canvas.height
  );
  ctx.stroke();

  ctx.textAlign = "center";
  ctx.font = "14px Lexend Deca";
  ctx.fillStyle = "black";
  // left
  labelMeasuringLine(
    ctx,
    obstacleInPixels,
    obstacleInPixels.x / 2,
    obstacleInPixels.y + obstacleInPixels.depth / 2 + 14,
    obstacle.x,
    unit
  );
  // top
  labelMeasuringLine(
    ctx,
    obstacleInPixels,
    (obstacleInPixels.x + (obstacleInPixels.x + obstacleInPixels.width)) / 2,
    obstacleInPixels.y / 2,
    obstacle.y,
    unit
  );
  // bottom
  labelMeasuringLine(
    ctx,
    obstacleInPixels,
    (obstacleInPixels.x + (obstacleInPixels.x + obstacleInPixels.width)) / 2,
    ctx.canvas.height -
      (ctx.canvas.height - obstacleInPixels.y - obstacleInPixels.depth) / 2,
    hangar.depth - obstacle.y - obstacle.depth,
    unit
  );
  // right
  labelMeasuringLine(
    ctx,
    obstacleInPixels,
    ctx.canvas.width -
      (ctx.canvas.width - obstacleInPixels.x - obstacleInPixels.width) / 2,
    obstacleInPixels.y + obstacleInPixels.depth / 2 + 14,
    hangar.width - obstacle.x - obstacle.width,
    unit
  );
  ctx.restore();
};

export const labelMeasuringLine = (
  ctx: CanvasRenderingContext2D,
  obstacleInPixels: Obstacle,
  x: number,
  y: number,
  size: number,
  unit: string = "ft"
) => {
  size = unit === "m" ? feetToMeters(size) : size;
  // if smushed to the back
  if (y < 20) {
    const value = Math.abs(round(size, 2));
    ctx.fillText(
      `${value} ${unit}`,
      x - 30 - obstacleInPixels.width / 2,
      y + 10
    );
    return;
  }
  // if smushed to the left
  if (x < 20) {
    const value = Math.abs(round(size, 2));
    ctx.fillText(`${value} ${unit}`, 30, y + obstacleInPixels.depth / 2);
    return;
  }
  // if smushed to the right
  if (x > ctx.canvas.width - 20) {
    const value = Math.abs(round(size, 2));
    ctx.fillText(
      `${value} ${unit}`,
      ctx.canvas.width - 30,
      y + obstacleInPixels.depth / 2
    );
    return;
  }
  // if smushed to the bottom
  if (y > ctx.canvas.height - 20) {
    const value = Math.abs(round(size, 2));
    ctx.fillText(
      `${value} ${unit}`,
      x - 30 - obstacleInPixels.width / 2,
      y - 10
    );
    return;
  }
  ctx.fillText(`${Math.abs(round(size, 2))} ${unit}`, x, y);
};

export const drawGarageDoorMarkers = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  garageDoors: GarageDoor[],
  feetToPixels: (ft: number) => number,
  unit: string = "ft"
) => {
  if (!garageDoors) {
    return;
  }

  for (const garageDoor of garageDoors) {
    if (!garageDoor.active) {
      continue;
    }
    ctx.lineWidth = 2;
    ctx.setLineDash([8]);
    ctx.strokeStyle = "skyblue";
    ctx.textAlign = "center";
    ctx.font = "14px Lexend Deca";
    ctx.fillStyle = "black";
    if (garageDoor.wall === "back") {
      const x = feetToPixels(garageDoor.x);
      const y = feetToPixels(2);
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(ctx.canvas.width, y);
      ctx.stroke();
      const value1 =
        unit === "m"
          ? Math.abs(round(feetToMeters(garageDoor.x), 2))
          : Math.abs(round(garageDoor.x, 2));
      ctx.fillText(`${value1} ${unit}`, Math.max(40, x / 2), y * 3);
      const value2 =
        unit === "m"
          ? Math.abs(
              round(
                feetToMeters(hangar.width - garageDoor.width - garageDoor.x),
                2
              )
            )
          : Math.abs(round(hangar.width - garageDoor.width - garageDoor.x, 2));
      ctx.fillText(
        `${value2} ${unit}`,
        Math.min(
          ctx.canvas.width - 40,
          feetToPixels(
            garageDoor.x +
              garageDoor.width +
              (hangar.width - garageDoor.x - garageDoor.width) / 2
          )
        ),
        y * 3
      );
      ctx.restore();
    } else if (garageDoor.wall === "left") {
      const x = feetToPixels(2);
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, ctx.canvas.height);
      ctx.stroke();
      const value1 =
        unit === "m"
          ? Math.abs(round(feetToMeters(garageDoor.y), 2))
          : Math.abs(round(garageDoor.y, 2));
      ctx.fillText(
        `${value1} ${unit}`,
        x * 5,
        feetToPixels(Math.max(10, garageDoor.y)) / 2
      );
      const value2 =
        unit === "m"
          ? Math.abs(
              round(
                feetToMeters(hangar.depth - garageDoor.y - garageDoor.width),
                2
              )
            )
          : Math.abs(round(hangar.depth - garageDoor.y - garageDoor.width, 2));
      ctx.fillText(
        `${value2} ${unit}`,
        x * 5,
        feetToPixels(
          Math.min(
            hangar.depth - 10,
            (hangar.depth + garageDoor.y + garageDoor.width) / 2
          )
        )
      );
      ctx.restore();
    } else if (garageDoor.wall === "right") {
      const x = feetToPixels(hangar.width - 15);
      ctx.beginPath();
      ctx.moveTo(feetToPixels(hangar.width - 1), 0);
      ctx.lineTo(feetToPixels(hangar.width - 1), ctx.canvas.height);
      ctx.stroke();
      const value1 =
        unit === "m"
          ? Math.abs(round(feetToMeters(garageDoor.y), 2))
          : Math.abs(round(garageDoor.y, 2));
      ctx.fillText(
        `${value1} ${unit}`,
        x,
        feetToPixels(Math.max(10, garageDoor.y)) / 2
      );
      const value2 =
        unit === "m"
          ? Math.abs(
              round(
                feetToMeters(hangar.depth - garageDoor.y - garageDoor.width),
                2
              )
            )
          : Math.abs(round(hangar.depth - garageDoor.y - garageDoor.width, 2));
      ctx.fillText(
        `${value2} ${unit}`,
        x,
        feetToPixels(
          Math.min(
            hangar.depth - 10,
            (hangar.depth + garageDoor.y + garageDoor.width) / 2
          )
        )
      );
      ctx.restore();
    }
  }
};

export const clearBackgroundForObstacles = (
  ctx: CanvasRenderingContext2D,
  hangar: Hangar,
  feetToPixels: (ft: number) => number
) => {
  ctx.beginPath();
  for (const obstacle of hangar.obstacles) {
    if (obstacle.type === ObstacleType.UNOBSTRUCTIVE) {
      continue;
    }
    if (obstacle.type === ObstacleType.CAUTION) {
      continue;
    }
    const obstacleInPixels: Obstacle = {
      ...obstacle,
      x: feetToPixels(obstacle.x),
      y: feetToPixels(obstacle.y),
      width: feetToPixels(obstacle.width),
      depth: feetToPixels(obstacle.depth),
      height: feetToPixels(obstacle.height),
    };
    ctx.clearRect(
      obstacleInPixels.x,
      obstacleInPixels.y,
      obstacleInPixels.width,
      obstacleInPixels.depth
    );
  }
  ctx.save();
};
