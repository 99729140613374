import { Box, Divider, Stack, Typography } from "@mui/material";
import * as React from "react";
import logo from "../../../airplx-logo-transparent.png";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useFeatureFlags } from "../../containers/FeatureFlagsContainer";
import { getStackPolygons } from "../../hooks/utils";
import { Hangar, Stack as StackType } from "../../types";
import { formatNumber } from "../../utils";
import { feetToMeters } from "../../utils/math";
import { Layout } from "../../widgets/Layout";
import {
  calcEstimateTowTime,
  estimateValue,
} from "../Hangars/HangarsPresenter";
import {
  calculateUtilization,
  findTenantsFullyInHangar,
} from "./HangarInfoCard";

type Props = {
  hangar: Hangar;
  stack: StackType;
  subtitle?: string;
};

export const HangarPrintLayout: React.FC<Props> = ({
  hangar: currentHangar,
  stack,
  subtitle,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { isConference } = useFeatureFlags();
  const hangar = {
    ...currentHangar,
    stack,
  };
  const polygons = getStackPolygons(stack, hangar.width, 1, false);

  const dimensions = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(hangar.width), 2)}w x ${formatNumber(
        feetToMeters(hangar.depth),
        2
      )}d  x ${formatNumber(feetToMeters(hangar.height), 2)}h`
    : `${formatNumber(hangar.width, 2)}w x ${formatNumber(
        hangar.depth,
        2
      )}d  x ${formatNumber(hangar.height, 2)}h`;

  return (
    <Box>
      <Stack direction="column" justifyContent="space-between">
        <style type="text/css" media="print">
          {`@page { size: ${
            hangar.depth > hangar.width ? "portrait" : "landscape"
          }; }`}
        </style>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Typography variant="h5">
              {subtitle ? `${hangar.name}: ${subtitle}` : hangar.name}
            </Typography>
            <Typography>{dimensions}</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ width: 500, height: 50 }}
          >
            <Stack direction="column">
              <Typography variant="subtitle1">Aircraft</Typography>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {findTenantsFullyInHangar(hangar, polygons).length}
              </Typography>
            </Stack>
            <Divider orientation="vertical" />
            <Stack direction="column">
              <Typography variant="subtitle1">Utilization</Typography>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {calculateUtilization(
                  hangar,
                  polygons,
                  true,
                  activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
                )}
                %{" "}
              </Typography>
            </Stack>
            {isConference && (
              <>
                <Divider orientation="vertical" />
                <Stack direction="column">
                  <Typography variant="subtitle1">Est. Value</Typography>
                  <Typography
                    variant="caption"
                    textAlign="center"
                    color="info.dark"
                  >
                    {estimateValue(
                      activeFBO,
                      hangar,
                      polygons,
                      activeFBO?.settings?.ignoreDeadSpaceForUtilization ??
                        false
                    )}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" />
                <Stack direction="column">
                  <Typography variant="subtitle1">Est. Tow Time</Typography>
                  <Typography
                    variant="caption"
                    textAlign="center"
                    color="info.dark"
                  >
                    {calcEstimateTowTime(hangar, polygons)}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        <br />
        <Layout
          stack={stack}
          setStack={() => {
            // not needed
          }}
          location={{
            ...hangar,
            type: "hangar",
          }}
          height={
            hangar.depth > hangar.width
              ? (hangar.depth / hangar.width) * 600
              : (hangar.depth / hangar.width) * 650
          }
          options={{
            hideUnplaced: true,
            readOnly: true,
            selectable: false,
            printable: true,
          }}
        />
      </Stack>
      {isConference && (
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        >
          <Typography align="right">
            Automatically optimized by
            <br />
            Contact us at www.airplx.com or peter@airplx.com.
          </Typography>
          <img src={logo} style={{ width: 175 }} />
        </Stack>
      )}
    </Box>
  );
};
