import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { formatDistance } from "date-fns";
import * as React from "react";
import { useIdentity } from "../../containers/IdentityContainer";
import { Tenant } from "../../types";

type Props = {
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled?: boolean;
};

export const NotesDialog: React.FC<Props> = ({
  open,
  setOpen,
  tenant,
  setTenant,
  disabled,
}) => {
  const { airplxIdentity } = useIdentity();
  const [localTenant, setLocalTenant] = React.useState<Tenant>(tenant);

  React.useEffect(() => {
    if (open) {
      setLocalTenant(tenant);
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Aircraft Notes</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} p={1}>
          <TextField
            disabled={disabled}
            autoFocus
            margin="dense"
            label="Notes"
            type="text"
            fullWidth
            placeholder="Departure Time: 05/24/2025 12:00 PM"
            multiline
            minRows={5}
            onChange={(evt) => {
              setLocalTenant({
                ...tenant,
                note: evt.target.value,
                note_last_updated: new Date().toISOString(),
                note_last_updated_by: airplxIdentity?.email ?? "Unknown",
              });
            }}
            value={localTenant.note ?? ""}
          />
          {Boolean(tenant.note_last_updated) && (
            <Typography variant="body2">
              Last update:{" "}
              {formatDistance(new Date(tenant.note_last_updated), new Date(), {
                addSuffix: true,
              })}{" "}
              ({new Date(tenant.note_last_updated).toLocaleDateString()}) by{" "}
              {tenant.note_last_updated_by}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={() => {
            setTenant(localTenant);
            setOpen(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
