import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { sum } from "lodash";
import * as React from "react";
import { AutocompleteResultsContainer } from "../../containers/AutocompleteResultsContainer";
import { getStackPolygons } from "../../hooks/utils";
import { formatNumber, formatPercentage, uuidv4 } from "../../utils";
import { StackResult } from "../Hangar/EvaluatingStackDialog/StackResult";
import { calculateUtilization } from "../Hangar/HangarInfoCard";
import { HangarResult } from "../StackMaster/StackMasterPresenter";

type Props = {
  result: HangarResult;
};

export const OptimizationHangarResultRow: React.FC<Props> = ({ result }) => {
  const [showStacks, setShowStacks] = React.useState<boolean>(false);
  const resultStacks = React.useMemo(() => {
    return result.layoutOptions.map((layout) => {
      const id = uuidv4();
      const stack = {
        id,
        options: layout.paramSet.options,
        tenants: layout.stackMembers.map((stackMember) => ({
          ...result.hangar.stack.tenants.find(
            (t) => t.placement_id === stackMember.placement_id
          ),
          selected: false, // overwrite or keep?
          position: {
            ...result.hangar.stack.tenants.find(
              (t) => t.placement_id === stackMember.placement_id
            ).position,
            x: stackMember.x,
            y: stackMember.y,
            angle: stackMember.angle,
            stack_id: id,
          },
        })),
        movableObstacles: result.hangar.stack.movableObstacles,
        isReference: result.hangar.stack.isReference,
      };
      const polygons = getStackPolygons(stack, result.hangar.width, 1);
      return {
        stack,
        polygons,
        utilization: calculateUtilization(result.hangar, polygons, true),
      };
    });
  }, [result.layoutOptions]);

  const formatNumberInHangar = (value: number) => {
    if (isNaN(value) || value === Infinity || value === -Infinity) {
      return "---";
    }
    return formatNumber(value, 0);
  };
  const worstInHangar = formatNumberInHangar(
    Math.min(
      ...resultStacks.map(
        ({ stack }) =>
          stack.tenants.filter(({ position }) => position.x != null).length
      )
    )
  );

  const bestInHangar = formatNumberInHangar(
    Math.max(
      ...resultStacks.map(
        ({ stack }) =>
          stack.tenants.filter(({ position }) => position.x != null).length
      )
    )
  );
  const averageInHangar = formatNumberInHangar(
    sum(
      resultStacks.map(
        ({ stack }) =>
          stack.tenants.filter(({ position }) => position.x != null).length
      )
    ) / resultStacks.length
  );

  const formatUtilization = (value: number) => {
    if (isNaN(value) || value === Infinity || value === -Infinity) {
      return "---";
    }
    return formatPercentage(value / 100, 0);
  };
  const worstUtilization = formatUtilization(
    Math.min(...resultStacks.map((stack) => stack.utilization))
  );
  const bestUtilization = formatUtilization(
    Math.max(...resultStacks.map((stack) => stack.utilization))
  );
  const averageUtilization = formatUtilization(
    sum(resultStacks.map((stack) => stack.utilization)) / resultStacks.length
  );

  return (
    <TableRow key={result.id}>
      <TableCell>{result.hangar.name}</TableCell>
      <TableCell>
        [{result.hangar.left_door}, {result.hangar.right_door}]
      </TableCell>
      <TableCell>
        <LinearProgress
          variant="determinate"
          color="success"
          value={(sum(result.progress) / result.progress.length) * 100}
          sx={{ width: "100%" }}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent={"center"}>
          <Typography variant="inherit">{worstInHangar}</Typography>
          <Typography variant="inherit">/</Typography>
          <Typography variant="inherit">{bestInHangar}</Typography>
          <Typography variant="inherit">/</Typography>
          <Typography variant="inherit">{averageInHangar}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent={"center"}>
          <Typography variant="inherit">{worstUtilization}</Typography>
          <Typography variant="inherit">/</Typography>
          <Typography variant="inherit">{bestUtilization}</Typography>
          <Typography variant="inherit">/</Typography>
          <Typography variant="inherit">{averageUtilization}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowStacks(true)}
          >
            View
          </Button>
          <Dialog open={showStacks} maxWidth="lg" fullWidth>
            <DialogContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">{result.hangar.name}</Typography>
                <IconButton onClick={() => setShowStacks(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Grid2 container spacing={2}>
                {resultStacks.map(({ stack }) => (
                  <Grid2 key={stack.id} size={{ xs: 6 }}>
                    <AutocompleteResultsContainer>
                      <StackResult
                        readOnly
                        status={"complete"}
                        hangar={result.hangar}
                        setHangar={() => {}}
                        stack={stack}
                        onClose={() => setShowStacks(false)}
                        onFlagResult={function(
                          id: string,
                          isFlagSet: boolean
                        ): Promise<void> {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </AutocompleteResultsContainer>
                  </Grid2>
                ))}
              </Grid2>
            </DialogContent>
          </Dialog>
        </>
      </TableCell>
    </TableRow>
  );
};
