import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";

import * as React from "react";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDone: (text: string) => void;
};
export const CanvasTextInput: React.FC<Props> = ({ open, setOpen, onDone }) => {
  const [text, setText] = React.useState<string>("");

  React.useEffect(() => {
    setText("");
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <Stack direction="column" spacing={1} p={2}>
        <Typography>Input Text</Typography>
        <TextField
          variant="outlined"
          size="small"
          autoFocus
          type="text"
          label="Name"
          fullWidth
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            onClick={() => {
              setOpen(false);
              setText("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setOpen(false);
              onDone(text);
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
