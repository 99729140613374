import { Box, Stack, useTheme } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { CollapsibleRampActionCard } from "../Ramp/CollapsibleRampActionCard";
import { HangarLeftPanelWrapper } from "./HangarLeftPanelWrapper";
import { HoldingAreaPresenter } from "./HoldingAreaPresenter";
import { LayoutToolbarWrapper } from "./LayoutToolbarWrapper";
import { MultiHangarInfoCard } from "./MultiHangarInfoCard";

export const HOLDING_AREA_ID = "holding-area";

type Props = {
  feetToPixels: number;
  multiHangarLayoutHeight: number;
};

export const HoldingArea: React.FC<Props> = ({
  feetToPixels,
  multiHangarLayoutHeight,
}) => {
  const {
    focus,
    activeLocationId,
    setActiveLocationId,
    hangars,
  } = useMultiHangarState();
  const theme = useTheme();
  const navigate = useNavigate();

  const holdingArea = hangars.find((h) => h.id === HOLDING_AREA_ID);

  if (!holdingArea || !holdingArea.stack) {
    return null;
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      onClick={(e) => {
        // if the user is focused on one of the hangars, keep the selected hangar blue
        if (focus) {
          return;
        }
        setActiveLocationId(HOLDING_AREA_ID);
      }}
      sx={{
        pr: 1,
        mt: "68px",
        height: "calc(100vh - 120px)",
        width: 250,
      }}
    >
      <>
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            top: 0,
            left: 72,
            display: activeLocationId === HOLDING_AREA_ID ? "block" : "none",
          }}
        >
          <CollapsibleRampActionCard
            hideTitle
            location={holdingArea}
            onClickBack={() => navigate("/hangars")}
          >
            <Stack
              direction="column"
              display="flex"
              flex={1}
              spacing={1}
              height="98%"
            >
              <MultiHangarInfoCard />
              <HangarLeftPanelWrapper
                hangarId={HOLDING_AREA_ID}
                width={300}
                viewableHangars={hangars}
                activeHangarId={activeLocationId}
                setActiveHangarId={setActiveLocationId}
                // none of this actually gets used for the holding area so we just stub it out
                stacks={[]}
                setStacks={() => null}
                stackStrategy={null}
                setStackStrategy={() => null}
                customPlacementOptions={{}}
                setCustomPlacementOptions={() => null}
              />
            </Stack>
          </CollapsibleRampActionCard>
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            top: 0,
            left: 475,
            width: "100%",
            display: activeLocationId === HOLDING_AREA_ID ? "block" : "none",
          }}
        >
          <LayoutToolbarWrapper
            hangarId={HOLDING_AREA_ID}
            setStacks={() => null}
          />
        </Box>
        <Box sx={{ borderRadius: 2 }}>
          <HoldingAreaPresenter
            active={activeLocationId === HOLDING_AREA_ID}
            feetToPixels={feetToPixels}
            multiHangarDepth={
              (multiHangarLayoutHeight || 0) - parseFloat(theme.spacing(2))
            }
            customPlacementOptions={{
              wall_spacing_left: 0,
              wall_spacing_back: 0,
              wall_spacing_right: 0,
              horizontal_spacing: 0,
              vertical_spacing: 0,
              overlap: false,
              ignore_nose_in_out: false,
              ignore_front_back: false,
              ignore_outside_hangar: false,
              density_threshold: 0,
              tug_spacing: 0,
              sample_size: 0,
              corner_strategies: [],
              cloud_position_prioritization: false,
              xy_weights: [],
              towing_equipment_id: "",
            }}
          />
        </Box>
      </>
    </Stack>
  );
};
