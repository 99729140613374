import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import feeCalculatorScreenshot from "../../../../static/screenshot-fee-calculator-button.png";
import { formatCurrency, formatNumber } from "../../../utils";
import { FormLabel } from "../../Estimator/FeeCalculator2Presenter";
import { Aircraft } from "../../LabelingTool";
import { AutocompleteAircraft } from "../HangarSettings/AutocompleteAircraft";
import { AnnualTenantTable } from "./AnnualTenantTable";
import { RatesForm } from "./RatesForm";

const calculateSqftPrice = (
  aircraft: Aircraft,
  rate: number,
  type: string
): number => {
  if (type === "base") {
    return rate / (aircraft.length * aircraft.wingspan) / 30;
  }
  // otherwise it's nightly
  return rate / (aircraft.length * aircraft.wingspan);
};

export const RatesTab: React.FC = () => {
  return (
    <Stack direction="column" spacing={4}>
      <RatesForm />
      <RateCalculator />
    </Stack>
  );
};

export const RateCalculator: React.FC = () => {
  const [aircraft, setAircaft] = React.useState<Aircraft | null>(null);
  const [rate, setRate] = React.useState<number>(0);
  return (
    <Stack direction="column" spacing={1}>
      <FormLabel
        title="Help Calculate My Current Rates"
        subtitle={`If you don't know your typical monthly tenant or nightly transient rates, use this calculator to figure them out. Just enter an example aircraft and the total fee you currently charge to see what that equates to in monthly or daily rates.`}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Typography width={300}>Aircraft</Typography>
        <Typography width={175}>Length x Wingspan</Typography>
        <Typography width={175}>Total Sqft</Typography>
        <Typography width={120}>Total Fee</Typography>
        <Typography width={125}>$/sqft</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box width={300}>
          <AutocompleteAircraft label={"Make/Model"} onSelect={setAircaft} />
        </Box>
        <Typography width={175}>
          {aircraft
            ? `${round(aircraft.length, 0)}L x ${round(aircraft.wingspan)}WS`
            : "---"}
        </Typography>
        <Typography width={175}>
          {aircraft
            ? `${round(aircraft.length * aircraft.wingspan, 0)} sqft`
            : "---"}
        </Typography>
        <TextField
          type="number"
          inputProps={{ min: 0.01, max: 100, step: 0.01, inputMode: "decimal" }}
          label="Rate"
          fullWidth
          size="small"
          onChange={(evt) => {
            setRate(round(parseFloat(evt.target.value), 2));
          }}
          value={rate}
          sx={{ width: 200 }}
        />
        <Typography width={125}>
          {!aircraft || !rate
            ? "---"
            : `$${round(calculateSqftPrice(aircraft, rate, "transient"), 3)}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const FeeCalculator: React.FC<{
  defaultMonthlyRate?: number;
  defaultDailyRate?: number;
}> = ({ defaultMonthlyRate, defaultDailyRate }) => {
  const [aircraft, setAircaft] = React.useState<Aircraft | null>(null);
  const [buffer, setBuffer] = React.useState<number>(0);
  const [monthlyRate, setMonthlyRate] = React.useState<number>(
    defaultMonthlyRate ?? 0
  );
  const [dailyRate, setDailyRate] = React.useState<number>(
    defaultDailyRate ?? 0
  );
  const [cam, setCAM] = React.useState<number>(0);
  const [camPerSqft, setCamPerSqft] = React.useState<number>(0);

  const aircraftDimensions = aircraft
    ? `${formatNumber(aircraft.length, 1)}L x ${formatNumber(
        aircraft.wingspan,
        1
      )}WS`
    : "---";

  const aircraftSqftValue = aircraft
    ? (2 * buffer + aircraft.length) * (2 * buffer + aircraft.wingspan)
    : 0;
  const aircraftSqft = aircraft ? `${round(aircraftSqftValue, 0)} sqft` : "---";

  const dailyTenantDisplayValue =
    !aircraft || !dailyRate
      ? "---"
      : formatCurrency(
          dailyRate *
            (2 * buffer + aircraft.length) *
            (2 * buffer + aircraft.wingspan),
          2
        );

  const monthlyTenantValue =
    monthlyRate *
    (2 * buffer + aircraft?.length) *
    (2 * buffer + aircraft?.wingspan);

  let monthlyTenantValueWithCAM =
    monthlyRate *
    (2 * buffer + aircraft?.length) *
    (2 * buffer + aircraft?.wingspan);

  if (cam) {
    monthlyTenantValueWithCAM += monthlyTenantValue * (cam / 100);
  } else if (camPerSqft) {
    monthlyTenantValueWithCAM +=
      camPerSqft * aircraftSqftValue + monthlyTenantValue;
  }

  const monthlyTenantDisplayValue =
    !aircraft || !monthlyRate ? "---" : formatCurrency(monthlyTenantValue, 2);

  const monthlyTenantDisplayValueWithCAM =
    !aircraft || !monthlyRate
      ? "---"
      : formatCurrency(monthlyTenantValueWithCAM, 2);

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Typography width={300}>Aircraft</Typography>
        <Typography width={175}>Length x Wingspan</Typography>
        <Typography width={100}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">Buffer</Typography>
            <Tooltip title="A buffer is added to each end of the dimensions. Eg: a 2ft buffer adds a total of 4ft to the length and 4ft to the wingspan.">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
        <Typography width={120}>Total Sqft</Typography>
        <Typography width={120}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">Rate</Typography>
            <Tooltip title="Enter your desired rates below. You can also change the default rates by going to Settings → Estimation→Rates.">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
        <Typography width={100}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">CAM</Typography>
            <Tooltip title="Common Area Maintenance Fees added to monthly tenant rate">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
        <Typography width={125} textAlign="center">
          Total
        </Typography>
        <Typography width={125} textAlign="center">
          Total + CAM
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={1}>
        <Box width={300}>
          <AutocompleteAircraft label={"Make/Model"} onSelect={setAircaft} />
        </Box>
        <Typography width={175} height={40} pt={1}>
          {aircraftDimensions}
        </Typography>
        <TextField
          type="number"
          inputProps={{ min: 0.0, max: 5, step: 0.5, inputMode: "decimal" }}
          label="Buffer"
          size="small"
          onChange={(evt) => setBuffer(Number(evt.target.value))}
          value={buffer}
          sx={{ width: 100 }}
        />
        <Typography width={120} height={40} pt={1}>
          {aircraftSqft}
        </Typography>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <TextField
              type="number"
              inputProps={{
                min: 1,
                max: 100,
                step: 0.01,
                inputMode: "decimal",
              }}
              label="$ / sqft / month"
              size="small"
              onChange={(evt) =>
                setMonthlyRate(round(parseFloat(evt.target.value), 2))
              }
              value={monthlyRate}
              sx={{ width: 120 }}
            />
            <TextField
              type="number"
              inputProps={{
                min: 0.0,
                max: 100,
                step: 1,
                inputMode: "numeric",
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              onChange={(evt) => {
                // Parse the value without the percentage sign
                const value = parseFloat(evt.target.value);
                setCAM(value || 0);
                setCamPerSqft(0);
              }}
              value={cam}
              sx={{ width: 120 }}
            />
            <Typography width={125} pt={1} textAlign="center">
              {monthlyTenantDisplayValue}
            </Typography>
            <Typography width={125} pt={1} textAlign="center">
              {monthlyTenantDisplayValueWithCAM}
            </Typography>
            <Typography width={175} pt={1} textAlign="left">
              Tenant (monthly)
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <TextField
              type="number"
              inputProps={{
                min: 0.01,
                max: 100,
                step: 0.01,
                inputMode: "decimal",
              }}
              label="$ / sqft / day"
              size="small"
              onChange={(evt) =>
                setDailyRate(round(parseFloat(evt.target.value), 2))
              }
              value={dailyRate}
              sx={{ width: 120 }}
            />
            <TextField
              type="number"
              inputProps={{
                min: 0.0,
                max: 100,
                step: 1,
                inputMode: "numeric",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">$/ft²</InputAdornment>
                ),
              }}
              size="small"
              onChange={(evt) => {
                // Parse the value without the percentage sign
                const value = parseFloat(evt.target.value);
                setCamPerSqft(value || 0);
                setCAM(0);
              }}
              value={camPerSqft}
              sx={{ width: 120 }}
            />
            <Typography width={125} pt={1} textAlign="center">
              {dailyTenantDisplayValue}
            </Typography>
            <Typography width={125} pt={1} textAlign="center">
              {"---"}
            </Typography>
            <Typography width={150} pt={1}>
              Transient (daily)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box width={500} sx={{ alignSelf: "flex-end" }}>
        <AnnualTenantTable aircraftFee={monthlyTenantValue} />
      </Box>
    </Stack>
  );
};

export const RevenueEstimatorPresenter: React.FC<{}> = ({}) => {
  const [activeTab, setActiveTab] = React.useState<string>("rates");
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ height: "calc(100% - 150px)", width: "100%" }}
    >
      <Stack direction="column" spacing={1}>
        <Tabs
          value={activeTab}
          onChange={(evt, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Rates" value="rates" />
          <Tab label="Fee Calculator" value="fee_calculator" />
        </Tabs>
        {activeTab === "rates" ? (
          <RatesTab />
        ) : (
          <Stack
            direction="column"
            alignItems="center"
            spacing={1}
            p={4}
            width={400}
          >
            <Typography>
              The Fee Calculator can now be accessed via the side navigation
              bar. Click below to go to the Fee Calculator.
            </Typography>
            <img
              src={feeCalculatorScreenshot}
              style={{
                width: 200,
                border: `2px solid lightgrey`,
              }}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => navigate("/fee-calculator")}
            >
              Go to Fee Calculator
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
