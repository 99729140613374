import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { GarageDoor, Hangar } from "../../types";
import { MetricAwareTextField } from "../../widgets/MetricAwareTextField/MetricAwareTextField";
import { Aircraft } from "../LabelingTool";
import { AutocompleteAircraft } from "../Settings/HangarSettings/AutocompleteAircraft";
import { HangarDimensionsForm } from "../Settings/HangarSettings/HangarDimensionsForm";

type Props = {
  localHangar: Hangar;
  setLocalHangar: (h: Hangar) => void;
  setIGarageDoor: (i: number, g: GarageDoor) => void;
  widthIncrement: number;
  setWidthIncrement: (w: number) => void;
  depthIncrement: number;
  setDepthIncrement: (d: number) => void;
  selectedAircraft: Aircraft | null;
  setSelectedAircraft: (a: Aircraft) => void;
  nAircraft: number;
  setNAircraft: (n: number) => void;
  aircrafts: Aircraft[];
  setAircrafts: (a: Aircraft[]) => void;
};

export const OptimizationInputsForm: React.FC<Props> = ({
  localHangar,
  setLocalHangar,
  depthIncrement,
  setDepthIncrement,
  widthIncrement,
  setWidthIncrement,
  selectedAircraft,
  setSelectedAircraft,
  nAircraft,
  setNAircraft,
  aircrafts,
  setAircrafts,
  setIGarageDoor,
}) => {
  const { activeFBO } = useActiveFBOs();
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="column">
        <Typography>Initial Dimensions</Typography>
        <HangarDimensionsForm
          localHangar={localHangar}
          setLocalHangar={setLocalHangar}
          editingWidth={localHangar.width}
          setEditingWidth={(w) => setLocalHangar({ ...localHangar, width: w })}
          editingDepth={localHangar.depth}
          setEditingDepth={(d) => setLocalHangar({ ...localHangar, depth: d })}
          hideGarageDoors
          setIGarageDoor={setIGarageDoor}
        />
      </Stack>
      <Stack direction="column">
        <Typography>Variations</Typography>
        <Stack direction="row" spacing={2} width={375} p={2}>
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={activeFBO.subscription === "standard"}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 50, max: 400 } }}
            size="small"
            label={`+/- width (${unit})`}
            onChange={setWidthIncrement}
            value={widthIncrement}
          />
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={activeFBO.subscription === "standard"}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 50, max: 400 } }}
            size="small"
            label={`+/- depth (${unit})`}
            onChange={setDepthIncrement}
            value={depthIncrement}
          />
        </Stack>
      </Stack>
      <Stack direction="column" width={600}>
        <Typography>Aircraft Mix</Typography>
        <Stack direction="row" spacing={2} p={2} alignItems="flex-start">
          <Box width={300} flexShrink={0}>
            <AutocompleteAircraft
              label="Search by make and model"
              onSelect={setSelectedAircraft}
            />
          </Box>
          <TextField
            label="# Aircraft"
            type="number"
            variant="outlined"
            size="small"
            inputProps={{ min: 1, max: 20 }}
            onChange={(e) => {
              setNAircraft(parseInt(e.target.value));
            }}
            sx={{
              width: 110,
            }}
          />
          <IconButton
            color="primary"
            onClick={() => {
              if (selectedAircraft && nAircraft > 0) {
                const newAircrafts = [...aircrafts];
                for (let i = 0; i < nAircraft; i++) {
                  newAircrafts.push(selectedAircraft);
                }
                setAircrafts(newAircrafts);
              }
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Make</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Wingspan ({unit})</TableCell>
              <TableCell>Length ({unit})</TableCell>
              <TableCell>Tail Height ({unit})</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aircrafts.map((aircraft, index) => (
              <TableRow key={index}>
                <TableCell>{aircraft.make}</TableCell>
                <TableCell>{aircraft.model}</TableCell>
                <TableCell>{aircraft.wingspan}</TableCell>
                <TableCell>{aircraft.length}</TableCell>
                <TableCell>{aircraft.tail_height}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const newAircrafts = [...aircrafts];
                      newAircrafts.splice(index, 1);
                      setAircrafts(newAircrafts);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </Stack>
  );
};
