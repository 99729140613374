import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { PostgrestClient } from "@supabase/postgrest-js";
import { MultiPolygon, Point, Polygon } from "geojson";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useIdentity } from "../../containers/IdentityContainer";
import { useSnackbar } from "../../containers/SnackbarContainer";
import { useTokenMaker } from "../../hooks";
import { getPostgrestUrl } from "../../utils";
import { ConfirmationDialog } from "../../widgets/ConfirmationDialog";
import { Loading } from "../../widgets/Loading";
import { LabelingToolPresenter } from "./LabelingToolPresenter";

const postgrest = new PostgrestClient(getPostgrestUrl());

type Props = {};

export interface Aircraft {
  id: string;
  linked_to: string;
  make: string;
  model: string;
  wingspan: number;
  length: number;
  midline: number;
  tail_height: number;
  image: string;
  side_image: string;
  geom: any;
  nose: any;
  geomz: any;
  centroid: any;
  geomz_text: string;
  front_wheels: number;
  rear_wheels: number;
  wing_geom: any;
  wing_top: number;
  wing_bottom: number;
  tail_geom: any;
  tail_top: number;
  tail_bottom: number;
  vertical_tail_fin_geom: any;
  vertical_tail_fin_top: number;
  vertical_tail_fin_bottom: number;
  cabin_top: number;
  cabin_bottom: number;
  wheel_rotation_limit: number;
  reference_point_wing_bottom: number;
  reference_point_wing_top: number;
  reference_point_tail_bottom: number;
  reference_point_tail_top: number;
  reference_point_cabin_bottom: number;
  reference_point_cabin_top: number;
  reference_point_vertical_tail_fin_bottom: number;
  reference_point_vertical_tail_fin_top: number;
  propeller_geom: any;
  fuel_capacity: number;
  fuel_type: string;
  tail_wheel: boolean;
  locked: boolean;
  is_hidden: boolean;
  created_at: string;
  scaled_geom: Polygon;
  scaled_geom_full: Polygon;
  scaled_geomz_full: MultiPolygon;
  geom_scaled_buffer: Polygon;
  ramp_geom_scaled_buffer: Polygon;
  pivot_point: Point;
  simplify_pct: number;
  is_helicopter: boolean;
}

export const LabelingTool: React.FC<Props> = ({ ...props }) => {
  const { aircraftId } = useParams();
  const [confirmDeploy, setConfirmDeploy] = React.useState<boolean>(false);
  const { url: webURL } = useTokenMaker();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { airplxIdentity } = useIdentity();

  // const [aircrafts, setAircrafts] = React.useState<Aircraft[]>([]);
  const [limitToCompleted, setLimitToCompleted] = React.useState<boolean>(
    false
  );
  const [completedAircraft, setCompletedAircraft] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [limitToNotCompleted, setLimitToNotCompleted] = React.useState<boolean>(
    false
  );
  const [selectedAircraft, setSelectedAircraft] = React.useState<Aircraft>();
  const [img, setImg] = React.useState(new Image());
  const [imgSide, setImgSide] = React.useState(new Image());

  const { loading, value: selectableAircrafts } = useAsync(async () => {
    const { data } = await postgrest
      .rpc("aircraft_raw", {
        anything: "anything",
      })
      .select("id, make, model, locked")
      .order("make")
      .order("model");
    const aircrafts = data as Aircraft[];
    const defaultAircraft =
      aircrafts.find((a) => a.id === aircraftId) || aircrafts[0];
    setSelectedAircraft(await fetchAircraft(defaultAircraft.id));
    fetchCompletedAircraft(aircrafts);
    return aircrafts;
  }, []);

  const fetchCompletedAircraft = async (aircrafts: Aircraft[]) => {
    const completedAircraft = {};
    const { data: completedAircraftResults } = await postgrest
      .rpc("is_aircraft_complete", {
        aircraft_ids: aircrafts.map((a) => a.id),
      })
      .select();

    for (const aircraft of completedAircraftResults) {
      completedAircraft[aircraft.aircraft_id] = aircraft.is_complete;
    }
    setCompletedAircraft(completedAircraft);
  };

  // fetch image from the server. use loaded/setLoaded in the even that we're
  // changing airplanes
  async function fetchImage(imageURL: string, setIt: (x: any) => void) {
    let newImage = new Image();
    newImage.setAttribute("crossOrigin", "");
    newImage.crossOrigin = "Anonymous";
    newImage.src = imageURL.replace("public", "");
    newImage.onload = () => {
      setIt(newImage);
    };
  }

  const gisIntersection = async (geom1, geom2) => {
    postgrest.schema = "web";
    return postgrest.rpc("gis_intersection", {
      geom1,
      geom2,
    });
  };

  React.useEffect(() => {
    if (window.location.host.indexOf("staging.airplx.com") > -1) {
      snackbar.notify({
        text: "FYI - You are on staging",
        severity: "warning",
      });
    }
  }, []);

  const fetchAircraft = async (id: string): Promise<Aircraft> => {
    const { data: aircrafts } = await postgrest
      .rpc("aircraft_raw", {
        anything: "anything",
      })
      .select()
      .eq("id", id);
    return aircrafts[0] as Aircraft;
  };

  useAsync(async () => {
    if (!selectedAircraft) {
      return;
    }
    const { id, geomz_text, ...data } = selectedAircraft;
    console.log("updating", data);
    await postgrest.rpc("upsert_aircraft", {
      an_aircraft: {
        id,
        ...data,
      },
    });
    await fetchCompletedAircraft(selectableAircrafts);
  }, [selectedAircraft]);

  React.useEffect(() => {
    if (!selectedAircraft) {
      return;
    }
    if (selectedAircraft.image) {
      fetchImage(selectedAircraft.image, setImg);
    }
    if (selectedAircraft.side_image) {
      fetchImage(selectedAircraft.side_image, setImgSide);
    }
  }, [selectedAircraft]);

  if (loading || !selectedAircraft || !img) {
    return <Loading />;
  }

  const isComplete = (a: Aircraft): boolean => {
    if (!a) {
      return false;
    }
    if (a.linked_to) {
      return true;
    }
    return completedAircraft[a.id] ?? false;
  };

  const getEmojiForAircraft = (aircraft: Aircraft): string => {
    if (aircraft.locked) {
      return "🔒";
    }

    if (isComplete(aircraft)) {
      return "✅";
    }
    return "";
  };

  const isPeter = airplxIdentity.isadmin;

  return (
    <Box sx={{ p: 1, overflow: "scroll", height: "100%" }}>
      <Stack direction="row" spacing={1}>
        <Autocomplete
          disablePortal
          sx={{ width: 500 }}
          options={selectableAircrafts
            .filter((a) => (limitToCompleted ? isComplete(a) : true))
            .filter((a) => (limitToNotCompleted ? !isComplete(a) : true))}
          getOptionLabel={(option: Aircraft) =>
            `${getEmojiForAircraft(option)} ${option.make} ${option.model}`
          }
          onChange={async (event: any, newValue: Aircraft | null) => {
            if (!newValue?.id) {
              return;
            }
            const aircraft = await fetchAircraft(newValue.id);
            setSelectedAircraft(aircraft);
            if (aircraft) {
              window.location.hash = "#/labeling-tool/" + aircraft.id;
            }
            if (isComplete(aircraft)) {
              snackbar.notify({
                text: "You are editing a completed aircraft.",
                severity: "warning",
              });
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Aircraft" size="small" />
          )}
          value={selectedAircraft}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={limitToCompleted}
              onChange={() => {
                setLimitToNotCompleted(false);
                setLimitToCompleted(!limitToCompleted);
              }}
            />
          }
          label="Limit to Completed"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={limitToNotCompleted}
              onChange={() => {
                setLimitToCompleted(false);
                setLimitToNotCompleted(!limitToNotCompleted);
              }}
            />
          }
          label="Limit to Not Completed"
        />
        <Button
          color="success"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={async () => {
            const { data: new_aircraft_id } = await postgrest.rpc(
              "new_aircraft",
              {
                anything: "hi",
              }
            );
            navigate(`/labeling-tool/${new_aircraft_id}`);
          }}
        >
          New
        </Button>
        {isPeter && (
          <>
            <FormControlLabel
              control={<Switch checked={selectedAircraft.locked} />}
              onChange={async (evt) => {
                const confirmed = confirm("You sure big man?");
                if (!confirmed) {
                  return;
                }
                setSelectedAircraft({
                  ...selectedAircraft,
                  locked: evt.target.checked,
                });
                await postgrest.rpc("toggle_locked", {
                  aircraft_id: selectedAircraft.id,
                  new_value: evt.target.checked,
                });
              }}
              label="Locked?"
            />
            <FormControlLabel
              control={<Switch checked={selectedAircraft.is_hidden} />}
              onChange={async (evt) => {
                const confirmed = confirm("You sure big man?");
                if (!confirmed) {
                  return;
                }
                setSelectedAircraft({
                  ...selectedAircraft,
                  is_hidden: evt.target.checked,
                });
                await postgrest.rpc("toggle_is_hidden", {
                  aircraft_id: selectedAircraft.id,
                  new_value: evt.target.checked,
                });
              }}
              label="Is Hidden?"
            />
            <Button
              onClick={() => setConfirmDeploy(true)}
              variant="contained"
              color="primary"
            >
              Deploy
            </Button>
            {confirmDeploy && (
              <ConfirmationDialog
                title="Confirm deploy"
                text="Are you sure you want to deploy?"
                onCancel={() => setConfirmDeploy(false)}
                onClose={() => setConfirmDeploy(false)}
                onOk={async () => {
                  await fetch(`${webURL}/jobs/update-aircraft`);
                  setConfirmDeploy(false);
                }}
                okText="Go baby go!"
              />
            )}
          </>
        )}
      </Stack>
      {selectedAircraft.locked && !isPeter ? (
        <LabelingToolPresenter
          img={img}
          imgSide={imgSide}
          aircrafts={selectableAircrafts}
          aircraft={selectedAircraft}
          setAircraft={() => {
            // noop
          }}
          gisIntersection={gisIntersection}
        />
      ) : (
        <LabelingToolPresenter
          img={img}
          imgSide={imgSide}
          aircrafts={selectableAircrafts}
          aircraft={selectedAircraft}
          setAircraft={setSelectedAircraft}
          gisIntersection={gisIntersection}
        />
      )}
    </Box>
  );
};
