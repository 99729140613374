import { Box, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format as formatDate, formatDistance } from "date-fns";
import * as React from "react";
import { Healthcheck } from "./WorkerStatus";

export const WorkerStatusPresenter: React.FC<{
  healthchecks: Healthcheck[];
}> = ({ healthchecks }) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Worker",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 125,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.status}
            color={params.row.status === "OK" ? "success" : "error"}
          />
        );
      },
    },
    {
      field: "last_updated",
      headerName: "Last Checked",
      width: 250,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDate(
          new Date(row.last_updated),
          "yyyy-MM-dd hh:mm:ss a z"
        );
      },
    },
    {
      field: "last_updated_relative",
      headerName: "",
      width: 200,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDistance(new Date(row.last_updated), new Date(), {
          addSuffix: true,
        });
      },
    },
  ];
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ height: "250px", width: "100%" }}
    >
      <Box>
        <Typography variant="subtitle2">Workers</Typography>
        <Typography variant="body2" color="textSecondary">
          {`Each worker reports home every minute to let us know how it's doing. Every time we checks-in, the worker runs a very small version of the AutoStacking algorithm. This should run in <50ms. It's the best way to determine if something is wrong.`}
        </Typography>
      </Box>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooter
        rows={healthchecks}
        columns={columns}
        getRowId={(row) => row.name}
      />
    </Stack>
  );
};
