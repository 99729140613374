import { Box, Stack, useTheme } from "@mui/material";
import * as React from "react";
import { useMeasure } from "react-use";
import {
  EventPayload,
  useEventContainer,
} from "../../containers/EventContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useSnackbar } from "../../containers/SnackbarContainer";
import { Position } from "../../types";
import { uuidv4 } from "../../utils";
import { MoveEntityEvent } from "../../widgets/Layout";
import { Loading } from "../../widgets/Loading";
import { ZoomControl } from "../../widgets/ZoomControl";
import { useLayout } from "../Ramp/CollapsibleRampActionCard";
import { checkForAircraftWarnings } from "../Settings/HangarSettings/AddTenantButton";
import { findNewHangar } from "./handleOnMoveEntity";
import { getFeetToPixels, HangarViewWrapper } from "./HangarViewWrapper";
import { HOLDING_AREA_ID, HoldingArea } from "./HoldingArea";
import { useMultiHangarLayoutPolygons } from "./useMultiHangarLayoutPolygons";

type Props = {
  hangarIds: string[];
};

export const MultiHangarPresenter: React.FC<Props> = ({ hangarIds }) => {
  const snackbar = useSnackbar();
  const { isDrawerOpen } = useLayout();

  const theme = useTheme();
  const {
    ready,
    zoom,
    setZoom,
    activeLocationId,
    focus,
    hangars: allHangars,
    setHangars,
    setActiveLocationId,
  } = useMultiHangarState();
  const hangars = allHangars.filter((h) => hangarIds.indexOf(h.id) > -1);
  const hangarRefs: React.RefObject<HTMLDivElement>[] = hangars.map((hangar) =>
    React.createRef<HTMLDivElement>()
  );
  // it's easier it we treat the holding area as its own speical place. technically it's a hangar
  // but it's not *actually* a hangar.
  const holdingAreaRef = React.createRef<HTMLDivElement>();

  // this is the container for all of the the actual drawings of all of the hangars
  // we need this to account for when an airplane gets dropped into a different hangar so
  // that we can update the position of the airplane relative to the new hangar.
  const [
    refMultiHangarLayout,
    { height: multiHangarLayoutHeight },
  ] = useMeasure();

  const moveEvent = useEventContainer<MoveEntityEvent>();

  const feetToPixels = getFeetToPixels(
    hangars.filter((h) => (focus ? h.id === focus : true)),
    zoom,
    focus
  );

  // for the multi-hangar layout, we need to account for the "whitespace" between the canvas of each hangar.
  // this is stuff such as padding, the title, etc. we need to account for this so that the global coordinates
  // of each hangar are correct.
  const layoutPolygons = useMultiHangarLayoutPolygons(
    {
      hangars,
      hangarRefs,
      holdingAreaRef,
      multiHangarLayoutHeight,
      feetToPixels,
    },
    [activeLocationId, focus]
  );

  /**
   * I need a way to check if a plane goes into another hangar. If that happens,
   * then we need to move the plane out of its original hangar and into the new one.
   */
  const handleOnMoveEntity = React.useCallback(
    (payload: EventPayload<MoveEntityEvent>) => {
      const { entity, locationId: previousLocationId } = payload.data;

      // convert coordinates to the 'global' coordinate system
      // this is the entire component (including the padding, title, etc.) not just the
      // hangar canvas.
      // determine where the tenant is now located
      const previousHangarIdx = allHangars.findIndex(
        (h) => h.id === previousLocationId
      );
      if (previousHangarIdx === -1) {
        return;
      }
      if (layoutPolygons.length === 0 || !layoutPolygons?.[0]?.depth) {
        return;
      }

      // need to account for the vertical padding from the hangar, as well as the distance offset
      // from the origin of the hangar

      // remove a few things and then it's the largest hangar's depth as baseline - this hangar's depth.
      // then adjusted for the offset from the origin
      const previousHCP = layoutPolygons.find(
        (hcp) => hcp.id === previousLocationId
      );

      const globalPosition: Position = {
        ...entity.position,
        x: entity.position.x + previousHCP.x + previousHCP.widthOffset,
        y: entity.position.y + previousHCP.y + previousHCP.depthOffset,
      };

      const newHangar = findNewHangar(
        previousHCP,
        allHangars,
        layoutPolygons,
        entity,
        globalPosition
      );

      // now we need to convert the global coordinates of the aircraft to the local
      // coordinates of the hangar. to do this, we'll remove the padding we applied
      // to the global coordinates.
      const newHangarPolygon = layoutPolygons.find(
        (hcp) => hcp.id === newHangar.id
      );
      const newPosition: Position = {
        ...entity.position,
        id: uuidv4(),
        stack_id: newHangar.stack.id,
        x: globalPosition.x - newHangarPolygon.x - newHangarPolygon.widthOffset,
        y: globalPosition.y - newHangarPolygon.y - newHangarPolygon.depthOffset,
        angle: entity.position.angle,
      };

      if (!newHangar) {
        return;
      }
      if (newHangar.id === previousLocationId) {
        return;
      }

      let newTenant;
      if (entity.entity_type === "tenant") {
        newTenant = {
          ...entity,
          position: newPosition,
        };

        if (newHangar.id !== HOLDING_AREA_ID) {
          // checking for things like if the airplane is too tall for the hangar
          const warnings = checkForAircraftWarnings(newTenant.aircraft, {
            type: "hangar",
            ...newHangar,
          });

          // if a warning triggers, throw it back up to the global warning snackbar.
          for (const warning of warnings) {
            snackbar.notify({
              text: warning,
              severity: "error",
            });
          }
        }
      }

      let movableObstacle;
      if (entity.entity_type === "movableObstacle") {
        movableObstacle = {
          ...entity,
          position: newPosition,
        };
      }

      setHangars((hangars) =>
        hangars.map((h) => {
          if (h.id === newHangar.id) {
            const tenants = Boolean(newTenant)
              ? [...h.stack.tenants, newTenant]
              : h.stack.tenants;
            const movableObstacles = Boolean(movableObstacle)
              ? [...h.stack.movableObstacles, movableObstacle]
              : h.stack.movableObstacles;
            return {
              ...h,
              stack: {
                ...h.stack,
                tenants,
                movableObstacles,
              },
            };
          } else {
            return {
              ...h,
              stack: {
                ...h.stack,
                tenants: h.stack.tenants.filter((t) => t.id !== newTenant?.id),
                movableObstacles: h.stack.movableObstacles.filter(
                  (mo) => mo.id !== movableObstacle?.id
                ),
              },
            };
          }
        })
      );
      setActiveLocationId(newHangar.id);
    },
    [layoutPolygons, hangars, setHangars, zoom, theme]
  );

  React.useEffect(() => {
    moveEvent.addEventListener("moveEntity", handleOnMoveEntity);
    return () => {
      moveEvent.removeEventListener("moveEntity", handleOnMoveEntity);
    };
  }, [
    handleOnMoveEntity,
    moveEvent.addEventListener,
    moveEvent.removeEventListener,
  ]);

  /**
   * it's easiest to let the multihangar select multiple tenants simultaneously
   * and then remove the `selected: true` flat on tenants where it should not be
   * selected. this happens when the user is changing active hangars. the reason it's
   * being done here is because if we try to do this in the onMouseDown event within the
   * HangarViewWrapper, it will cause the click event to not fire.
   */
  const selectedTenants = hangars
    .map((hangar) => hangar.stack.tenants.filter((t) => t.selected))
    .flat();

  React.useEffect(() => {
    if (selectedTenants.length === 0) {
      return;
    }
    // find tenant that is also in the hangar that is currently selected
    setHangars((hangars) =>
      hangars.map((h) => ({
        ...h,
        stack: {
          ...h.stack,
          tenants: h.stack.tenants.map((t) => {
            if (t.selected && h.id === activeLocationId) {
              return {
                ...t,
                selected: true,
              };
            }
            return {
              ...t,
              selected: false,
            };
          }),
        },
      }))
    );
  }, [JSON.stringify(selectedTenants.map((t) => t.id))]);

  if (!ready) {
    return <Loading />;
  }

  return (
    <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
      <Box
        ref={refMultiHangarLayout}
        data-id="multi-hangar-layout"
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start" // Align items at the start of the flex container (top of each row)
        justifyContent="flex-start" // Justify items at the start of the flex container (left)
        sx={{
          placeSelf: "flex-start",
          marginLeft: !isDrawerOpen ? "150px" : "400px",
          marginTop: `50px`,
          p: 2,
          // height: "100%", // causes planes to "hop" when moving to/from holding area
          overflowY: "scroll",
        }}
      >
        {hangars
          .filter((h) => {
            if (focus) {
              return h.id === focus || h.id === activeLocationId;
            }
            if (h.id === HOLDING_AREA_ID) {
              return false;
            }
            return true;
          })
          .map((hangar, idx) => {
            return (
              <Box
                ref={hangarRefs[idx]}
                key={`multihangar-${hangar.id}`}
                data-id={hangar.id}
                height="100%"
              >
                <HangarViewWrapper
                  hangarId={hangar.id}
                  feetToPixels={feetToPixels}
                />
              </Box>
            );
          })}
      </Box>
      <Box ref={holdingAreaRef}>
        <HoldingArea
          feetToPixels={feetToPixels}
          multiHangarLayoutHeight={multiHangarLayoutHeight}
        />
      </Box>

      <ZoomControl
        currentZoom={zoom}
        increment={0.1}
        onClickPlus={() => setZoom(zoom * 1.1)}
        onClickMinus={() => setZoom(zoom * 0.9)}
      />
    </Stack>
  );
};
