import { Button } from "@mui/material";
import * as React from "react";
import { Tenant } from "../../types";
import { NotesDialog } from "./NotesDialog";

type Props = {
  variant?: "contained" | "outlined" | "text" | undefined;
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  disabled?: boolean;
};

export const ButtonNotes: React.FC<Props> = ({
  disabled = false,
  variant,
  tenant,
  setTenant,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <Button
        size="small"
        color={variant === "contained" ? "info" : "inherit"}
        variant={variant ?? "text"}
        onClick={() => setOpen(true)}
      >
        Notes
      </Button>
      <NotesDialog
        disabled={disabled}
        tenant={tenant}
        setTenant={setTenant}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
