import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Tenant } from "../../types";

type Props = {
  mini?: boolean;
  tenants: Tenant[];
  onSelect: (tenant: Tenant) => void;
};

export const AircraftFinder: React.FC<Props> = ({ mini, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "searchPopover" : undefined;

  if (mini) {
    return (
      <>
        <Tooltip title="Find aircraft">
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={handleClick}
            sx={{
              alignSelf: "center",
              width: "80%",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ConnectingAirportsIcon />
              <SearchIcon />
            </Stack>
          </Button>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box width={300}>
            <AircraftFinderAutocomplete {...props} />
          </Box>
        </Popover>
      </>
    );
  }

  return <AircraftFinderAutocomplete {...props} />;
};

const AircraftFinderAutocomplete: React.FC<Props> = ({ tenants, onSelect }) => {
  const options = React.useMemo(
    () =>
      tenants.sort((a, b) => {
        const cmpTail = a.tail_number.localeCompare(b.tail_number);
        if (cmpTail !== 0) {
          return cmpTail;
        }
        return a.aircraft.model.localeCompare(b.aircraft.model);
      }),
    [tenants.length]
  );
  return (
    <Autocomplete
      size="small"
      onChange={(event: any, newValue: Tenant | null) => {
        if (!newValue || !newValue?.id) {
          return;
        }
        onSelect(newValue);
      }}
      options={options}
      sx={{ width: "100%" }}
      getOptionLabel={(option: Tenant) => {
        return option.tail_number ?? "";
      }}
      renderOption={(props, option, state) => {
        const isInHangar = tenants.find((tenant) => tenant.id === option.id);
        let text = "";
        if (!option.tail_number) {
          text = `${option.aircraft.model}`;
        } else {
          text = `${option.tail_number} - ${option.aircraft.model}`;
        }

        return (
          <li
            {...props}
            key={`search-hangars-${option.id}`}
            style={{
              backgroundColor: isInHangar ? `#c8cfde40` : "transparent",
            }}
          >
            <Typography variant="inherit">{text}</Typography>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search for aircraft"
        />
      )}
    />
  );
};
